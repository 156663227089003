-<template>
  <div v-if="$store.state.utilizador1 && $store.state.lojas1 && $store.state.series1" ref="windowResize">

    <div class="modal-mask" v-if="modalProdutoVariado" transition="modal" id="modalProdutoVariado">
      <div class="modalScrollMobile">
        <div class="modal-wrapper">
          <div class="modal-fundo"></div>
          <div class="modal-container padding-0 modal-800">
            <div class="modal-header">
              <h3 class="modal-title padding-0">{{modalProdutoVariadoNome}}<button @click="modalProdutoVariado = false" type="button" class="btn btn-default btn-pure btn-lg modal-fechar"><i class="glyphicon glyphicon-remove"></i></button></h3>
              <label v-if="!ISENTO" class="switch pull-right"><input class="foco" type="checkbox" v-model="modalProdutoVariadoIva"><i></i> <span>{{l('preco_civa')}}</span></label>
            </div>
            <div class="modal-body margin-0 padding-top-0 padding-bottom-15 text-center">
              <div id="divTable" class="row">
                <table id="tabelaFixa1" class="table tabelaVerde table-hover dataTable width-full table-striped table-condensed">
                  <thead id="tabelaFixaThead1">
                    <tr>
                      <th class="text-left" v-for="(dt, index) in modalProdutoVariadoNames" :key="index">{{dt}}</th>
                      <th v-if="modalProdutoVariadoIva" class="text-right">{{l('t_preco_c_iva')}} ({{MOEDA}})</th>
                      <th v-else class="text-right">{{l('t_preco_s_iva')}} ({{MOEDA}})</th>
                      <th v-if="page2 !== 'compras'" class="text-right">{{$t('stock')}}</th>
                      <th class="width-45"></th>
                    </tr>
                    <tr>
                      <td class="text-left" v-for="(dt, index) in modalProdutoVariadoNames" :key="index">
                        <input autocomplete="off" type="text" class="form-control foco coluna_total" v-model="modalProdutoVariadoKeys[index]" :placeholder="dt" :id="dt + '-' + index" @keydown.enter.prevent="FOCO" v-texto @keyup="filtrarVariacoes" />
                      </td>
                      <td :colspan="page2 !== 'compras' ? 3 : 2"></td>
                    </tr>
                  </thead>
                  <tbody id="tbodyFixa">
                    <tr :id="'xpo-' + dt.codigo_barras" v-for="(dt, index) in modalProdutoVariadoAr" :key="index" @click="selArtigo1(modalProdutoVariadoDT, 0, 1, index)" class="mao" v-show="dt.show">
                      <td class="text-left" v-for="(dtt, ind) in dt.value" :key="index + 'fd-' + ind">{{dtt}}</td>
                      <td v-if="modalProdutoVariadoIva" class="text-right">{{dt.preco * (dt. imposto / 100  + 1) | valor2($store.getters.CASASDECIMAIS)}}</td>
                      <td v-else class="text-right">{{dt.preco | valor2($store.getters.CASASDECIMAIS)}}</td>
                      <td v-if="page2 !== 'compras'" class="text-right">{{dt.stock}}</td>
                      <th class="text-center"><button class="btn btn-success"><i class="glyphicon glyphicon-floppy-disk"></i></button></th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="clearfix"></div>
              <button class="btn btn-default pull-right" type="button" @click="modalProdutoVariado = false">{{$t('fechar')}}</button>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-mask" v-if="modalUpdatePrecosCompra" transition="modal" id="suporteAtModal">
      <div class="modalScrollMobile">
        <div class="modal-wrapper">
          <div class="modal-fundo"></div>
          <div class="modal-container padding-0 modal-800">
            <div class="modal-header">
              <h3 class="modal-title padding-0">{{l('atualizar_preco_custo')}}
                <label v-if="!ISENTO" class="switch pull-right"><input class="foco" type="checkbox" v-model="modalUpdatePrecosCompraIva"><i></i> <span>{{l('preco_civa')}}</span></label>
              </h3>
            </div>
            <div class="modal-body margin-0 padding-top-0 padding-bottom-15 text-center">
              <div id="divTable">
                <table id="tabelaFixa" class="table tabelaVerde table-hover dataTable width-full table-striped table-condensed">
                  <thead id="tabelaFixaThead">
                    <tr>
                      <th class="width-45">
                        <label class="switch"><input type="checkbox" name="estado1" v-model="modalUpdatePrecosCompraCheck" @change="modalUpdatePrecosCompraCheckFN(1)" :checked="modalUpdatePrecosCompraCheck" id="estgfhf54ado1"><i></i></label>
                      </th>
                      <th class="text-left">{{l('t_descricao')}}</th>
                      <th v-if="modalUpdatePrecosCompraIva" class="text-right">{{l('t_preco_c_iva_antes')}} ({{MOEDA}})</th>
                      <th v-else class="text-right">{{l('t_preco_s_iva_antes')}} ({{MOEDA}})</th>
                      <th v-if="modalUpdatePrecosCompraIva" class="text-right">{{l('t_preco_c_iva_depois')}} ({{MOEDA}})</th>
                      <th v-else class="text-right">{{l('t_preco_s_iva_depois')}} ({{MOEDA}})</th>
                      <th class="text-right">{{l('t_desc')}} (%)</th>
                    </tr>
                  </thead>
                  <tbody id="tbodyFixa">
                    <tr v-for="(dt, index) in modalUpdatePrecosCompraAr" :key="index">
                      <td>
                        <label class="switch"><input type="checkbox" v-model="dt.actualiza_preco" :checked="dt.actualiza_preco"><i></i></label>
                      </td>
                      <td class="text-left">{{dt.nome}}</td>
                      <td v-if="modalUpdatePrecosCompraIva" class="text-right">{{dt.preco_custo * (dt. imposto / 100  + 1) || ''}}</td>
                      <td v-else class="text-right">{{dt.preco_custo}}</td>
                      <td v-if="modalUpdatePrecosCompraIva" class="text-right">{{dt.preco_unitario * (dt. imposto / 100  + 1) || ''}}</td>
                      <td v-else class="text-right">{{dt.preco_unitario}}</td>
                      <td class="text-right">{{dt.desconto_1}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="clearfix"></div>
              <button class="btn btn-default btn-pure pull-left" type="button" @click="submit();">{{$t('cancelar')}}</button>
              <button class="btn btn-primary pull-right" type="button" @click="modalUpdatePrecosCompraConfirm = true; submit();">{{$t('confirmar')}}</button>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="PERMISSOES.itens_ver_preco_custo && PERMISSOES.compras && page2 === 'compras' && (PERMISSOES.pagamentos_gerir && form.tipo_documento_tp == 'RFC' || form.tipo_documento_tp != 'RFC') || PERMISSOES.vendas && page2 !== 'compras' && (PERMISSOES.recibos_gerir && (form.tipo_documento_tp == 'RC' || form.tipo_documento_tp == 'NL') || form.tipo_documento_tp != 'RC' && form.tipo_documento_tp != 'NL')">
      <div class="clearfix"></div>
      <div class="alert alert-danger alert-dismissible text-justify" v-if="form.tipo_documento_tp == 'NL'" v-html="l('nota_liquidacao')"></div>
      <div class="clearfix"></div>
      <div class="alert alert-danger alert-dismissible" v-if="form.terminado && form.estado === 'A' && form.motivo">{{form.motivo}}</div>
      <div class="clearfix"></div>

      <div id="form" v-if="!criarFormExterno && resultado">
        <input type="hidden" v-model="form.terminado" id="terminado">
        <div>
          <div class="panel margin-bottom-15 padding-bottom-15" v-if="agendar && form.terminado != 1">
            <div class="panel-heading">
              <h3 class="panel-title padding-10">{{l('detalhes_agendamento')}}<!--<button @click="agendar = 0" class="btn btn-pure btn-default pull-right"><i class="glyphicon glyphicon-remove" style="font-size:18px;"></i></button>--></h3>
            </div>
            <div class="panel-body container-fluid padding-0">
              <div>
                <div class="col-md-4 col-sm-6 col-lg-4 form-group form-material">
                  <div class="input-group">
                    <span class="input-group-addon">{{$t('data_inicio')}}<span class="verm">*</span></span>
                    <!--<input autocomplete="off" maxlength="10" pattern="[0-9]{2}\/[0-9]{2}\/[0-9]{4}$" min="2019-01-01" :max="ao.data_fim" type="date" class="form-control foco" v-model="ao.data_inicio" :placeholder="$t('data_defeito')" id="data_agendamento_inicio" @keydown.enter.prevent="FOCO" @blur="agendamentoFN()">-->
                    <date-picker :clearable="false" :editable="false" type="date" format="YYYY-MM-DD" :lang="$store.state.langData" class="foco" v-model="ao.data_inicio" :placeholder="$t('data_defeito')" id="data_agendamento_inicio" @change="agendamentoFN()"></date-picker>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6 col-lg-4 form-group form-material">
                  <div class="input-group">
                    <span class="input-group-addon">{{$t('data_fim')}}<span class="verm">*</span></span>
                    <!--<input autocomplete="off" maxlength="10" pattern="[0-9]{2}\/[0-9]{2}\/[0-9]{4}$" :min="ao.data_inicio" type="date" class="foco" v-model="ao.data_fim" :placeholder="$t('data_defeito')" id="data_agendamento_fim" @keydown.enter.prevent="FOCO" @blur="agendamentoFN()">-->
                    <date-picker :clearable="false" :editable="false" type="date" format="YYYY-MM-DD" :lang="$store.state.langData" class="foco" v-model="ao.data_fim" :placeholder="$t('data_defeito')" id="data_agendamento_fim" @change="agendamentoFN()"></date-picker>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6 col-lg-4 form-group form-material">
                  <div class="input-group pr">
                    <span class="input-group-addon">{{l('frequencia')}}<span class="verm">*</span></span>
                    <input style="width:50%" autocomplete="off" type="number" class="form-control foco text-center" v-model="ao.a_cada" id="a_cada" @keydown.enter.prevent="FOCO" v-numerico min="1" max="900" @change="agendamentoFN()">
                    <select style="width:50%; height:33px;" class="form-control foco" v-model="ao.frequencia" @change="agendamentoFN()">
                      <option value="dias">{{$t('dia')}}</option>
                      <option value="meses">{{$t('mes')}}</option>
                      <option value="anos">{{$t('ano')}}</option>
                    </select>
                          <!--
                          <a v-show="ao.frequencia === 'dias'" @click="ao.frequencia = 'meses'; agendamentoFN()" class="text-warning icoinput mao text-uppercase">{{$t('dia')}}</a>
                          <a v-show="ao.frequencia === 'meses'" @click="ao.frequencia = 'anos'; agendamentoFN()" class="text-warning icoinput mao text-uppercase">{{$t('mes')}}</a>
                          <a v-show="ao.frequencia === 'anos'" @click="ao.frequencia = 'dias'; agendamentoFN()" class="text-warning icoinput mao text-uppercase">{{$t('ano')}}</a>-->
                        </div>
                      </div>
                      <div class="clearfix"></div>
                      <div class="col-md-12 form-group" v-if="ao.rascunho && form.terminado > 0 || form.terminado < 1">
                        <div class="padding-10 pull-left">
                          <input class="foco" type="checkbox" v-model="ao.rascunho" id="rascunho" @keydown.enter.prevent="FOCO">
                          <label for="rascunho">{{l('rascunho')}}</label>
                        </div>

                        <div class="padding-10 pull-left" v-if="!ao.rascunho && form.terminado < 1 || form.terminado > 0">
                          <input class="foco" type="checkbox" v-model="ao.enviar_email" id="enviar_email" @keydown.enter.prevent="FOCO">
                          <label for="enviar_email">{{l('enviar_email')}}</label>
                        </div>
                      </div>
                      <div class="clearfix"></div>
                      <div v-if="ao.enviar_email">
                        <div class="col-md-8 col-sm-8 col-lg-8 form-group form-material">
                          <div class="input-group">
                            <span class="input-group-addon">{{$t('email')}}</span>
                            <input autocomplete="off" type="email" class="form-control foco" v-model="ao.email_destino" maxlength="150" id="agend_email" @keydown.enter.prevent="FOCO" v-texto>
                          </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-lg-4 form-group form-material" v-if="PLANO120 && $store.state.template.length && $store.state.utilizador.empresa.smtp_privado > 0">
                          <div class="input-group">
                            <span class="input-group-addon">{{l('template_id')}}<span class="verm">*</span></span>
                            <select class="form-control foco" v-model="ao.template_id" id="agend_template_id" @keydown.enter.prevent="FOCO">
                              <option v-for="(dt, i) in $store.state.template" :key="i" :value="dt.id">{{dt.nome}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="col-lg-12 form-group form-material" v-if="PLANO120 && $store.state.template.length && $store.state.utilizador.empresa.smtp_privado > 0">
                          <div class="input-group">
                            <span class="input-group-addon">{{l('mensagem_email')}}</span>
                            <textarea autocomplete="off" rows="4" maxlength="200" class="form-control foco" id="mensagem_email" v-model="ao.mensagem_email" @keydown.enter.prevent="FOCO"></textarea>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="margin-left-10 margin-top-10">
                          <p class="margin-bottom-5" v-html="l('explicacao_agendamento').replace('@documento',  l('documentos.' + form.tipo_documento_tp))"></p>
                          <label class="btn btn-primary btn-xs margin-right-5 margin-top-5" v-for="(dt, index) in agendamento" :key="index"><i class="glyphicon glyphicon-calendar"></i> {{dt | data}}</label>
                          <div class="clearfix"></div>
                          <p class="padding-0 margin-0 margin-top-15">{{l('total_documentos')}} <b>{{agendamento.length}}</b></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div :class="{'panel margin-bottom-15 padding-bottom-15': 1, 'padding-top-15': form.terminado}" v-if="form.tipo_documento_tp != 'guiaglobal' && form.tipo_documento_tp != 'GMD' && page !== 'quebrastock'">
                  <div class="panel-heading" v-if="form.terminado != 1">
                    <h3 class="panel-title padding-10">{{$t('contacto')}} <span v-if="$store.state.seriestotal == 1 && form.terminado != 1">/ {{$t('serie')}}: {{Principal(series, form.serie_id).nome}}</span></h3>
                  </div>
                  <div class="panel-body container-fluid padding-0">
                    <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="form.terminado != 1 && form.tipo_documento_tp == 'GA'">
                      <div class="input-group">
                        <span class="input-group-addon">
                          {{l('entre_outras_lojas')}}
                        </span>
                        <select class="form-control" v-model="ao.lojaGuia" @change="FNLojaGuia()">
                          <option value="contacto">{{$t('contacto')}}</option>
                          <option value="sede">{{l('sede')}}</option>
                          <option v-for="(dt, i) in $store.state.lojas" :key="i" :value="dt.id">{{dt.nome}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                    <div :class="contacto" v-if="ao.lojaGuia == 'contacto' && form.tipo_documento_tp == 'GA' || form.tipo_documento_tp != 'GA'">
                      <pesq-contactos :contactos="[]" @delContato="delContato" @selContato="selContato" :contacto="contacto" :disabled="form.terminado > 0 || form.contato_id > 0" :hideCriarContato="!(form.contato_id > 0) && form.terminado!=1 && PERMISSOES.contatos_gerir > 0" :deleteContato="form.contato_id > 0 && form.terminado!=1" :contatoID="form.contato_id > 0" :obrigatorio="true" :mostrarNome="true"></pesq-contactos>
                    </div>
                    <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="form.terminado != 1 && (page2 == 'faturas' || page2 == 'guias' || page2 == 'orcamentos') && form.contato_divida && form.contato_id > 0">
                      <div class="input-group">
                        <span class="input-group-addon">
                          {{l('saldo_divida')}}
                        </span>
                        <input disabled readonly autocomplete="off" type="text" class="form-control" v-model="form.contato_divida">
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="form.terminado != 1 && (page2 == 'faturas' || page2 == 'guias' || page2 == 'orcamentos') && form.contato_divida_vencida && form.contato_id > 0">
                      <div class="input-group">
                        <span class="input-group-addon text-danger">
                          {{l('saldo_divida_vencida')}}
                        </span>
                        <input disabled readonly autocomplete="off" type="text" class="form-control" v-model="form.contato_divida_vencida">
                      </div>
                    </div>

                    <div v-if="form.terminado && form.contato">
                      <div class="col-md-12 col-sm-12 col-lg-8 col-xs-12 form-group form-material" v-if="form.contato.morada && form.contato.morada !== 'Desconhecido'">
                        <div class="input-group">
                          <span class="input-group-addon">
                            {{$t('morada')}}
                          </span>
                          <input disabled autocomplete="off" type="text" class="form-control" v-model="form.contato.morada">
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-6 col-lg-4 col-xs-12 form-group form-material" v-if="form.contato.codigo_postal && form.contato.codigo_postal !== '0000-000'">
                        <div class="input-group">
                          <span class="input-group-addon">
                            {{$t('codigo_postal')}}
                          </span>
                          <input disabled type="text" class="form-control" v-model="form.contato.codigo_postal">
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-6 col-lg-4 col-xs-12 form-group form-material" v-if="form.contato.cidade && form.contato.cidade !== 'Desconhecido'">
                        <div class="input-group">
                          <span class="input-group-addon">
                            {{$t('cidade')}}
                          </span>
                          <input disabled type="text" class="form-control" v-model="form.contato.cidade">
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-6 col-lg-4 col-xs-12 form-group form-material">
                        <div class="input-group">
                          <span class="input-group-addon">
                            {{$t('pais')}}
                          </span>
                          <select class="form-control" v-model="form.contato.pais" disabled>
                            <option v-for="(dt, i) in paises" :key="i" :value="dt.code">{{dt.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-6 col-lg-4 col-xs-12 form-group form-material" v-if="form.contato.nif != '999999990'">
                        <div class="input-group">
                          <span class="input-group-addon">
                            {{$t('contribuinte')}}
                          </span>
                          <input disabled autocomplete="off" type="text" class="form-control" v-model="form.contato.nif">
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 col-sm-12 form-group form-material" v-if="moradas.length && form.terminado < 1 && ao.lojaGuia == 'contacto'">
                      <div class="input-group">
                        <span class="input-group-addon">{{l('moradas_faturacao')}}<span class="verm">*</span></span>
                        <select ref="morada1" class="form-control foco" v-model="form.morada1" :disabled="form.terminado > 0" id="morada1" @keydown.enter.prevent="FOCO" required="required" @change="upMorada()">
                          <option value="sede">{{l('sede')}}</option>
                          <optgroup v-for="(mrd, i) in moradas" :key="i" :label="mrd.pais">
                            <option v-for="(dt, index) in mrd.moradas" :key="index" :value="dt.id" >{{dt.morada}} ({{dt.codigo_postal}} {{dt.cidade}})</option>
                          </optgroup>
                        </select>
                      </div>
                      <p v-if="form.terminado < 1" class="ajuda margin-top-5 text-justify margin-left-10" v-html="l('ajuda_moradas')"></p>
                    </div>

                    <div class="col-md-12 col-sm-12 form-material" v-if="form.tipo_documento_tp == 'NC' || form.tipo_documento_tp == 'ND' || form.tipo_documento_tp == 'VNC' || form.tipo_documento_tp == 'VND'">
                      <div class="input-group">
                        <span class="input-group-addon">{{l('motivo')}}<span class="verm">*</span></span>
                        <input ref="motivo" autocomplete="off" type="text" class="form-control foco" v-model="form.motivo" :disabled="form.terminado > 0" id="motivo" @keydown.enter.prevent="FOCO" maxlength="50" minlength="3" v-texto @blur="openConverter()">
                      </div>
                      <p v-if="form.terminado < 1" class="ajuda margin-top-5 text-justify margin-left-10" v-html="l('ajuda_motivo')"></p>
                    </div>

                    <div class="clearfix"></div>
                    <p v-if="!form.tipo_documento_id" class="pull-right text-justify margin-0 margin-right-10"><span class="verm">*</span> {{$t('campos_obrigatorios')}}</p>
                  </div>
                </div>
                <!--<div class="clearfix"></div>
                <div :class="{'col-md-12 padding-0 panel margin-0 padding-bottom-15': 1, 'padding-top-15': form.terminado}" v-if="form.tipo_documento_id == 31 && form.contato_id > 0 || form.tipo_documento_tp == 'ESTK'">
                  <div class="panel-heading" v-if="form.terminado != 1">
                    <h3 class="panel-title padding-10">{{l('valor_titulo')}}</h3>
                  </div>
                  <div class="panel-body container-fluid padding-0">

                        <div :class="{'col-md-6 col-sm-6 form-group form-material': 1, 'col-lg-4': form.tipo_documento_tp == 'ESTK', 'col-lg-4': form.tipo_documento_tp != 'ESTK'}">
                          <div class="input-group">
                              <span class="input-group-addon">{{$t('data')}}<span class="verm">*</span></span>
                              <input ref="data" autocomplete="off" maxlength="10" pattern="[0-9]{2}\/[0-9]{2}\/[0-9]{4}$" min="2017-01-01" :max="ano + '-12-31'" type="date" class="form-control foco" v-model="data1" :placeholder="$t('data_defeito')" :disabled="form.terminado > 0" id="data" @keydown.enter.prevent="FOCO" required="required" @change="upData">
                          </div>
                        </div>

                        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="form.tipo_documento_tp != 'ESTK'">
                          <div class="input-group">
                              <span class="input-group-addon">{{l('metodo_pagamento')}}<span class="verm">*</span></span>
                              <select class="form-control foco" v-model="form.metodo_pagamento_id" :disabled="form.terminado > 0" id="pagamento" @keydown.enter.prevent="FOCO" required="required">
                                <option v-for="(dt, index) in pagamento" :key="index" :value="dt.id" v-if="dt.visivel">{{dt.nome}}</option>
                              </select>
                          </div>
                        </div>

                        <div :class="{'col-md-6 col-sm-6 form-group form-material': 1, 'col-lg-4': form.tipo_documento_tp == 'ESTK', 'col-lg-4': form.tipo_documento_tp != 'ESTK'}" v-if="$store.state.seriestotal > 1 && form.terminado != 1">
                          <div class="input-group">
                              <span class="input-group-addon">{{$t('serie')}}<span class="verm">*</span></span>
                              <select class="form-control foco" v-model="form.serie_id" :disabled="form.terminado > 0" id="serie" @keydown.enter.prevent="FOCO" required="required">
                                <option v-for="(dt, index) in series" :key="index" :value="dt.id" v-if="dt.visivel || form.serie_id == dt.id">{{dt.nome}} {{dt.cae && caes.length > 2 ? '(' + l('cae') + ': ' + dt.cae + ')' : ''}}</option>
                              </select>
                          </div>
                        </div>

                        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="form.tipo_documento_tp != 'ESTK'">
                          <div class="input-group">
                              <span class="input-group-addon">{{l('valor')}} ({{MOEDA}})<span class="verm">*</span></span>
                              <input ref="valor1" autocomplete="off" type="text" class="form-control foco" v-model="form.gross_total" :disabled="form.terminado > 0" id="valor1" @keydown.enter.prevent="FOCO" v-decimal>
                          </div>
                        </div>

                        <div class="col-md-6 col-sm-6 col-lg-6 form-group form-material" v-if="form.tipo_documento_tp == 'ESTK' && (form.terminado < 1 || form.observacoes && form.terminado)">
                          <div class="input-group">
                              <span class="input-group-addon">{{l('observacoes')}}</span>
                              <textarea autocomplete="off" rows="1" maxlength="200" v-if="form.terminado<1" v-tooltip.top-start="form.observacoes" class="form-control foco" id="observacoes" v-model="form.observacoes" :disabled="form.terminado > 0" @keydown.enter.prevent="FOCO"></textarea>
                              <span v-if="form.terminado">{{form.observacoes}}</span>
                          </div>
                        </div>
                  </div>
                </div>-->
                <div class="clearfix"></div>
                <div :class="{'panel margin-0 padding-bottom-15': 1, 'padding-top-15': form.terminado}" v-if="(form.contato_id > 0 || form.tipo_documento_tp == 'guiaglobal') && (form.tipo_documento_tp != 'ND' && form.tipo_documento_tp != 'NC' && form.tipo_documento_tp != 'VNC' && form.tipo_documento_tp != 'VND' || (form.tipo_documento_tp == 'ND' || form.tipo_documento_tp == 'NC' || form.tipo_documento_tp == 'VNC' || form.tipo_documento_tp == 'VND') && form.motivo.length > 2)">
                  <div class="panel-heading" v-if="form.terminado != 1">
                    <h3 class="panel-title padding-10">{{l('informacoes_gerais')}}</h3>
                  </div>
                  <div class="panel-body container-fluid padding-0">

                        <!--<div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="form.terminado">
                          <div class="input-group">
                              <span class="input-group-addon">{{$t('data')}}<span class="verm">*</span></span>
                              <input type="text" class="form-control" :value="data1Time1" disabled>
                          </div>
                        </div>-->
                        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material margin-0">
                          <div class="input-group">
                            <span class="input-group-addon">{{$t('data')}}<span class="verm">*</span></span>
                            <!--<input autocomplete="off" maxlength="10" pattern="[0-9]{2}\/[0-9]{2}\/[0-9]{4}$" min="2017-01-01" :max="ano + '-12-31'" type="date" class="form-control foco" v-model="data1" :placeholder="$t('data_defeito')" :disabled="form.terminado || form.tipo_documento_tp == 'FS'" id="data" @keydown.enter.prevent="FOCO" required="required" @change="upData">-->

                            <date-picker v-if="!form.terminado" :clearable="false" :editable="false" type="date" format="YYYY-MM-DD" :lang="$store.state.langData" class="foco" v-model="data1" :placeholder="$t('data_defeito')" :disabled="form.tipo_documento_tp == 'FS'" id="data" @change="upData()" required :disabled-date="disableDates"></date-picker>

                            <input v-else type="datetime-local" format="YYY-MM-DD HH:mm:ss" class="form-control" :value="form.status_date" disabled>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material margin-0" v-if="showTime1 && !form.terminado">
                          <div class="input-group">
                            <span class="input-group-addon">{{$t('hora')}}<span class="verm">*</span></span>
                            <!--<input autocomplete="off" maxlength="5" type="time" class="form-control foco" v-model="time1" :disabled="form.terminado > 0" id="hora" @keydown.enter.prevent="FOCO" required="required">-->

                            <date-picker :clearable="false" :editable="false" type="time" format="HH:mm:ss" :lang="$store.state.langData" class="foco" v-model="time1" :placeholder="$t('hora')" :disabled="form.terminado > 0" id="hora" required :open.sync="openTime" @change="handleChangeTime"></date-picker>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="$store.state.seriestotal > 1 && form.terminado != 1 || clonar">
                          <div class="input-group">
                            <span class="input-group-addon">{{$t('serie')}}<span class="verm">*</span></span>
                            <select class="form-control foco" v-model="form.serie_id" id="serie" @keydown.enter.prevent="FOCO" required="required" @change="upSerie()">
                              <option v-for="(dt, index) in series" :key="index" :value="dt.id" v-show="dt.visivel || form.serie_id == dt.id">{{dt.nome}} {{dt.cae && caes.length > 2 ? '(' + l('cae') + ': ' + dt.cae + ')' : ''}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="caes.length > 2 && form.terminado < 1 || form.terminado && form.cae">
                          <div class="input-group">
                            <span class="input-group-addon">{{l('cae')}}</span>
                            <input type="text" class="form-control" v-model="form.cae" v-if="form.terminado" disabled />
                            <select class="form-control foco" v-model="form.cae" v-else id="cae" @keydown.enter.prevent="FOCO">
                              <option v-for="(dt, index) in caes" :key="index" :value="dt">{{dt}}</option>
                            </select>
                          </div>
                        </div>

                        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="page2 == 'compras' && form.tipo_documento_tp != 'SINIC' && form.tipo_documento_tp != 'PEDG' && (form.terminado && form.documento_original || form.terminado != 1)">
                          <div class="input-group">
                            <span class="input-group-addon">{{l('documento_original')}}</span>
                            <input autocomplete="off" type="text" class="form-control foco" v-model="form.documento_original" :disabled="form.terminado > 0" id="documento_original" @keydown.enter.prevent="FOCO" maxlength="25" v-texto>
                            <router-link class="input-group-addon padding-0 mao hidden-xs" :to="'/relatorios/conta-corrente/' + form.contato_id" target="_blank"><i class="glyphicon glyphicon-list"></i></router-link><router-link class="input-group-addon visible-xs menos30 mao" :to="'/relatorios/conta-corrente/' + form.contato_id" target="_blank" style="right: 16px;"><i class="glyphicon glyphicon-list"></i></router-link>
                          </div>
                        </div>

                        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="page2!='guias' && page2!='encomendas' && form.tipo_documento_tp != 'VGR' && form.tipo_documento_tp != 'VGT' && form.tipo_documento_tp != 'VNENC' && (form.metodo_pagamento_id && form.terminado || form.terminado != 1)">
                          <div class="input-group">
                            <span class="input-group-addon">{{l('metodo_pagamento')}}<span class="verm">*</span></span>
                            <select class="form-control foco" v-model="form.metodo_pagamento_id" :disabled="form.terminado > 0" id="pagamento" @keydown.enter.prevent="FOCO" required="required" @change="idChequeFN">
                              <option v-for="(dt, index) in pagamento" :key="index" :value="dt.id">{{dt.nome}}</option>
                            </select>
                          </div>
                        </div>

                        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="(form.tipo_documento_tp === 'RC' || form.tipo_documento_tp === 'RFC') && idCheque && !form.terminado">
                          <div class="input-group">
                            <span class="input-group-addon">{{l('n_cheque')}}</span>
                            <input autocomplete="off" type="text" class="form-control foco" v-model="form.n_cheque" :disabled="form.terminado > 0" id="n_cheque" @keydown.enter.prevent="FOCO" v-texto>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material margin-0" v-if="(form.tipo_documento_tp === 'RC' || form.tipo_documento_tp === 'RFC') && idCheque && !form.terminado">
                          <div class="input-group">
                            <span class="input-group-addon">{{l('data_cheque')}}</span>
                            <date-picker :clearable="false" :editable="false" type="date" format="YYYY-MM-DD" :lang="$store.state.langData" class="foco" v-model="form.data_cheque" :placeholder="$t('data')" :disabled="form.terminado > 0" id="data_cheque"></date-picker>
                          </div>
                        </div>

                        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="form.tipo_documento_tp != 'RC' && form.tipo_documento_tp != 'NL' && form.tipo_documento_tp != 'RFC' && form.tipo_documento_tp != 'FR' && form.tipo_documento_tp != 'NC' && page2!='guias' && form.tipo_documento_tp != 'VNC' && form.tipo_documento_tp != 'VGR' && form.tipo_documento_tp != 'VGT' && form.tipo_documento_tp != 'VNENC' && form.tipo_documento_tp !='SINIV' && form.tipo_documento_tp != 'SINIC' && form.tipo_documento_tp != 'SINIC' && form.tipo_documento_tp != 'FS' && form.tipo_documento_tp != 'VFS' && form.tipo_documento_tp != 'DP' && form.prazo_vencimento !== '0000-00-00'">
                          <div class="input-group">
                            <span class="input-group-addon">{{l('prazo_vencimento')}}<span class="verm">*</span></span>
                            <input autocomplete="off" type="number" v-tooltip.top-start="ao.prazo_vencimento1" @keyup="up_prazo_vencimento()" class="form-control foco text-center" v-model="ao.prazo_vencimento" v-if="form.terminado!=1" id="vencimento" @keydown.enter.prevent="FOCO" required="required" v-numerico />
                            <span class="input-group-addon padding-0 hidden-xs" v-if="form.terminado!=1">
                              {{$t('dias')}}
                            </span>
                            <span class="input-group-addon visible-xs menos30" style="right:16px;" v-if="form.terminado!=1">
                              {{$t('dias')}}
                            </span>
                            <input id="vencimento" autocomplete="off" type="text" class="form-control foco" disabled v-model="form.prazo_vencimento" v-else>
                          </div>
                        </div>

                        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="(form.terminado != 1 || form.terminado == 1 && form.moeda !== 'EUR_€') && form.tipo_documento_tp != 'SINIV' && form.tipo_documento_tp != 'SINIC' && form.tipo_documento_tp != 'GMD' && form.tipo_documento_tp != 'DP' && contato.pais !== 'PT'">
                          <div class="input-group">
                            <span class="input-group-addon">{{$t('moeda')}}<span class="verm">*</span></span>
                            <pais-moeda :estado="form.terminado ? true : false" :form="form" @cambio="cambio"></pais-moeda>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="'EUR_€'!=form.moeda && form.tipo_documento_tp != 'SINIV' && form.tipo_documento_tp != 'SINIC' && form.tipo_documento_tp != 'GMD' && form.tipo_documento_tp != 'DP'">
                          <div class="input-group">
                            <span class="input-group-addon">{{l('cambio')}}</span>
                            <input autocomplete="off" type="text" class="form-control foco text-center" v-model="form.cambio" :disabled="form.terminado > 0" id="cambio" @keydown.enter.prevent="FOCO" :placeholder="0 | valor2" v-decimal>
                            <span class="input-group-addon hidden-xs">{{form.moeda.split('_')[1]}}</span>
                            <span class="input-group-addon visible-xs menos30">{{form.moeda.split('_')[1]}}</span>
                          </div>
                        </div>

                        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="contato.pais!='PT' && form.terminado != 1 && form.tipo_documento_tp != 'guiaglobal' && form.tipo_documento_tp != 'SINIC' && form.tipo_documento_tp != 'SINIV' && form.tipo_documento_tp != 'GMD'">
                          <div class="input-group">
                            <span class="input-group-addon">
                              {{l('lingua_padrao')}}
                            </span>
                            <select class="form-control foco" v-model="form.lingua" id="lingua" @keydown.enter.prevent="FOCO">
                              <option value="en">English</option>
                              <option value="fr">Français</option>
                              <option value="pt">Português</option>
                            </select>
                          </div>
                        </div>

                        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="form.tipo_documento_tp == 'SINIV' || form.tipo_documento_tp == 'SINIC' || form.tipo_documento_tp == 'DP'">
                          <div class="input-group">
                            <span class="input-group-addon">{{l('valor')}} ({{MOEDA}})<span class="verm">*</span></span>
                            <input ref="divida" autocomplete="off" type="text" class="form-control text-center foco" v-model="form.gross_total" :disabled="form.terminado > 0" id="divida" @keydown.enter.prevent="FOCO" v-decimal :placeholder="0 | valor2">
                          </div>
                        </div>

                        <div class="col-md-12 col-sm-12 col-lg-12 form-group form-material" v-if="form.observacoes.length && form.terminado || form.terminado != 1">
                          <div class="input-group">
                            <span class="input-group-addon">{{l('observacoes')}}</span>
                            <textarea autocomplete="off" rows="1" maxlength="200" v-if="form.terminado<1" v-tooltip.top-start="form.observacoes" class="form-control foco" id="observacoes" v-model="form.observacoes" :disabled="form.terminado > 0" @keydown.enter.prevent="FOCO"></textarea>
                              <span v-if="form.terminado" v-html="form.observacoes.toString().replaceAll('\n', '<br />')"></span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="clearfix"></div>
                      <div :class="{'panel margin-0 padding-bottom-15': 1, 'padding-top-15': form.terminado}" v-if="page === 'quebrastock'">
                        <div class="panel-heading" v-if="form.terminado != 1">
                          <h3 class="panel-title padding-10">{{l('informacoes_gerais')}}</h3>
                        </div>
                        <div class="panel-body container-fluid padding-0">
                          <div class="col-md-4 col-sm-6 col-lg-4 form-group form-material">
                            <div class="input-group">
                              <span class="input-group-addon">{{$t('data')}}<span class="verm">*</span></span>
                              <!--<input autocomplete="off" maxlength="10" pattern="[0-9]{2}\/[0-9]{2}\/[0-9]{4}$" min="2017-01-01" :max="ano + '-12-31'" type="date" class="form-control foco" v-model="data1" :placeholder="$t('data_defeito')" :disabled="form.terminado > 0" id="data" @keydown.enter.prevent="FOCO" required="required">-->
                              <date-picker v-if="!form.terminado" :clearable="false" :editable="false" type="date" format="YYYY-MM-DD" :lang="$store.state.langData" class="foco" v-model="data1" :placeholder="$t('data_defeito')" :disabled="form.terminado > 0" id="data" required :disabled-date="disableDates"></date-picker>

                              <input v-else type="datetime-local" format="YYY-MM-DD HH:mm:ss" class="form-control" :value="form.status_date" disabled>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-6 col-lg-4 form-group form-material" v-if="$store.state.seriestotal > 1 && form.terminado != 1">
                            <div class="input-group">
                              <span class="input-group-addon">{{$t('serie')}}<span class="verm">*</span></span>
                              <select class="form-control foco" v-model="form.serie_id" id="serie" @keydown.enter.prevent="FOCO" required="required" @change="upSerie()">
                                <option v-for="(dt, index) in series" :key="index" :value="dt.id" v-show="dt.visivel || form.serie_id == dt.id">{{dt.nome}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-12 col-sm-12 col-lg-12 form-group form-material" v-if="form.observacoes.length && form.terminado || form.terminado != 1">
                            <div class="input-group">
                              <span class="input-group-addon">{{l('observacoes')}}</span>
                              <textarea autocomplete="off" rows="1" maxlength="200" v-if="form.terminado<1" v-tooltip.top-start="form.observacoes" class="form-control foco" id="observacoes" v-model="form.observacoes" :disabled="form.terminado > 0" @keydown.enter.prevent="FOCO"></textarea>
                                <span v-if="form.terminado">{{form.observacoes}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                        <!--recibos-->
                        <div class="margin-top-15" v-if="form.tipo_documento_tp == 'RC' || form.tipo_documento_tp == 'NL' || form.tipo_documento_tp == 'RFC'">
                          <div v-if="(form.tipo_documento_tp == 'RC' || form.tipo_documento_tp == 'NL') && PERMISSOES.recibos_gerir > 0 || form.tipo_documento_tp == 'RFC' && PERMISSOES.pagamentos_gerir > 0">
                            <div class="alert alert-danger alert-dismissible" v-if="form.serie_id>0 && data1 && form.contato_id > 0 && (form.tipo_documento_tp == 'RC' || form.tipo_documento_tp == 'NL' || form.tipo_documento_tp == 'RFC') && ao.docsrecibo.length==0 && form.terminado<1">
                              {{l('nao_tem_documentos_aberto')}}
                            </div>
                            <div class="panel margin-0 padding-bottom-15" v-if="form.serie_id>0 && data1 && form.contato_id > 0 && (form.tipo_documento_tp == 'RC' || form.tipo_documento_tp == 'NL' || form.tipo_documento_tp == 'RFC') && ao.docsrecibo.length && form.terminado<1">
                              <div class="panel-heading">
                                <h3 class="panel-title padding-10">{{l('docs_recibo')}}</h3>
                              </div>
                              <div class="container-fluid padding-0">

                                <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12">
                                  <div>
                                    <table id="tabelaFixa" class="table table-hover width-full table-condensed table-striped">
                                      <thead id="tabelaFixaThead" v-if="widthBody">
                                        <tr>
                                          <th class="width-45"></th>
                                          <th class="text-left">
                                            <input ref="all-recibos" type="checkbox" class="foco" id="all-recibos" @click="recboValAll()" v-model="allRecibos" @keydown.enter.prevent="FOCO" v-if="ao.docsrecibo.length > 1">
                                            <label for="all-recibos">{{$t('serie')}}</label>
                                          </th>
                                          <th class="text-left width-80">
                                            {{$t('data')}}
                                          </th>
                                          <th class="text-right">
                                            {{l('t_divida')}} ({{MOEDA}})
                                          </th>
                                          <th class="text-right">{{l('a_pagar')}} ({{MOEDA}})</th>
                                          <th class="text-right width-80">{{l('t_desc')}} ({{MOEDA}})</th>
                                          <th class="text-right">{{l('recebido')}} ({{MOEDA}})</th>
                                        </tr>
                                      </thead>
                                      <tbody id="tbodyFixa">
                                        <tr class="odd gradeX" v-for="(dt,index) in ao.docsrecibo" :key="index">
                                          <td v-if="!widthBody">
                                            <div class="margin-top-15">
                                              <span class="pull-right text-uppercase">{{dt.documento.invoice_date | data}}</span>
                                              <span class="pull-left"><input type="checkbox" class="foco" :id="'Ax' + index" @click="recboVal(dt)" :checked="dt.valor > 0" @keydown.enter.prevent="FOCO">
                                                <label :for="'Ax' + index">{{dt.documento.invoice_number}}<b v-if="dt.documento.documento_original"><br />{{dt.documento.documento_original}}</b></label></span>
                                              </div>
                                              <div class="clearfix"></div>
                                              <div class="text-uppercase margin-top-15">
                                                {{l('t_divida')}}
                                                <h4 class="margin-0 padding-0 text-danger pull-right">{{MOEDA}} {{dt.total_divida | valor2}}</h4>
                                              </div>
                                              <div class="clearfix"></div>
                                              <div class="text-uppercase margin-top-15">
                                                {{l('a_pagar')}}<br />
                                                <input style="width:80%" autocomplete="off" class="form-control foco text-center pull-right" type="text" v-model="dt.valor" :placeholder="0 | valor2" @keyup="valormax(dt)" @blur="valormax(dt)" :id="'B' + index" :ref="'B' + index" v-decimal>
                                              </div>
                                              <div class="clearfix"></div>
                                              <div class="text-uppercase margin-top-15" v-if="dt.valor > 0">
                                                {{$t('desconto')}}<br />
                                                <input style="width:80%" autocomplete="off" class="form-control foco text-center pull-right" type="text" v-model="dt.desconto" :placeholder="0 | valor2" @keyup="valormaxDesc(dt)" @blur="valormaxDesc(dt)" :id="'Bew' + index" :ref="'Bew' + index" v-decimal>
                                              </div>
                                              <div class="clearfix"></div>
                                              <div class="text-uppercase margin-top-15" v-if="dt.valor > 0">
                                                {{l('recebido')}}
                                                <h4 class="margin-0 padding-0 text-right">
                                                  {{dt.valor - (dt.desconto || 0) | valor2}}
                                                </h4>
                                              </div>
                                              <div class="padding-top-15 clearfix"></div>
                                            </td>

                                            <td class="text-left" v-if="widthBody">
                                              <router-link target="_blank" :to="'/documentos/editar/' + page2 + '/' + dt.documento.id" class="btn btn-pure btn-default"><i class="glyphicon glyphicon-search"></i></router-link>
                                            </td>
                                            <td class="text-left" v-if="widthBody">
                                              <div>
                                                <input :ref="'A' + index" type="checkbox" class="foco" :id="'A' + index" @click="recboVal(dt)" :checked="dt.valor > 0" @keydown.enter.prevent="FOCO">
                                                <label :for="'A' + index">{{dt.documento.invoice_number}}<b v-if="dt.documento.documento_original"><br />{{dt.documento.documento_original}}</b></label>
                                              </div>
                                            </td>
                                            <td class="text-left" v-if="widthBody">
                                              <a class="block" href="javascript:void(0)" @click="recboVal(dt)">{{dt.documento.invoice_date | data}}</a>
                                            </td>
                                            <td class="text-right semquebras label-danger" v-if="widthBody">
                                              <a class="block texto-branco" href="javascript:void(0)" @click="recboVal(dt)">
                                                {{dt.total_divida | valor2}}
                                              </a>
                                            </td>
                                            <td v-if="widthBody">
                                              <input autocomplete="off" class="form-control foco text-center" type="text" v-model="dt.valor" :placeholder="0 | valor2" @keyup="valormax(dt)" @blur="valormax(dt)" :id="'B' + index" :ref="'B' + index" @keydown.enter.prevent="FOCO" v-decimal>
                                            </td>
                                            <td v-if="widthBody">
                                              <input autocomplete="off" class="form-control foco text-center" type="text" v-model="dt.desconto" :placeholder="0 | valor2" @keyup="valormaxDesc(dt)" @blur="valormaxDesc(dt)" :id="'B' + index" :ref="'Bx' + index" @keydown.enter.prevent="FOCO" v-decimal v-if="dt.valor > 0">
                                            </td>
                                            <td v-if="widthBody">
                                              <h4 class="text-right semquebras padding-0 margin-0" v-if="dt.valor > 0">{{MOEDA}} {{dt.valor - (dt.desconto || 0) | valor2}}</h4>
                                            </td>
                                          </tr>
                                        </tbody>
                                        <tfoot class="back-e1" v-if="credito>0">
                                          <tr v-if="widthBody">
                                            <td class="text-uppercase text-right" colspan="3">
                                              <b>{{$t('total')}} ({{MOEDA}})</b>
                                            </td>
                                            <td class="text-right semquebras label-danger texto-branco" v-valor2:[credito]></td>
                                            <td>
                                              <input autocomplete="off" class="form-control foco text-center" type="text" v-model="ao.total_recibo" :placeholder="0 | valor2" @keyup="valortotalpagar()" @blur="valortotalpagar()" ref="totalpagar" id="totalpagar" @keydown.enter.prevent="FOCO" v-decimal>
                                            </td>
                                            <td class="text-right">
                                              <!--<input autocomplete="off" class="form-control foco text-center" type="text" v-model="ao.total_desconto" :placeholder="0 | valor2" @keyup="valortotalpagarDesconto()" @blur="valortotalpagarDesconto()" ref="totalpagar" id="totalpagar" @keydown.enter.prevent="FOCO" v-decimal>-->
                                              {{ao.total_desconto | valor2}}
                                            </td>
                                            <td>
                                              <h4 class="text-right semquebras padding-0 margin-0">{{ao.total_recibo - ao.total_desconto | valor2}}</h4>
                                            </td>
                                          </tr>

                                          <tr v-else>
                                            <td>
                                              <div class="padding-vertical-20">
                                                <p><b class="text-uppercase">{{l('a_pagar')}} ({{MOEDA}})</b></p>
                                                <input style="width:80%" autocomplete="off" class="form-control foco text-center pull-right" type="text" v-model="ao.total_recibo" :placeholder="0 | valor2" @keyup="valortotalpagar()" @blur="valortotalpagar()" ref="totalpagar" id="totalpagar" @keydown.enter.prevent="FOCO" v-decimal>
                                                <div class="clearfix"></div>
                                                <div class="margin-0 padding-0 margin-top-15"><b class="text-uppercase">{{l('Total_desconto')}}</b></div>
                                                <h4 class="margin-0 padding-0 text-right"><span class="visible-xs">{{MOEDA}}</span> {{ao.total_desconto | valor2}}</h4>
                                                <div><b class="text-uppercase">{{l('recebido')}}</b></div>
                                                <h4 class="margin-0 padding-0 text-right">
                                                  <span class="visible-xs">{{MOEDA}}</span>{{ao.total_recibo - ao.total_desconto | valor2}}
                                                </h4>
                                              </div>
                                            </td>
                                          </tr>
                                        </tfoot>
                                      </table>
                                    </div>
                                    <p v-if="form.terminado < 1" class="ajuda margin-top-5 text-justify" v-html="l('ajuda_recibos')"></p>
                                    <div class="clearfix"></div>
                                    <p v-if="form.terminado < 1" class="pull-right text-justify margin-0 margin-right-10"><span class="verm">*</span> {{$t('campos_obrigatorios')}}</p>
                                  </div>
                                </div>
                              </div>

                              <div class="margin-top-15" v-if="form.terminado">
                                <div class="container-fluid padding-0">
                                  <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12 padding-0">
                                    <div class="table-responsive">
                                      <table class="table table-hover width-full table-condensed table-striped margin-0">
                                        <thead>
                                          <tr>
                                            <th class="text-left">
                                              {{l('documento')}}
                                            </th>
                                            <th class="text-right">
                                              {{$t('total')}} ({{MOEDA}})
                                            </th>
                                            <th class="text-right">
                                              {{l('t_desc')}} ({{MOEDA}})
                                            </th>
                                            <th class="text-right width-110">
                                             {{l('recebido')}} ({{MOEDA}})
                                           </th>
                                         </tr>
                                       </thead>
                                       <tbody>
                                        <tr class="odd gradeX" v-for="(dt, index) in form.pagou" :key="index">
                                          <td class="text-left">
                                            <div id="dt.documento_relacionado">
                                              <router-link target="_blank" :to="'/documentos/editar/' + page2 + '/' + dt.documento_relacionado_id">{{dt.documento_relacionado.invoice_number}}</router-link>
                                              <b v-if="dt.documento_relacionado.documento_original"><br />{{dt.documento_relacionado.documento_original}}</b>
                                            </div>
                                          </td>
                                          <td class="text-right semquebras" v-valor2:[dt.total]>
                                          </td>
                                          <td class="text-right semquebras">
                                            <span v-if="dt.total_desconto > 0" v-valor2:[dt.total_desconto]></span>
                                          </td>
                                          <td class="text-right semquebras label-success">
                                            <h4 class="margin-0 padding-0 texto-branco" v-valor2:[dt.total-dt.total_desconto]></h4>
                                          </td>
                                        </tr>
                                      </tbody>
                                      <tfoot class="back-e1" v-if="form.pagou">
                                        <tr v-if="form.pagou.length > 1">
                                          <td class="text-right text-uppercase">
                                            <b>{{$t('total')}} ({{MOEDA}})</b>
                                          </td>
                                          <td class="text-right semquebras">
                                            <b v-valor2:[parseFloat(form.gross_total)+parseFloat(form.desconto_total)]></b>
                                          </td>
                                          <td class="text-right semquebras">
                                            <b v-valor2:[form.desconto_total]></b>
                                          </td>
                                          <td class="text-right semquebras">
                                            <h4 class="margin-0 padding-0" v-valor2:[form.gross_total]></h4>
                                          </td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                          <msg-permissoes :ok="!((form.tipo_documento_tp == 'RC' || form.tipo_documento_tp == 'NL') && PERMISSOES.recibos_gerir > 0 || form.tipo_documento_tp == 'RFC' && PERMISSOES.pagamentos_gerir > 0)"></msg-permissoes>
                        </div>
                        <div class="clearfix"></div>
                        <div :class="{'col-md-12 padding-0 margin-0 margin-top-15': 1, 'panel': form.terminado != 1}" v-if="form.serie_id>0 && data1 && (form.contato_id > 0 || form.tipo_documento_tp == 'guiaglobal') && form.tipo_documento_tp != 'RC' && form.tipo_documento_tp != 'NL' && form.tipo_documento_tp != 'RFC'  && (form.tipo_documento_tp != 'ND' && form.tipo_documento_tp != 'NC' && form.tipo_documento_tp != 'VND' && form.tipo_documento_tp != 'VNC' || (form.tipo_documento_tp == 'ND' || form.tipo_documento_tp == 'NC' || form.tipo_documento_tp == 'VND' || form.tipo_documento_tp == 'VNC') && form.motivo.length > 2) && form.tipo_documento_tp != 'DP' && form.tipo_documento_tp != 'SINIV' && form.tipo_documento_tp != 'SINIC' || page === 'quebrastock'" @click="searchNumSerie()">
                          <div class="panel-heading" v-if="form.terminado != 1">
                            <h3 class="panel-title padding-10">
                              {{l('tabela_artigos')}}
                              <button id="limparprecos1" @click="limparPrecosGuias()" v-if="(page2 === 'guias' || page2 === 'compras' && (form.tipo_documento_tp == 'VGT' || form.tipo_documento_tp == 'VGR')) && form.terminado != 1" class="btn btn-danger btn-xs pull-right visible-xs visible-sm"><i class="glyphicon glyphicon-erase"></i></button>

                              <div class="form-group form-material pull-right pr top--10" v-if="form.tipo_documento_tp == 'NC' || form.tipo_documento_tp == 'VNC'">
                                <div class="margin-left-20">
                                  <input id="nota_credito_stock" ref="nota_credito_stock" class="foco" type="checkbox" v-model="form.retorna_stock" @keydown.enter.prevent="FOCO">
                                  <label for="nota_credito_stock">{{l('retorna_stock')}}</label>
                                  <div class="clearfix"></div>
                                </div>
                              </div>
                            </h3>
                          </div>
                          <div :class="{'panel-body': form.terminado != 1, 'container-fluid padding-0': 1}">
                            <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12 padding-0">
                              <div id="divTable" :class="{'margin-bottom-0': 1, 'padding-horizontal-10': form.terminado != 1}">
                                <draggable-draggable id="tabelaFixa" tag="table" :disabled="form.terminado || !widthBody && form.terminado != 1" draggable=".linhaProduto" class="table table-hover width-full table-condensed table-striped tableDocs margin-bottom-0">
                                  <!--<table id="tabelaFixa" class="table table-hover width-full table-condensed table-striped tableDocs margin-bottom-0">-->
                                    <thead id="tabelaFixaThead" slot="header">
                                      <tr v-if="widthBody">
                                            <!--<th class="text-left">
                                              {{l('t_codigo')}}
                                            </th>-->
                                            <th class="text-left">
                                              {{l('t_descricao')}}
                                            </th>
                                            <th class="text-right">
                                              {{l('t_qtd')}}
                                            </th>
                                            <!--<th class="text-center" v-if="widthBody" v-if="form.terminado !== 1">
                                              {{l('t_und')}}
                                            </th>-->
                                            <th class="text-right" v-if="form.tipo_documento_tp != 'GMD'">
                                              <button id="limparprecos" v-tooltip.top-start="l('limpar_precos_guias')" @click="limparPrecosGuias()" v-if="(page2 === 'guias' || page2 === 'compras' && (form.tipo_documento_tp == 'VGT' || form.tipo_documento_tp == 'VGR')) && form.terminado != 1" class="btn btn-danger btn-xs pull-left"><i class="glyphicon glyphicon-erase"></i></button>
                                              <span v-if="PrecoIVA">{{l('t_preco_iva')}}</span>
                                              <span v-else>{{l('t_preco')}}</span> ({{MOEDA}})
                                            </th>
                                            <th class="text-right" v-if="(PERMISSOES.vendas_pode_fazer_descontos > 0 && page2 != 'compras' || page2 == 'compras') && form.tipo_documento_tp != 'GMD' && page !== 'quebrastock'">
                                              {{l('t_desc')}} <span v-if="form.terminado">(%)</span> <button v-if="!form.terminado" id="trocar_perc_euro" v-tooltip.top-start="euroPerc ? l('trocar_perc_euro') : l('trocar_euro_perc')" @click="euroPerc = !euroPerc" class="btn btn-warning btn-xs pull-left" v-text="euroPerc ? '%' : '€'"></button>
                                            </th>
                                            <!--<th class="text-right" v-if="form.terminado < 1 && (PERMISSOES.vendas_pode_fazer_descontos > 0 && page2 != 'compras' || page2 == 'compras')">
                                              {{l('t_desc1')}}<br />(%)
                                            </th>
                                            <th class="text-right" style="width:50px;" v-if="form.terminado < 1 && (desconto2 && (PERMISSOES.vendas_pode_fazer_descontos > 0 && page2 != 'compras' || page2 == 'compras'))">
                                              {{l('t_desc2')}}<br />(%)
                                            </th>
                                            <th class="text-right" style="width:50px;" v-if="form.terminado < 1 && (desconto3 && (PERMISSOES.vendas_pode_fazer_descontos > 0 && page2 != 'compras' || page2 == 'compras'))">
                                              {{l('t_desc3')}}<br />(%)
                                            </th>-->
                                            <th class="text-right" v-if="RETENCAO>0 && form.cliente_retencao && form.tipo_documento_tp != 'GMD'">
                                              {{l('t_ret')}} (<span v-if="form.terminado">{{MOEDA}}</span><span v-else>%</span>)
                                            </th>
                                            <th class="text-right width50" v-if="form.tipo_documento_tp != 'GMD' && form.tipo_documento_tp != 'GC' && form.tipo_documento_tp != 'FC'">
                                              {{l('t_iva')}} (%)
                                            </th>
                                            <th class="text-right" v-if="form.tipo_documento_tp != 'GMD'">
                                              <span v-if="PrecoIVA">{{l('t_total_iva')}}</span><span v-else>{{$t('total')}} ({{MOEDA}})</span>
                                            </th>
                                            <th :colspan="form.produtos[1] ? 2 : 1" v-show="form.terminado < 1"></th>
                                          </tr>
                                        </thead>
                                        <tbody v-for="(dt1, index1) in produtosLinha" :key="index1" class="linhaProduto">
                                          <tr :id="dt.id1" :class="{'odd gradeX': 1, 'produto': !dt.item_pai_id, 'artigo-composto': dt.item_pai_id}" v-for="(dt, index) in dt1" :key="index">
                                            <td class="text-left">
                                              <p class="text-right margin-bottom-0" v-if="(dt.lancamento_pai_id>0 && dt.convertido || clonar) && dt.convertido || form.terminado && dt.referencia_manual">{{dt.convertido}} {{dt.referencia_manual}}</p>
                                              <span class="pull-right" v-if="page !== 'quebrastock'">
                                                <span v-if="REFERENCIAMANUAL && form.terminado != 1 && !(dt.lancamento_pai_id>0) && !dt.convertido && form.tipo_documento_tp != 'ND' && form.tipo_documento_tp != 'NC' && form.tipo_documento_tp != 'GMD' && !dt.produto_composto">
                                                  <button v-tooltip.top-start="l('origem')" class="btn btn-warning text-uppercase" @click="referenciaManualUp(dt, index)" type="button" id="bot_ref_manual"><i class="glyphicon glyphicon-list-alt"></i></button>
                                                </span>
                                                <span v-if="(form.terminado < 1 && (!(dt.lancamento_pai_id > 0 || dt.convertido) || !(dt.lancamento_pai_id > 0 || !dt.convertido) && dt.servico != 1 && NUMEROSERIE))">
                                                  <button v-if="!(dt.lancamento_pai_id > 0 || dt.convertido) && dt.servico != 1 && NUMEROSERIE && !dt.produto_composto" v-tooltip.top-start="l('add_numero_de_serie')" class="btn btn-success margin-left-5" @click="serialsUp(dt, index)" type="button"><i class="glyphicon glyphicon-barcode" id="bot_num_serie"></i></button>
                                                  <button v-else-if="NUMEROSERIE" style="visibility: hidden" class="btn btn-success margin-left-5" type="button"><i class="glyphicon glyphicon-barcode"></i></button>
                                                  <button v-if="!(dt.lancamento_pai_id > 0 || dt.convertido)" v-tooltip.top-start="l('add_descricao_extra')" class="btn btn-primary margin-left-5" @click="descricaoExtraUp(dt, index)" type="button" id="bot_descricao_extra"><i class="glyphicon glyphicon-plus-sign"></i></button>
                                                </span>
                                              </span>
                                              <div class="clearfix"></div>
                                              <router-link v-if="form.terminado" :to="'/itens/editar/' + dt.item_id" :class="{'text-justify documentoProduto': 1, 'padding-left-15': dt.item_pai_id}" style="white-space: normal"><i v-if="dt.item_pai_id" class="glyphicon glyphicon-paperclip"></i> {{dt.codigo}}<br /><b :class="{'padding-left-33': dt.item_pai_id}">{{dt.nome}}</b></router-link>
                                              <router-link v-if="form.terminado != 1 && dt.stock" :to="'/relatorios/movimentos-produto/' + dt.item_id + '/' + form.contato_id" target="_blank" :class="{'text-justify pull-left': 1, 'padding-left-15': dt.item_pai_id}" style="white-space: normal"><i v-if="dt.item_pai_id" class="glyphicon glyphicon-paperclip"></i> {{dt.codigo}}<br /><b>{{dt.nome}}</b></router-link>
                                              <span v-if="form.terminado != 1 && !dt.stock" :class="{'text-justify pull-left': 1, 'padding-left-15': dt.item_pai_id}" style="white-space: normal"><i v-if="dt.item_pai_id" class="glyphicon glyphicon-paperclip"></i> {{dt.codigo}}<br /><b>{{dt.nome}}</b></span>

                                              <div class="clearfix"></div>
                                              <div v-if="dt.variavel && dt.variavelAr" class="margin-top-10">
                                                <div v-show="variavel" v-for="(variavel, ind11) in dt.variavelAr.value" :key="'f9mds-' + ind11">{{dt.variavelAr.name[ind11]}}: <b>{{variavel}}</b>
                                                </div>
                                              </div>

                                              <div class="clearfix"></div>
                                              <div v-if="form.terminado < 1 && (!(dt.lancamento_pai_id>0 && dt.convertido) && (form.tipo_documento_tp == 'ND' || form.tipo_documento_tp == 'NC') || REFERENCIAMANUAL && dt.referencia_manual1)" class="margin-top-10"><input autocomplete="off" class="form-control foco" type="text" v-tooltip.top-start="l('referencia_manual_desc') + ((form.tipo_documento_tp == 'ND' || form.tipo_documento_tp == 'NC') ? '(' + $t('obrigatorio') + ')' : '(' + $t('opcional') + ')' )" v-model="dt.referencia_manual" name="referencia_manual[]" minlength="3" maxlength="60" :disabled="form.terminado > 0" :id="'A_-' + index1 + '-' + index" :ref="'A_-' + index1 + '-' + index" @keydown.enter.prevent="FOCO" :placeholder="l('referencia_manual')" required="required"></div>
                                              <p v-if="form.terminado != 1 && (form.tipo_documento_tp == 'ND' || form.tipo_documento_tp == 'NC' || form.tipo_documento_tp == 'VND' || form.tipo_documento_tp == 'VNC') && !((dt.lancamento_pai_id>0 && dt.convertido || clonar) && dt.convertido)" class="text-justify text-danger" v-html="l('converter_desc')"></p>

                                              <div class="clearfix"></div>
                                              <div class="margin-top-5" v-if="(form.terminado || dt.lancamento_pai_id > 0 || dt.convertido) && dt.descricao_extra" v-html="dt.descricao_extra.toString().replaceAll('\n', '<br />')"></div>

                                              <textarea autocomplete="off" v-if="dt.descricao_extra1 && form.terminado < 1 && !(dt.lancamento_pai_id > 0 && dt.convertido)" class="form-control foco margin-top-10" type="text" v-model="dt.descricao_extra" name="pnotas[]" maxlength="150" :placeholder="l('descricao_extra')" :id="'A-'+index1 + '-' + index" :ref="'A-'+index1 + '-' + index" @keydown.enter.prevent="FOCO"></textarea>
                                          <!--<span v-if="form.terminado || dt.lancamento_pai_id>0">{{dt.nome}}</span>
                                            <input autocomplete="off" v-else type="text" v-tooltip.top-start="dt.nome" v-model="dt.nome" name="pdescricao[]" required="required" maxlength="150" :id="'A-'+index1 + '-' + index" @keydown.enter.prevent="FOCO([(!(dt.lancamento_pai_id>0 && dt.convertido) && (form.tipo_documento_tp == 'ND' || form.tipo_documento_tp == 'NC' || REFERENCIAMANUAL) ? 'A_' : 'D') + index])">-->
                                            <!-- v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'" -->

                                            <!-- v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'" -->
                                            <div class="clearfix"></div>
                                            <!--input-group-->
                                            <div class="form-group margin-top-10" v-show="dt.serials1 && form.terminado < 1 && !(dt.lancamento_pai_id > 0 && dt.convertido) && !dt.servico" v-for="(serie, i) in dt.serials" :key="i">
                                              <input @focus="serialFocou(serie, dt, index)" autocomplete="off" class="text-left form-control foco" type="text" v-model="serie.serial" :name="'serialsA-' + index1 + '-' + index + '-' + i" :id="'Z' + index1 + '-' + index + '-' + i" :ref="'Z' + index1 + '-' + index + '-' + i" :placeholder="l('numero_de_serie')" maxlength="50" @keydown.enter.prevent="FOCO" @click.stop="" @keyup="produto(dt, index)">
                                            <!--<span class="input-group-btn" v-if="0">
                                              <button class="btn btn-danger padding-vertical-5" @click="LimparSerial(serie, dt, index)" type="button"><i class="glyphicon glyphicon-trash"></i></button>
                                            </span>-->
                                            <div class="table-responsive">
                                              <table v-if="serie.serialFocus && page2 !== 'compras' && page2 !== 'encomendas'" class="table table-hover width-full table-condensed table-striped tableDocs margin-bottom-0 tabelaVerde">
                                                <tbody>
                                                  <tr v-for="(s,i) in dt.numeros_serie_nao_usados" :key="i" v-show="s.serial[0] && (s.serial.toString().toLowerCase().indexOf(serie.serial.toString().toLowerCase()) !== -1 || !serie.serial)">
                                                    <td class="mao" @click="serie.serial = s.serial;serie.id = s.id;" v-html="s.serial.toString().replace(RegExp(serie.serial, 'gi'), '<b>$&</b>')"></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                          <div v-if="(form.terminado || dt.lancamento_pai_id > 0 || dt.convertido) && dt.serials">
                                            <b v-if="dt.serials.length === 1 && dt.serials[0].serial.length">{{l('numero_de_serie')}}:</b>
                                            <b v-if="dt.serials.length > 1 && dt.serials[0].serial.length">{{l('numeros_de_serie')}}:</b>
                                            <div class="text-right" v-for="(serie, i) in dt.serials" :key="i">{{serie.serial}}</div>
                                          </div>
                                          <div class="clearfix"></div>

                                          <slot name="composicao" :dt="dt"></slot>

                                          <div class="clearfix"></div>
                                          <div v-if="!widthBody" class="padding-vertical-5">
                                            <div class="col-xs-6 col-sm-6 margin-top-10">
                                              <b>{{l('t_qtd')}} ({{dt.unidade}})</b><br />
                                              <input autocomplete="off" class="text-center form-control foco" type="text" v-model="dt.quantidade" name="pquantidade[]" required="required" @keyup="produto(dt, index, null)" @blur="calcularQuantidade(dt, index, 'qt-' + index1 + '-' + index)" :disabled="form.terminado || dt.item_pai_id" :id="'qt-' + index1 + '-' + index" :ref="'qt-' + index1 + '-' + index" @keydown.enter.prevent="FOCO" v-decimalx placeholder="1" />
                                            </div>

                                            <div class="col-xs-6 col-sm-6 margin-top-10" v-if="PrecoIVA && !dt.produto_composto">
                                              <b>{{l('t_preco_c_iva')}} ({{MOEDA}})</b><br />
                                              <input autocomplete="off" type="text" v-model="dt.preco_unitario_iva" name="pvalor1[]" required="required" class="form-control foco text-center" @keyup="produto(dt, index)" @blur="produto(dt, index)" :placeholder="0 | valor2" :disabled="form.terminado || PERMISSOES.vendas_pode_alterar_precos < 1 && page2 != 'compras'" :id="'pp-' + index1 + '-' + index" :ref="'pp-' + index1 + '-' + index" @keydown.enter.prevent="FOCO" v-decimal />
                                            </div>
                                            <div class="col-xs-6 col-sm-6 margin-top-10" v-if="!PrecoIVA && !dt.produto_composto">
                                              <b>{{l('t_preco_s_iva')}} ({{MOEDA}})</b><br />
                                              <input autocomplete="off" class="form-control foco text-center" type="text" v-model="dt.preco_unitario" name="pvalor[]" required="required" @keyup="produto(dt, index)" @blur="produto(dt, index)" :placeholder="0 | valor2" :disabled="form.terminado || PERMISSOES.vendas_pode_alterar_precos < 1 && page2 != 'compras'" :id="'po-' + index1 + '-' + index" :ref="'po-' + index1 + '-' + index" @keydown.enter.prevent="FOCO" v-decimal />
                                            </div>

                                            <div :class="{'col-xs-4 col-sm-4': dt.desconto_1 > 0 && dt.desconto_2 > 0, 'col-xs-6 col-sm-6': !dt.desconto_2 || !dt.desconto_1, 'margin-top-10': 1}" v-if="(((PERMISSOES.vendas_pode_fazer_descontos > 0 && page2 != 'compras' && form.terminado != 1 || page2 == 'compras') || form.terminado && dt.desconto_1 > 0) && page !== 'quebrastock') && !dt.produto_composto">
                                              <b>{{l('t_desconto1')}} (%)</b><br />
                                              <input autocomplete="off" maxlength="6" class="form-control foco text-center" type="text" v-model="dt.desconto_1" :placeholder="0 | valor2" @keyup="ao.desconto_percentual = '';produto(dt, index)" @blur="produto(dt, index)" :disabled="form.terminado > 0" :id="'dd-' + index1 + '-' + index" :ref="'dd-' + index1 + '-' + index" @keydown.enter.prevent="FOCO" v-percentagem>
                                            </div>
                                            <div :class="{'col-xs-4 col-sm-4': dt.desconto_1 > 0 && dt.desconto_2 > 0, 'col-xs-6 col-sm-6': !dt.desconto_2, 'margin-top-10': 1}" v-if="(form.terminado != 1 && page !== 'quebrastock' && (PERMISSOES.vendas_pode_fazer_descontos > 0 && page2 != 'compras' || page2 == 'compras') && dt.desconto_1 > 0 && dt.desconto_1 < 100 || form.terminado && dt.desconto_1 > 0 && dt.desconto_2 > 0) && !dt.produto_composto">
                                              <b>{{l('t_desconto2')}} (%)</b><br />
                                              <input autocomplete="off" maxlength="6" class="form-control foco text-center" type="text" v-model="dt.desconto_2" :placeholder="0 | valor2" @keyup="ao.desconto_percentual = '';produto(dt, index)" @blur="produto(dt, index)" :disabled="form.terminado > 0" :id="'ds-' + index1 + '-' + index" :ref="'ds-' + index1 + '-' + index" @keydown.enter.prevent="FOCO" v-percentagem />
                                            </div>
                                            <div class="col-xs-4 col-sm-4 margin-top-10" v-if="(form.terminado != 1 && page !== 'quebrastock' && (PERMISSOES.vendas_pode_fazer_descontos > 0 && page2 != 'compras' || page2 == 'compras') && dt.desconto_1 > 0 && dt.desconto_1 < 100 && dt.desconto_2 > 0 && dt.desconto_2 < 100 || form.terminado && dt.desconto_1 > 0 && dt.desconto_2 > 0 && dt.desconto_3 > 0) && !dt.produto_composto">
                                              <b>{{l('t_desconto3')}} (%)</b><br />
                                              <input autocomplete="off" maxlength="6" class="form-control foco text-center" type="text" v-model="dt.desconto_3" :placeholder="0 | valor2" @keyup="ao.desconto_percentual = '';produto(dt, index)" @blur="produto(dt, index)" :disabled="form.terminado > 0" :id="'dt-' + index1 + '-' + index" :ref="'dt-' + index1 + '-' + index" @keydown.enter.prevent="FOCO" v-percentagem />
                                            </div>

                                            <div class="col-xs-6 col-sm-6 margin-top-10">
                                              <b>{{l('t_desc')}} (€)</b><br />
                                              <div v-if="PrecoIVA">
                                                <input autocomplete="off" class="form-control foco text-center" type="text" v-model="dt.desconto_1Civa" :placeholder="0 | valor2" :id="'E2-'+index1 + '-' + index" :ref="'E2-'+index1 + '-' + index" @keydown.enter.prevent="FOCO" @keyup="euroPercFN(dt, index)" @blur="euroPercFN(dt, index)" v-decimal :disabled="form.terminado > 0" />
                                              </div>
                                              <div v-else>
                                                <input autocomplete="off" class="form-control foco text-center" type="text" v-model="dt.desconto_1Siva" :placeholder="0 | valor2" :id="'E1-'+index1 + '-' + index" :ref="'E1-'+index1 + '-' + index" @keydown.enter.prevent="FOCO" @keyup="euroPercFN(dt, index)" @blur="euroPercFN(dt, index)" v-decimal :disabled="form.terminado > 0" />
                                              </div>
                                            </div>

                                            <div class="col-xs-6 col-sm-6 margin-top-10" v-if="((RETENCAO>0 && form.cliente_retencao || dt.lancamento_pai_id > 0 && page2 === 'compras') && dt.servico && form.terminado < 1|| form.terminado && dt.retencao > 0) && !dt.produto_composto">
                                              <b>{{l('t_ret')}} (%)</b><br />
                                              <input autocomplete="off" class="form-control foco text-center" type="text" v-model="dt.retencao" :placeholder="0 | valor2" @keyup="produto(dt, index)" @blur="produto(dt, index)" :disabled="form.terminado || form.terminado < 1 && dt.lancamento_pai_id > 0" :id="'rt-' + index1 + '-' + index" :ref="'rt-' + index1 + '-' + index" @keydown.enter.prevent="FOCO" v-percentagem />
                                            </div>

                                            <div v-if="!dt.produto_composto && form.tipo_documento_tp != 'FC' && form.tipo_documento_tp != 'GC'" :class="{'col-xs-12 col-sm-12': form.terminado && dt.imposto>0 || form.terminado, 'col-xs-6 col-sm-6': form.terminado != 1, 'margin-top-10': 1}">
                                              <b v-if="(form.terminado || form.terminado != 1 || dt.lancamento_pai_id>0)">{{l('t_iva')}}</b> <div class="clearfix" v-if="form.terminado != 1"></div>
                                              <span v-if="(form.terminado || (dt.lancamento_pai_id>0 && dt.convertido.toString().indexOf('FC ') != 0))"><span v-if="dt.imposto>0"> {{dt.imposto}}%</span><span v-else style="white-space: normal">{{Principal(razoesIsencaoIva, dt.motivo_isencao_id, 1).motivo}}</span></span>
                                              <select class="form-control foco" v-else v-model="dt.imposto_id" required="required" @change="produto(dt, index)" :id="'iv-' + index1 + '-' + index" :ref="'iv-' + index1 + '-' + index" @keydown.enter.prevent="FOCO">
                                                <!--<option value="0" :selected="dt.imposto == 0" v-if="form.tipo_documento_tp != 'FS'">{{l('isento_iva')}}</option>-->
                                                <option v-show="form.tipo_documento_tp != 'FS' || form.tipo_documento_tp == 'FS' && dt.id > 0" v-for="(dt, i) in impostos" :key="i" :value="dt.id">{{dt.valor}} %</option>
                                              </select>
                                            </div>
                                            <div class="col-xs-12 col-sm-12 margin-top-10" v-if="((form.terminado < 1 && !dt.imposto && !(dt.lancamento_pai_id > 0 && dt.convertido.toString().indexOf('FC ') != 0)) && form.tipo_documento_tp != 'FS') && !dt.produto_composto && form.tipo_documento_tp != 'FC' && form.tipo_documento_tp != 'GC'">
                                              <b>{{l('razao_isencao_iva')}}</b><br />
                                              <select class="form-control foco" v-model="dt.motivo_isencao_id" :id="'is-' + index1 + '-' + index" :ref="'is-' + index1 + '-' + index" @keydown.enter.prevent="FOCO">
                                                <option v-show="dt.activo" v-for="(dt, i) in razoesIsencaoIva" :key="i" :value="dt.id">{{dt.motivo}}</option>
                                              </select>
                                            </div>

                                            <div class="clearfix"></div>
                                            <div class="margin-top-10 margin-horizontal-20">
                                              <button v-if="form.terminado != 1 && !dt.item_pai_id" type="button" @click="removeItem(dt, index)" class="btn btn-danger btn-xs pr pull-left margin-top-10"><i class="glyphicon glyphicon-trash"></i></button>
                                              <!--<button v-if="form.terminado < 1 && form.produtos.length > 1 && !dt.item_pai_id" class="btn btn-default btn-xs pr pull-left margin-top-10 margin-left-10"><i class="glyphicon glyphicon glyphicon-move"></i></button>-->

                                              <h3 class="pull-right margin-0 padding-0 margin-top-10" v-if="dt.preco_unitario > 0 && dt.quantidade > 0">
                                                <div v-if="dt.produto_composto">
                                                  {{MOEDA}} <span class="risco text-danger semquebras" v-if="dt.desconto_total>0">{{PrecoIVA ? dt.preco_unitario_iva : dt.preco_unitario | valor2}}</span><span v-if="dt.desconto_total>0"> / </span>
                                                  <span>{{PrecoIVA ? dt.preco_unitario_iva - dt.desconto_total : dt.preco_unitario - dt.desconto_total | valor2}}</span>
                                                </div>
                                                <div v-else>
                                                  {{MOEDA}} <span class="risco text-danger semquebras" v-if="dt.desconto_1>0">{{PrecoIVA ? dt.preco_unitario_iva * dt.quantidade : dt.preco_unitario * dt.quantidade | valor2}}</span><span v-if="dt.desconto_1>0"> / </span>
                                                  <span>{{PrecoIVA ? dt.preco_unitario * dt.quantidade - dt.desconto_total + dt.total_iva : dt.preco_unitario * dt.quantidade - dt.desconto_total | valor2}}</span>
                                                </div>
                                              </h3>
                                              <h3 class="pull-right margin-0 padding-0 margin-top-10" v-else>{{MOEDA}} {{0 | valor2}}</h3>

                                            </div>
                                            <div class="clearfix"></div>
                                          </div>
                                        </td>
                                        <td class="text-right width50 semquebras" v-if="widthBody">
                                          <span v-if="form.terminado || dt.item_pai_id">{{dt.quantidade.toString().replace('.', ',')}} {{dt.unidade}}</span>
                                          <input autocomplete="off" class="width50 text-center form-control foco" type="text" v-model="dt.quantidade" name="pquantidade[]" required="required" @keyup="produto(dt, index)" @blur="calcularQuantidade(dt, index, 'D-'+index1 + '-' + index)" v-else :id="'D-'+index1 + '-' + index" :ref="'D-'+index1 + '-' + index" @keydown.enter="dt.quantidade.toString().indexOf('*') !== -1 ? calcularQuantidade(dt, index, 'D-'+index1 + '-' + index, 1) : ''" @keyup.enter.prevent="FOCO" v-decimalx placeholder="1" />
                                        </td>
                                        <!--<td class="text-left" v-if="!widthBody" v-if="form.terminado !== 1">
                                          {{dt.unidade}}
                                        </td>-->

                                        <td class="text-right width50" v-if="widthBody && PrecoIVA && form.tipo_documento_tp != 'GMD'">
                                          <div v-if="!dt.produto_composto">
                                            <span v-if="form.terminado || PERMISSOES.vendas_pode_alterar_precos < 1 && page2 != 'compras'">{{casasDec(dt.preco_unitario_iva)}}</span>
                                            <div v-else>
                                              <input autocomplete="off" type="text" v-model="dt.preco_unitario_iva" name="pvalor1[]" required="required" class="width50 form-control foco text-center" @keyup="produto(dt, index)" @blur="produto(dt, index)" :placeholder="0 | valor2" :id="'C-'+index1 + '-' + index" :ref="'C-'+index1 + '-' + index" @keydown.enter.prevent="FOCO" v-decimal>
                                            </div>
                                          </div>
                                        </td>
                                        <td class="text-right width-100" v-if="widthBody && !PrecoIVA && form.tipo_documento_tp != 'GMD'">
                                          <div v-if="!dt.produto_composto">
                                            <span v-if="form.terminado || PERMISSOES.vendas_pode_alterar_precos < 1 && page2 != 'compras'">{{casasDec(dt.preco_unitario)}}</span>
                                            <div v-else>
                                              <input autocomplete="off" class="form-control foco text-center" type="text" v-model="dt.preco_unitario" name="pvalor[]" required="required" @keyup="produto(dt, index)" @blur="produto(dt, index)" :placeholder="0 | valor2" :id="'B-'+index1 + '-' + index" :ref="'B-'+index1 + '-' + index" @keydown.enter.prevent="FOCO" v-decimal>
                                            </div>
                                          </div>
                                        </td>
                                        <td class="text-right width50" v-if="widthBody && form.terminado < 1 && (PERMISSOES.vendas_pode_fazer_descontos > 0 && page2 != 'compras' || page2 == 'compras') && form.tipo_documento_tp != 'GMD' && page !== 'quebrastock'">
                                          <div v-if="!dt.produto_composto">
                                            <div v-if="euroPerc">
                                              <input autocomplete="off" maxlength="6" class="width50 form-control foco text-center" type="text" v-model="dt.desconto_1" :placeholder="0 | valor2" :id="'E-'+index1 + '-' + index" :ref="'E-'+index1 + '-' + index" @keydown.enter.prevent="FOCO" @keyup="ao.desconto_percentual = '';produto(dt, index)" @blur="produto(dt, index)" v-percentagem />
                                              <div class="clearfix"></div>
                                              <input autocomplete="off" v-if="dt.desconto_1 > 0 && dt.desconto_1 < 100" maxlength="6" class="width50 form-control foco text-center" type="text" v-model="dt.desconto_2" :placeholder="0 | valor2" @keyup="produto(dt, index)" @blur="ao.desconto_percentual = '';produto(dt, index)" :id="'F-'+index1 + '-' + index" :ref="'F-'+index1 + '-' + index" @keydown.enter.prevent="FOCO" v-percentagem />
                                              <div class="clearfix"></div>
                                              <input autocomplete="off" v-if="dt.desconto_1 > 0 && dt.desconto_1 < 100 && dt.desconto_2 > 0 && dt.desconto_2 < 100" maxlength="6" class="width50 form-control foco text-center" type="text" v-model="dt.desconto_3" :placeholder="0 | valor2" @keyup="ao.desconto_percentual = '';produto(dt, index)" @blur="produto(dt, index)" :id="'G-'+index1 + '-' + index" :ref="'G-'+index1 + '-' + index" @keydown.enter.prevent="FOCO" v-percentagem />
                                            </div>
                                            <div v-else>
                                              <div v-if="PrecoIVA">
                                                <input autocomplete="off" class="width50 form-control foco text-center" type="text" v-model="dt.desconto_1Civa" :placeholder="0 | valor2" :id="'E2-'+index1 + '-' + index" :ref="'E2-'+index1 + '-' + index" @keydown.enter.prevent="FOCO" @keyup="euroPercFN(dt, index)" @blur="euroPercFN(dt, index)" v-decimal />
                                                <div class="clearfix"></div>
                                              </div>
                                              <div v-else>
                                                <input autocomplete="off" class="width50 form-control foco text-center" type="text" v-model="dt.desconto_1Siva" :placeholder="0 | valor2" :id="'E1-'+index1 + '-' + index" :ref="'E1-'+index1 + '-' + index" @keydown.enter.prevent="FOCO" @keyup="euroPercFN(dt, index)" @blur="euroPercFN(dt, index)" v-decimal />
                                                <div class="clearfix"></div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td class="text-right width50" v-if="widthBody && form.terminado >0 && ((PERMISSOES.vendas_pode_fazer_descontos > 0 && page2 != 'compras') || page2 == 'compras') && form.tipo_documento_tp != 'GMD' && page !== 'quebrastock'">
                                          <span v-if="dt.desconto_1 > 0">{{dt.desconto_1}}</span><span v-if="dt.desconto_2 > 0">+{{dt.desconto_2}}</span><span v-if="dt.desconto_3 > 0">+{{dt.desconto_3}}</span>
                                        </td>
                                        <td class="text-right width50" v-if="RETENCAO>0 && form.cliente_retencao && widthBody && form.tipo_documento_tp != 'GMD'">
                                          <div v-if="!dt.produto_composto">
                                            <span v-if="form.terminado && dt.retencao_total > 0 || dt.lancamento_pai_id > 0"><span v-if="dt.retencao_total > 0">{{MOEDA}} {{dt.retencao_total | valor}}</span></span>
                                            <div v-if="form.terminado < 1 && dt.servico && !dt.lancamento_pai_id">
                                              <input autocomplete="off" class="width50 form-control foco text-center" type="text" v-model="dt.retencao" :placeholder="0 | valor2" @keyup="produto(dt, index)" @blur="produto(dt, index)" :id="'I-'+index1 + '-' + index" :ref="'I-'+index1 + '-' + index" @keydown.enter.prevent="FOCO" maxlength="6" v-percentagem />
                                            </div>
                                          </div>
                                        </td>
                                        <td class="text-right width50" v-if="widthBody && form.tipo_documento_tp != 'GMD'  && form.tipo_documento_tp !== 'FC' && form.tipo_documento_tp !== 'GC'">
                                          <div v-if="!dt.produto_composto">
                                            <span v-if="(form.terminado || (dt.lancamento_pai_id>0  && dt.convertido.toString().indexOf('FC ') != 0))"><span v-if="dt.imposto>0">{{dt.imposto}}</span><span v-else>{{Principal(razoesIsencaoIva, dt.motivo_isencao_id, 1).motivo}}</span></span>
                                            <select class="width50 form-control foco selectDocs" v-else v-model="dt.imposto_id" required="required" :id="'K-'+index1 + '-' + index" :ref="'K-'+index1 + '-' + index" @keydown.enter.prevent="FOCO" @change="produto(dt, index)">
                                              <!--<option value="0" :selected="dt.imposto == 0" v-if="form.tipo_documento_tp != 'FS'">{{l('isento_iva')}}</option>-->
                                              <option v-show="form.tipo_documento_tp != 'FS' || form.tipo_documento_tp == 'FS' && dt.id > 0" v-for="(dt, i) in impostos" :key="i" :value="dt.id">{{dt.valor}}</option>
                                            </select>
                                            <div class="clearfix"></div>
                                            <!--nao imprimo o motivo de isençao
                                              <span v-if="form.terminado && dt.imposto == 0  && !dt.imposto_id || dt.lancamento_pai_id > 0 && form.terminado < 1 && !dt.imposto_id">{{Principal(razoesIsencaoIva, dt.motivo_isencao_id, 1).motivo}}</span>-->

                                              <select class="width50 form-control foco margin-top-10 selectDocs" v-if="(form.terminado < 1 && !dt.imposto && !(dt.lancamento_pai_id > 0 && dt.convertido.toString().indexOf('FC ') != 0)) && form.tipo_documento_tp != 'FS'" v-model="dt.motivo_isencao_id" :id="'L-'+index1 + '-' + index" :ref="'L-'+index1 + '-' + index" @keydown.enter.prevent="FOCO">
                                                <option value="" disabled>{{l('razao_isencao_iva')}}</option>
                                                <option v-show="dt.activo" v-for="(dt, i) in razoesIsencaoIva" :key="i" :value="dt.id">{{dt.motivo}}</option>
                                              </select>
                                            </div>
                                          </td>
                                          <td class="text-right maxwidth50" v-if="widthBody && form.tipo_documento_tp != 'GMD'">
                                            <div v-if="dt.preco_unitario > 0 && dt.quantidade > 0">
                                              <div v-if="dt.produto_composto">
                                                <span class="risco text-danger semquebras" v-if="dt.desconto_total>0">{{PrecoIVA ? dt.preco_unitario_iva : dt.preco_unitario | valor2}}<br /></span>
                                                <span class="semquebras">{{PrecoIVA ? dt.preco_unitario_iva - dt.desconto_total : dt.preco_unitario - dt.desconto_total | valor2}}</span>
                                              </div>
                                              <div v-else>
                                                <span class="risco text-danger semquebras" v-if="dt.desconto_1>0">{{PrecoIVA ? dt.preco_unitario_iva * dt.quantidade : dt.preco_unitario * dt.quantidade | valor2}}<br /></span>
                                                <span class="semquebras">{{PrecoIVA ? dt.preco_unitario * dt.quantidade - dt.desconto_total + dt.total_iva : dt.preco_unitario * dt.quantidade - dt.desconto_total | valor2}}</span>
                                              </div>
                                            </div>
                                            <div v-else>
                                              {{0 | valor2}}
                                            </div>
                                          </td>
                                          <td class="text-center" style="width:24px;" v-if="widthBody && form.terminado != 1 && form.tipo_documento_tp != 'GMD'">
                                            <button v-show="!dt.item_pai_id" type="button" :id="'del-' + index1 + '-' + index" @click="removeItem(dt, index)" class="btn btn-danger btn-xs"><i class="glyphicon glyphicon-trash"></i></button>
                                          </td>
                                          <td class="text-center" style="width:24px;" v-show="widthBody && form.terminado != 1 && form.produtos[1]">
                                            <button v-if="!dt.item_pai_id" class="btn btn-default btn-pure btn-xs"><i class="glyphicon glyphicon glyphicon-move"></i></button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </draggable-draggable>
                                  </div>
                                </div>
                                <div class="clearfix"></div>
                                <hr v-if="form.terminado != 1 && form.produtos.length > 1" class="visible-xs visible-sm" />
                                <div class="clearfix"></div>
                                <!--page2 !== 'compras' && page2 !== 'encomendas') &&-->
                                <pesq-artigos :processBarcode="processBarcode" :form="form" :selArtigos="[]" @selArtigo="selArtigo" @addArtigo="addArtigo" :PrecoIVA="PrecoIVA ? true : false" :stock="(form.tipo_documento_tp != 'SINIV' && form.tipo_documento_tp != 'ND' && form.tipo_documento_tp != 'NC')" :hideCriarArtigo="PERMISSOES.itens_gerir>0" :hideSearch="form.tipo_documento_tp != 'GMD'" :documentID="form.tipo_documento_tp" @submit="submit" :show="form.terminado != 1" :data1="new Date(data1).getFullYear() + '-' + ('0' + (new Date(data1).getMonth() + 1)).slice(-2) + '-' + ('0' + new Date(data1).getDate()).slice(-2)" :hidePreco="false" :showTime1="showTime1" :time1="time1 ? ('0' + new Date(time1).getHours()).slice(-2) + ':' + ('0' + new Date(time1).getMinutes()).slice(-2) + ':' + ('0' + new Date(time1).getSeconds()).slice(-2) : ''"></pesq-artigos>

                                <div class="clearfix"></div>
                                <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12 form-group form-material padding-0" v-if="page2!='orcamentos' && form.tipo_documento_tp != 'VORC' && form.tipo_documento_tp != 'ESTK' && form.tipo_documento_tp != 'SSTK'">
                                  <a href="javascript:void(0)" class="btn btn-primary pull-right margin-top-10 margin-right-10 margin-bottom-15" @click="SET_CONVERTER(form)" v-if="form.terminado!=1 && form.tipo_documento_tp != 'guiaglobal' && form.tipo_documento_tp != 'RC' && form.tipo_documento_tp != 'NL' && form.tipo_documento_tp != 'RFC' && (PERMISSOES.vendas_converter_documentos && page2 != 'compras' || PERMISSOES.compras_converter_documentos && page2 == 'compras') && form.tipo_documento_tp !='SINIV' && form.tipo_documento_tp != 'SINIC'" id="bot_converter"><i class="glyphicon glyphicon-zoom-in"></i> {{l('converter')}}</a>
                                  <div class="clearfix"></div>
                                </div>
                                <div class="clearfix"></div>

                                <p v-if="(form.produtos.length == 0 || form.tipo_documento_tp == 'ESTK' || form.tipo_documento_tp == 'SSTK') && form.terminado < 1" class="pull-right text-justify margin-10 margin-top-0"><span class="verm">*</span> {{$t('campos_obrigatorios')}}</p>
                                <div :class="{clearfix: 1, 'margin-bottom-10': (page2=='orcamentos' || form.tipo_documento_tp == 'VORC' || form.tipo_documento_tp == 'ESTK' || form.tipo_documento_tp == 'SSTK') && form.produtos.length}"></div>
                              </div>
                            </div>
                            <div class="clearfix"></div>

                            <div :class="{'col-md-12 padding-0 margin-bottom-0 margin-top-10': 1, 'padding-horizontal-10 panel padding-bottom-10': form.terminado != 1}" v-if="form.serie_id>0 && data1 && form.contato_id > 0 && form.tipo_documento_tp != 'RC' && form.tipo_documento_tp != 'NL' && form.tipo_documento_tp != 'RFC'  && (form.tipo_documento_tp != 'ND' && form.tipo_documento_tp != 'NC' && form.tipo_documento_tp != 'VND' && form.tipo_documento_tp != 'VNC' || (form.tipo_documento_tp == 'ND' || form.tipo_documento_tp == 'NC' || form.tipo_documento_tp == 'VND' || form.tipo_documento_tp == 'VNC') && form.motivo.length > 2) && form.tipo_documento_tp != 'DP' && form.produtos.length && form.tipo_documento_tp != 'GMD'">
                              <div class="panel-heading" v-if="form.terminado != 1">
                                <h3 class="panel-title padding-10">{{$t('total')}}</h3>
                              </div>
                              <div class="panel-body container-fluid padding-0">

                                <div :class="{'col-lg-6 col-md-6 col-sm-6 col-xs-12 padding-0': 1, 'padding-right-10': widthBody}">
                                  <div class="table-responsive">
                                    <table class="table width-full table-condensed table-striped margin-bottom-0">
                                      <thead>
                                        <tr class="height39">
                                          <th class="text-center">
                                            {{l('t_imposto')}}
                                          </th>
                                          <th class="text-center">
                                            {{l('t_incidencia')}}
                                          </th>
                                          <th class="text-center">
                                            {{l('t_valor')}}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr class="odd gradeX" v-for="(dt, i) in fimIvas" :key="i">
                                          <td class="text-right semquebras">{{dt.imposto}}%</td>
                                          <td class="text-right semquebras">{{MOEDA}} {{(dt.incidencia || 0) | valor2}}</td>
                                          <td class="text-right semquebras">{{MOEDA}} {{(dt.valor || 0) | valor2}}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 padding-0">
                                  <div class="table-responsive">
                                    <table class="table table-striped width-full table-condensed margin-bottom-0">
                                      <tbody>
                                        <tr class="height39">
                                          <td class="text-right text-uppercase"><b>{{l('Total_liquido')}}</b></td>
                                          <td class="text-right maxwidth50 semquebras">{{MOEDA}} {{total_liquido + (ao.desconto_total || 0) | valor2}}</td>
                                          <!--form.terminado ? form.net_total : -->
                                        </tr>

                                        <tr class="height39">
                                          <td class="text-right"><b>{{l('Total_desconto')}} <span v-if="PrecoIVA">({{l('t_preco_iva')}})</span><span v-else>({{l('t_preco')}})</span><!--<span v-if="!(form.terminado || form.tipo_documento_tp == 'ND' || form.tipo_documento_tp == 'NC' || form.tipo_documento_tp == 'VND' || form.tipo_documento_tp == 'VNC')" class="visible-xs"></span>--></b></td>
                                          <td class="text-right">
                                            <span v-if="form.terminado == 1 || form.tipo_documento_tp == 'ND' || form.tipo_documento_tp == 'NC' || form.tipo_documento_tp == 'VND' || form.tipo_documento_tp == 'VNC'">{{MOEDA}} <span v-if="PrecoIVA">{{ao.desconto_total_iva | valor2}}</span><span v-else>{{form.desconto_total || ao.desconto_total | valor2}}</span></span>
                                            <div class="form-group" v-else>
                                              <div class="col-md-6 col-lg-6 margin-0 padding-0 inputMobile">
                                                <div class="input-group pull-right margin-0 padding-0 marginmobile" v-if="PrecoIVA">
                                                  <input autocomplete="off" class="form-control foco text-center" type="text" v-model="ao.desconto_total_iva" @focus="descontTotalValor_ = ao.desconto_total_iva" @keyup="descontoTotal1()" @blur="descontoTotal1()" id="descontoTotal_" @keydown.enter.prevent="FOCO" :placeholder="0 | valor2" v-decimal>
                                                  <div class="input-group-addon">{{MOEDA}}</div>
                                                </div>
                                                <div class="input-group pull-right margin-0 padding-0 marginmobile" v-else>
                                                  <input autocomplete="off" class="form-control foco text-center" type="text" v-model="ao.desconto_total" @focus="descontTotalValor = ao.desconto_total" @keyup="descontoTotal()" @blur="descontoTotal()" id="descontoTotal" @keydown.enter.prevent="FOCO" :placeholder="0 | valor2" v-decimal>
                                                  <div class="input-group-addon">{{MOEDA}}</div>
                                                </div>
                                              </div>
                                              <div class="col-md-6 col-lg-6 margin-0 padding-0 inputMobile">
                                                <div class="input-group pull-right margin-0 padding-0">
                                                  <input autocomplete="off" class="form-control foco text-center" type="text" v-model="ao.desconto_percentual" @focus="descontTotalPercentual = ao.desconto_percentual" @keyup="descontoPercentual()" @blur="descontoPercentual()" id="descontoTotalP" @keydown.enter.prevent="FOCO" :placeholder="0 | valor2" v-percentagem>
                                                  <div class="input-group-addon">%</div>
                                                </div>
                                              </div>
                                              <div class="clearfix"></div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr class="height39">
                                          <td class="text-right text-uppercase"><b>{{l('Total_iva')}}</b></td>
                                          <td class="text-right semquebras">{{MOEDA}} {{form.terminado ? form.tax_total : total_iva | valor2}}</td>
                                        </tr>

                                        <tr v-if="RETENCAO && form.cliente_retencao" class="height39">
                                          <td class="text-right text-uppercase"><b>{{l('Total_retencao')}}</b></td>
                                          <td class="text-right semquebras">{{MOEDA}} {{form.terminado ? form.retencao_total : retencao_total | valor2}}</td>
                                        </tr>
                                        <tr class="height39" v-if="ao.arredondamento > 0 && form.terminado || form.terminado < 1 && ARREDONDAMENTOS">
                                          <td class="text-right text-uppercase"><b>{{l('Total_Arredondamentos')}}</b></td>
                                          <td class="text-right">
                                            <div class="input-group margin-left-20" v-if="form.terminado < 1">
                                              <div class="input-group-addon padding-0">
                                                <button type="button" style="border-radius: 3px 0px 0px 3px;" :class="{'btn padding-5': 1, 'btn-danger': !sinal,'btn-primary': sinal}" @click="sinal=!sinal;">{{sinal?'+':'-'}}0.0</button>
                                              </div>
                                              <input style="height:36px;" autocomplete="off" type="number" step="1" class="form-control foco text-center" v-model="ao.arredondamento" name="arredondamento" placeholder="0" min="0" max="3" maxlength="1" @blur="erroArrendomantos" id="arredondamento" ref="arredondamento" @keydown.enter.prevent="FOCO" :disabled="form.terminado > 0" @keyup="erroArrendomantos()" v-numerico3 />
                                              <div class="input-group-addon padding-top-0 padding-bottom-0">€</div>
                                            </div>
                                            <span v-else class="semquebras">{{sinal ? '' : '-'}}0,0{{ao.arredondamento}} {{MOEDA}}</span>
                                          </td>
                                        </tr>

                                        <tr class="height39" v-if="(form.desconto_financeiro > 0 && form.terminado || form.terminado < 1 && $store.state.utilizador.empresa.desconto_financeiro) && (form.tipo_documento_tp == 'FT' || form.tipo_documento_tp == 'FR' || form.tipo_documento_tp == 'FS')">
                                          <td class="text-right text-uppercase"><b>{{l('desconto_financeiro')}}</b></td>
                                          <td class="text-right">
                                            <div class="input-group margin-left-20" v-if="form.terminado < 1">
                                              <input autocomplete="off" type="text" class="form-control foco text-center" v-model="ao.desconto_financeiro" name="desconto_financeiro" placeholder="0" id="desconto_financeiro" ref="desconto_financeiro" @keydown.enter.prevent="FOCO" v-decimal />
                                              <div class="input-group-addon padding-top-0 padding-bottom-0">€</div>
                                            </div>
                                            <span v-else class="semquebras">{{MOEDA}} {{form.desconto_financeiro | valor2}}</span>
                                          </td>
                                        </tr>

                                        <tr class="height39">
                                          <td class="text-right text-uppercase semquebras"><b>{{l('Total_documento')}}</b></td>
                                          <td class="text-right semquebras" id="Total_documento" style="font-size:21px;"><a @click="contas()" v-if="form.terminado == 0" class="text-success margin-right-10 mao"><i class="glyphicon glyphicon-refresh"></i></a> {{MOEDA}} {{form.terminado ? form.gross_total : (total_liquido+total_iva-((ao.arredondamento/100 || 0)*((sinal)?-1:1))) > 0 ? total_liquido+total_iva-retencao_total-((ao.arredondamento/100 || 0)*((sinal)?-1:1)) - (ao.desconto_financeiro > 0 ? ao.desconto_financeiro : 0) : 0 | valor2}}
                                          </td>
                                        </tr>

                                    <!--<tr class="height39" v-if="RETENCAO > 0 && form.cliente_retencao && form.terminado != 1">
                                      <td class="text-right text-uppercase"><b>{{l('Total_divida')}}</b></td>
                                      <td class="text-right text-danger semquebras">{{MOEDA}} {{form.terminado ? form.gross_total : (total_liquido+total_iva-retencao_total-((ao.arredondamento/100 || 0)*((sinal)?-1:1))) > 0 ? total_liquido+total_iva-retencao_total-((ao.arredondamento/100 || 0)*((sinal)?-1:1)) : 0 | valor2}}</td>
                                    </tr>-->
                                    <tr class="height39" v-if="form.moeda != 'EUR_€'">
                                      <td class="text-right text-uppercase"><b>{{l('Total_documento')}}</b></td>
                                      <td class="text-right text-danger semquebras">{{form.moeda.split('_')[1]}} {{form.terminado ? form.gross_total * form.cambio : ((total_liquido+total_iva-retencao_total-((ao.arredondamento/100 || 0)*((sinal)?-1:1))) * form.cambio) > 0 ? ((total_liquido+total_iva-retencao_total-((ao.arredondamento/100 || 0)*((sinal)?-1:1))) - (ao.desconto_financeiro > 0 ? ao.desconto_financeiro : 0)) * form.cambio : 0 | valor2}}</td>
                                    </tr>
                                    <tr class="height39" v-if="form.divida && form.terminado && form.estado !== 'A'">
                                      <td class="text-right text-uppercase"><b>{{l('Total_divida')}}</b></td>
                                      <td class="text-right semquebras" style="font-size:21px;">
                                        <span class="text-danger" v-if="form.divida.total_divida - (ao.desconto_financeiro > 0 ? ao.desconto_financeiro : 0) > 0">{{MOEDA}} {{form.divida.total_divida - (ao.desconto_financeiro > 0 ? ao.desconto_financeiro : 0) | valor2}}</span>
                                        <span class="text-success" v-else>{{$t('pago')}}</span>
                                      </td>
                                    </tr>
                                    <template v-if="form.terminado">
                                      <tr v-for="(dt, index) in pagoPorFN(form.pago_por)" :key="index">
                                        <td class="text-right">
                                          <router-link :to="'/documentos/editar/faturas/' + dt.id" target="_blank"><b>{{dt.documento.invoice_number}}</b></router-link>
                                        </td>
                                        <td class="text-right">
                                          <router-link :to="'/documentos/editar/faturas/' + dt.id" target="_blank"><b>{{MOEDA}} {{dt.total | valor2}} <span v-if="dt.total_desconto > 0"><span class="verm"> - {{dt.total_desconto | valor2}}</span> = {{(dt.total - dt.total_desconto) | valor2}}</span></b></router-link>
                                        </td>
                                      </tr>
                                    </template>
                                    <tr class="height39" v-if="form.estado == 'A'">
                                        <td class="text-right text-uppercase"><b>{{l('Total_divida')}}</b></td>
                                        <td class="text-right semquebras" style="font-size:21px;">
                                          <span class="text-danger">{{$t('anulado')}}</span>
                                        </td>
                                    </tr>

                                    <tr class="height39" v-if="form.terminado && form.porto || contato.pais !== 'PT' && form.terminado < 1">
                                      <td class="text-right text-uppercase"><b>{{l('total_cif')}}</b></td>
                                      <td class="text-left">
                                        <span v-if="form.terminado">{{form.porto}}</span>
                                        <div class="margin-left-20" v-else>
                                          <input autocomplete="off" type="text" class="form-control foco text-center" v-model="form.porto" :placeholder="l('porto')">
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <div class="clearfix"></div>
                            <div class="form-group form-material margin-vertical-15 pull-left" v-if="!blocktransporte && form.terminado < 1 && form.produtos.length">
                              <div class="margin-left-20">
                                <input @change="ao.dados_transporte ? SET_SCROLLBOTTOM(true) : ''" id="dados_transporte" ref="dados_transporte" class="foco" type="checkbox" v-model="ao.dados_transporte" :disabled="form.terminado > 0" @keydown.enter.prevent="FOCO">
                                <label for="dados_transporte">{{l('dados_transporte')}}</label>
                                <div class="clearfix"></div>
                              </div>
                            </div>
                            <div class="clearfix"></div>
                            <p v-if="form.produtos.length && !ao.dados_transporte && form.terminado < 1" class="pull-right text-justify margin-0 margin-right-10 margin-vertical-10"><span class="verm">*</span> {{$t('campos_obrigatorios')}}</p>
                            <div class="clearfix"></div>
                          </div>
                        </div>
                        <div class="clearfix"></div>

                        <div class="alert alert-info col-md-6 col-lg-6 pull-right" v-if="(form.tipo_documento_tp === 'NC' || form.tipo_documento_tp === 'VNC') && form.terminado && !form.importado">
                          <h4 class="pull-left">{{l('serve_pagamento')}}</h4>
                          <div class="pull-left">
                            <input type="radio" @change="servePagamentoFN()" value="0" name="serve_pagamento" id="serve_pagamento_false" v-model="form.divida.serve_pagamento">
                            <label class="margin-left-35" for="serve_pagamento_false">{{$t('nao')}}</label>
                          </div>
                          <div class="pull-left">
                            <input type="radio" @change="servePagamentoFN()" value="1" name="serve_pagamento" id="serve_pagamento" v-model="form.divida.serve_pagamento">
                            <label class="margin-left-35" for="serve_pagamento">{{$t('sim')}}</label>
                          </div>
                          <div class="clearfix"></div>
                          <small class="text-justify">{{l('serve_pagamento_desc')}}</small>
                        </div>
                        <div class="alert alert-info col-md-6 col-lg-6 pull-right margin-top-10" v-if="form.importado">
                          <h4 class="pull-left">{{l('ignorar_nas_contas')}}</h4>
                          <div class="pull-left">
                            <input type="radio" @change="ignoraPagamentoFN()" value="0" name="serve_pagamento" id="serve_pagamento_false" v-model="form.divida.ignorado">
                            <label class="margin-left-35" for="serve_pagamento_false">{{$t('nao')}}</label>
                          </div>
                          <div class="pull-left">
                            <input type="radio" @change="ignoraPagamentoFN()" value="1" name="serve_pagamento" id="serve_pagamento" v-model="form.divida.ignorado">
                            <label class="margin-left-35" for="serve_pagamento">{{$t('sim')}}</label>
                          </div>
                          <div class="clearfix"></div>
                          <small class="text-justify">{{l('ignorar_nas_contas_desc')}}</small>
                        </div>

                        <div class="clearfix"></div>
                        <div :class="{'col-md-6 col-lg-6': form.tipo_documento_tp != 'guiaglobal', 'col-md-12 col-lg-12 col-sm-12': form.tipo_documento_tp == 'guiaglobal', 'padding-0 margin-0 margin-top-15': 1, 'padding-right-10': widthBody && form.tipo_documento_tp != 'guiaglobal'}" v-if="form.serie_id>0 && data1 && form.contato_id > 0 && form.tipo_documento_tp != 'RC' && form.tipo_documento_tp != 'NL' && form.tipo_documento_tp != 'RFC' && ao.dados_transporte && form.tipo_documento_tp != 'DP' && !form.importado || form.tipo_documento_tp == 'guiaglobal'">
                          <div class="panel margin-0 padding-bottom-15">
                            <div class="panel-heading">
                              <h3 class="panel-title padding-10" id="carga">{{l('dados_carga')}}</h3>
                            </div>
                            <div class="panel-body container-fluid padding-0">

                              <div :class="{'col-lg-6 col-md-6': form.tipo_documento_tp != 'guiaglobal', 'col-lg-4 col-md-6': form.tipo_documento_tp == 'guiaglobal', 'form-group form-material': 1}">
                                <div class="input-group">
                                  <span class="input-group-addon">{{$t('data')}}<span class="verm">*</span></span>
                                  <!--<input ref="datacarga" autocomplete="off" maxlength="10" pattern="[0-9]{2}\/[0-9]{2}\/[0-9]{4}$" min="2017-01-01" :max="ano + '-12-31'" type="date" class="form-control foco" v-model="ao.data_carga" :placeholder="$t('data_defeito')" :disabled="form.terminado > 0" id="datacarga" @keydown.enter.prevent="FOCO" required="required">-->
                                  <date-picker :clearable="false" ref="datacarga" :editable="false" type="date" format="YYYY-MM-DD" :lang="$store.state.langData" class="foco" v-model="ao.data_carga" :placeholder="$t('data_defeito')" :disabled="form.terminado > 0" id="datacarga" required></date-picker>
                                </div>
                              </div>
                              <div :class="{'col-lg-6 col-md-6': form.tipo_documento_tp != 'guiaglobal', 'col-lg-4 col-md-6': form.tipo_documento_tp == 'guiaglobal', 'form-group form-material': 1}">
                                <div class="input-group">
                                  <span class="input-group-addon">{{$t('hora')}}<span class="verm">*</span></span>
                                  <!--<input ref="horacarga" autocomplete="off" type="time" class="form-control foco" v-model="ao.hora_carga" :placeholder="$t('hora_defeito')" :disabled="form.terminado > 0" id="horacarga" @keydown.enter.prevent="FOCO" required="required">-->
                                  <date-picker :clearable="false" ref="horacarga" :editable="false" type="time" format="HH:mm:ss" :lang="$store.state.langData" class="foco" v-model="ao.hora_carga" :placeholder="$t('hora_defeito')" :disabled="form.terminado > 0" id="horacarga" required :open.sync="openTimeCarga" @change="handleChangeTime"></date-picker>
                                </div>
                              </div>

                              <div :class="{'col-lg-12 col-md-12': form.tipo_documento_tp != 'guiaglobal', 'col-lg-4 col-md-6': form.tipo_documento_tp == 'guiaglobal', 'form-group form-material': 1}">
                                <div class="input-group">
                                  <span class="input-group-addon">{{$t('morada')}}<span class="verm">*</span></span>
                                  <input ref="carga_morada" autocomplete="off" type="text" class="form-control foco" v-model="ao.carga_morada" :disabled="form.terminado > 0" id="carga_morada" @keydown.enter.prevent="FOCO" required="required" maxlength="100" v-texto />
                                </div>
                              </div>
                              <div :class="{'col-lg-12 col-md-12': form.tipo_documento_tp != 'guiaglobal', 'col-lg-4 col-md-6': form.tipo_documento_tp == 'guiaglobal', 'form-group form-material': 1}">
                                <div class="input-group">
                                  <span class="input-group-addon">{{$t('cidade')}}<span class="verm">*</span></span>
                                  <input ref="carga_cidade" autocomplete="off" type="text" class="form-control foco" v-model="ao.carga_cidade" :disabled="form.terminado > 0" id="carga_cidade" @keydown.enter.prevent="FOCO" required="required" maxlength="50" v-texto>
                                </div>
                              </div>
                              <div :class="{'col-lg-6 col-md-12': form.tipo_documento_tp != 'guiaglobal', 'col-lg-4 col-md-6': form.tipo_documento_tp == 'guiaglobal', 'form-group form-material': 1}">
                                <div class="input-group">
                                  <span class="input-group-addon">{{$t('codigo_postal')}}<span class="verm">*</span></span>
                                  <input ref="carga_codigo_postal" autocomplete="off" type="text" class="form-control foco" v-model="ao.carga_codigo_postal" :disabled="form.terminado > 0" id="carga_codigo_postal" @keydown.enter.prevent="FOCO"  required="required" :maxlength="ao.carga_pais === 'PT' ? 8 : 20" v-texto :placeholder="ao.carga_pais === 'PT' ? 'xxxx-xxx' : ''">
                                </div>
                              </div>
                              <div :class="{'col-lg-6 col-md-12': form.tipo_documento_tp != 'guiaglobal', 'col-lg-4 col-md-6': form.tipo_documento_tp == 'guiaglobal', 'form-group form-material': 1}">
                                <div class="input-group">
                                  <span class="input-group-addon">{{$t('pais')}}<span class="verm">*</span></span>
                                  <select class="form-control foco" v-model="ao.carga_pais" :disabled="form.terminado > 0" id="carga_pais" @keydown.enter.prevent="FOCO" required="required">
                                    <option v-for="(dt, i) in paises" :key="i" :value="dt.code">{{dt.name}}</option>
                                  </select>
                                </div>
                              </div>

                              <div :class="{'col-lg-6 col-md-12': form.tipo_documento_tp != 'guiaglobal', 'col-lg-4 col-md-6': form.tipo_documento_tp == 'guiaglobal', 'form-group form-material': 1}">
                                <div class="input-group">
                                  <span class="input-group-addon">{{l('metodo_expedicao')}}<span class="verm">*</span></span>
                                  <select class="form-control foco" v-model="form.metodo_expedicao_id" :disabled="form.terminado > 0" id="expedicao" @keydown.enter.prevent="FOCO" required="required">
                                    <option v-for="(dt, i) in expedicao" :key="i" :value="dt.id">{{dt.nome}}</option>
                                  </select>
                                </div>
                              </div>

                              <div :class="{'col-lg-6 col-md-12': form.tipo_documento_tp != 'guiaglobal', 'col-lg-4 col-md-6': form.tipo_documento_tp == 'guiaglobal', 'form-group form-material': 1}" v-if="form.terminado < 1 || form.terminado > 0 && ao.viaturaoutra">
                                <div class="input-group">
                                  <span class="input-group-addon">{{l('viatura')}}</span>
                                  <select ref="viatura" class="form-control foco" v-model="ao.viatura" :disabled="form.terminado > 0" id="viatura" @keydown.enter.prevent="FOCO" @change="delyFoco('viaturaoutra')" v-if="form.terminado < 1 && ao.viatura != -1 && !ao.viaturaoutra" >
                                    <option value=""></option>
                                    <option value="-1">{{l('definir_viatura')}}</option>
                                    <option v-for="(dt, i) in viaturas" :key="i" :value="dt.matricula">{{dt.nome}}</option>
                                  </select>
                                  <input ref="viaturaoutra" autocomplete="off" type="text" class="form-control foco" v-model="ao.viaturaoutra" :disabled="form.terminado > 0" v-else id="viaturaoutra" @keydown.enter.prevent="FOCO" v-texto />
                                  <span class="input-group-addon mao text-danger" v-if="form.terminado < 1 && (ao.viaturaoutra || ao.viatura == -1)"><i class="glyphicon glyphicon-trash" @click="selectViatura()"></i></span>
                                </div>
                              </div>
                              <div class="clearfix"></div>
                            </div>
                          </div>
                        </div>
                        <div :class="{'col-md-6 col-lg-6': form.tipo_documento_tp != 'guiaglobal', 'col-md-12 col-lg-12 col-sm-12': form.tipo_documento_tp == 'guiaglobal', 'padding-0 margin-0 margin-top-15': 1}" v-if="form.serie_id>0 && data1 && form.contato_id > 0 && form.tipo_documento_tp != 'RC' && form.tipo_documento_tp != 'NL' && form.tipo_documento_tp != 'RFC' && ao.dados_transporte && form.tipo_documento_tp != 'DP' && !form.importado && form.tipo_documento_tp != 'guiaglobal'">
                          <div class="panel margin-0 padding-bottom-15">
                            <div class="panel-heading">
                              <h3 class="panel-title padding-10" id="descarga">{{l('dados_descarga')}}</h3>
                            </div>
                            <div class="panel-body container-fluid padding-0">

                              <div class="col-lg-6 col-md-6 form-group form-material">
                                <div class="input-group">
                                  <span class="input-group-addon">{{$t('data')}}</span>
                                  <!--<input ref="datadescarga" autocomplete="off" maxlength="10" pattern="[0-9]{2}\/[0-9]{2}\/[0-9]{4}$" min="2017-01-01" :max="ano + '-12-31'" type="date" class="form-control foco" v-model="ao.data_descarga" :placeholder="$t('data_defeito')" :disabled="form.terminado > 0" id="datadescarga" @keydown.enter.prevent="FOCO" required="required">-->
                                  <date-picker :clearable="false" ref="datadescarga" :editable="false" type="date" format="YYYY-MM-DD" :lang="$store.state.langData" class="foco" v-model="ao.data_descarga" :placeholder="$t('data_defeito')" :disabled="form.terminado > 0" id="datadescarga" required></date-picker>
                                </div>
                              </div>
                              <div class="col-lg-6 col-md-6 form-group form-material">
                                <div class="input-group">
                                  <span class="input-group-addon">{{$t('hora')}}<span class="verm">*</span></span>
                                  <!--<input ref="horadescarga" autocomplete="off" type="time" class="form-control foco" v-model="ao.hora_descarga" :placeholder="$t('hora_defeito')" :disabled="form.terminado > 0" id="horadescarga" @keydown.enter.prevent="FOCO" required="required">-->
                                  <date-picker :clearable="false" ref="horadescarga" :editable="false" type="time" format="HH:mm:ss" :lang="$store.state.langData" class="foco" v-model="ao.hora_descarga" :placeholder="$t('hora_defeito')" :disabled="form.terminado > 0" id="horadescarga" required :open.sync="openTimeDescarga" @change="handleChangeTime"></date-picker>
                                </div>
                              </div>

                              <div class="col-lg-12 col-md-12 form-group form-material">
                                <div class="input-group">
                                  <span class="input-group-addon">{{$t('morada')}}<span class="verm">*</span></span>
                                  <input ref="descarga_morada" autocomplete="off" type="text" class="form-control foco" v-model="ao.descarga_morada" :disabled="form.terminado > 0" id="descarga_morada" @keydown.enter.prevent="FOCO" required="required" maxlength="100" v-texto />
                                </div>
                              </div>
                              <div class="col-lg-12 col-md-12 form-group form-material">
                                <div class="input-group">
                                  <span class="input-group-addon">{{$t('cidade')}}<span class="verm">*</span></span>
                                  <input ref="descarga_cidade" autocomplete="off" type="text" class="form-control foco" v-model="ao.descarga_cidade" :disabled="form.terminado > 0" id="descarga_cidade" @keydown.enter.prevent="FOCO" required="required" maxlength="50" v-texto>
                                </div>
                              </div>
                              <div class="col-lg-6 col-md-12 form-group form-material">
                                <div class="input-group">
                                  <span class="input-group-addon">{{$t('codigo_postal')}}<span class="verm">*</span></span>
                                  <input ref="descarga_codigo_postal" autocomplete="off" type="text" class="form-control foco" v-model="ao.descarga_codigo_postal" :disabled="form.terminado > 0" id="descarga_codigo_postal" @keydown.enter.prevent="FOCO" required="required" :maxlength="ao.descarga_pais === 'PT' ? 8 : 15" v-texto :placeholder="ao.descarga_pais === 'PT' ? 'xxxx-xxx' : ''" />
                                </div>
                              </div>
                              <div class="col-lg-6 col-md-12 form-group form-material">
                                <div class="input-group">
                                  <span class="input-group-addon">{{$t('pais')}}<span class="verm">*</span></span>
                                  <select class="form-control foco" v-model="ao.descarga_pais" :disabled="form.terminado > 0" id="descarga_pais" @keydown.enter.prevent="FOCO" required="required">
                                    <option v-for="(dt, i) in paises" :key="i" :value="dt.code">{{dt.name}}</option>
                                  </select>
                                </div>
                              </div>
                              <div class="clearfix"></div>
                              <div style="height:39px;">
                                <p :style="form.produtos.length && ao.dados_transporte && form.terminado < 1 ? '' : 'visibility: hidden'" class="pull-right text-justify margin-0 margin-right-10 margin-top-15"><span class="verm">*</span> {{$t('campos_obrigatorios')}}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="panel margin-vertical-15" v-if="(metas[0] && form.serie_id > 0 && data1 && (form.contato_id > 0 || form.tipo_documento_tp == 'guiaglobal') && form.tipo_documento_tp != 'SINIV' && form.tipo_documento_tp != 'ND' && form.tipo_documento_tp != 'NC' && form.tipo_documento_tp != 'RC' && form.tipo_documento_tp != 'NL' && form.tipo_documento_tp != 'DP' && form.tipo_documento_tp != 'VND' && form.tipo_documento_tp != 'VNC' && form.tipo_documento_tp != 'SINIC' && form.tipo_documento_tp != 'RFC') && (existeMeta && form.terminado || form.terminado != 1)">
                          <div class="panel-heading">
                            <h3 class="panel-title padding-10">{{l('informacao_meta')}}</h3>
                          </div>
                          <div class="panel-body container-fluid padding-0 padding-bottom-15">
                            <div :class="{'form-group form-material col-xs-12': 1, 'col-md-4 col-sm-6 col-lg-4': dt.meta_value !== 'textarea' && dt.meta_value !== 'radio', 'col-md-12 col-sm-12 col-lg-12': dt.meta_value === 'textarea' || dt.meta_value === 'radio', h34: dt.meta_value === 'checkbox'}" v-for="(dt,index) in metas" :key="index" v-show="dt.option.valor && form.terminado || form.terminado != 1">
                              <div class="input-group">
                                <span class="input-group-addon" v-if="dt.meta_value !== 'image' && dt.meta_value !== 'checkbox' && dt.meta_value !== 'radio'">
                                  {{dt.meta_key}}
                                </span>
                                <textarea v-if="dt.meta_value === 'textarea'" style="height:100px" autocomplete="off" class="form-control foco" v-model="dt.option.valor" @keydown.enter.prevent="FOCO" v-texto :disabled="form.terminado > 0"></textarea>

                                <input v-if="dt.meta_value === 'text' || dt.meta_value === 'hidden'" autocomplete="off" type="text" class="form-control foco" v-model="dt.option.valor" @keydown.enter.prevent="FOCO" v-texto :disabled="form.terminado > 0">

                                <input v-if="dt.meta_value === 'password'" autocomplete="off" type="password" class="form-control foco" v-model="dt.option.valor" @keydown.enter.prevent="FOCO" v-texto :disabled="form.terminado > 0">

                                <select v-if="dt.meta_value === 'select'" class="form-control foco" v-model="dt.option.valor" @keydown.enter.prevent="FOCO" :disabled="form.terminado > 0">
                                  <option value=""></option>
                                  <option v-for="(dtt,index1) in dt.option.valores" :key="index1" :value="dtt">{{dtt}}</option>
                                </select>

                                <input v-if="dt.meta_value === 'checkbox'" class="foco" type="checkbox" :id="'fdsDSd' + index" v-model="dt.option.valor" @keydown.enter.prevent="FOCO" :disabled="form.terminado > 0">
                                <label v-if="dt.meta_value === 'checkbox'" :for="'fdsDSd' + index">{{dt.meta_key}}</label>

                                <span v-if="dt.meta_value === 'radio'" class="input-group-addon" style="width: auto;">{{dt.meta_key}}</span>
                                <span v-show="dt.meta_value === 'radio'" v-for="(dtt,index1) in dt.option.valores" :key="index1">
                                  <input class="foco" type="radio" :name="'fds232dsa' + index" :id="'fds232dsa' + index + '-' + index1" @keydown.enter.prevent="FOCO" v-model="dt.option.valor" :value="dtt" :disabled="form.terminado > 0">
                                  <label :for="'fds232dsa' + index + '-' + index1">{{dtt}}</label>
                                </span>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
              <div class="form-group form-material margin-top-15" v-if="form.serie_id > 0 && data1 && (form.contato_id > 0 || form.tipo_documento_tp == 'guiaglobal' ||  form.tipo_documento_tp == 'ESTK' ||  form.tipo_documento_tp == 'SSTK') && form.terminado < 1 && ((form.tipo_documento_tp == 'ND' || form.tipo_documento_tp == 'NC' || form.tipo_documento_tp == 'VND' || form.tipo_documento_tp == 'VNC') && form.motivo.length > 2 && form.produtos[0] || form.gross_total > 0 && (form.tipo_documento_tp == 'SINIV' || form.tipo_documento_tp == 'SINIC') || form.produtos[0] && form.tipo_documento_tp != 'ND' && form.tipo_documento_tp != 'NC' && form.tipo_documento_tp != 'VND' && form.tipo_documento_tp != 'VNC' && form.tipo_documento_tp != 'RC' && form.tipo_documento_tp != 'NL' && form.tipo_documento_tp != 'RFC' || form.tipo_documento_tp == 'DP' || ((form.tipo_documento_tp == 'RC' || form.tipo_documento_tp == 'NL') && PERMISSOES.recibos_gerir > 0 || form.tipo_documento_tp == 'RFC' && PERMISSOES.pagamentos_gerir > 0) && ao.docsrecibo.length ) && (PERMISSOES.compras_gerir > 0 && page2 == 'compras' || PERMISSOES.vendas_gerir > 0 && page2 != 'compras')">
                <div v-if="agendar">
                  <div v-if="PLANO120">
                    <input autocomplete="off" type="button" :class="{'btn btn-primary pull-right foco': 1, 'btn-block': !widthBody}" @click="submit(1)" :value="$t('guardar')" id="finalizar" :disabled="isButtonDisabled" />
                  </div>
                  <div v-else class="col-lg-12 alert alert-warning text-justify">
                    <svg width="24" aria-hidden="true" data-prefix="far" data-icon="sad-tear" class="margin-right-10 svg-inline--fa fa-sad-tear fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm8-152c-13.2 0-24 10.8-24 24s10.8 24 24 24c23.8 0 46.3 10.5 61.6 28.8 8.1 9.8 23.2 11.9 33.8 3.1 10.2-8.5 11.6-23.6 3.1-33.8C330 320.8 294.1 304 256 304zm-88-64c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160-64c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm-165.6 98.8C151 290.1 126 325.4 126 342.9c0 22.7 18.8 41.1 42 41.1s42-18.4 42-41.1c0-17.5-25-52.8-36.4-68.1-2.8-3.7-8.4-3.7-11.2 0z"></path></svg>{{$t('sem_suporte_agendamentos')}}
                    <br /><router-link class="pull-right btn btn-primary" to="/planos" v-if="$store.state.utilizador.empresa.afiliado !== 'soos'">{{l('planos')}}</router-link>
                    <div class="clearfix"></div>
                  </div>
                </div>
                <div v-else>
                  <input autocomplete="off" type="button" class="btn btn-info pull-left btn-block-mobile" @click="submit(0)" :disabled="isButtonDisabled" v-if="form.tipo_documento_tp == 'FT' || form.tipo_documento_tp == 'FR' || form.tipo_documento_tp == 'FS' || form.tipo_documento_tp == 'ORC' || form.tipo_documento_tp == 'FPF' || form.tipo_documento_tp == 'FO' || form.tipo_documento_tp == 'GT' || form.tipo_documento_tp == 'GR' || form.tipo_documento_tp == 'GC' || form.tipo_documento_tp == 'GA' || form.tipo_documento_tp == 'GD' || form.tipo_documento_tp == 'GMD' || form.tipo_documento_tp == 'guiaglobal' || form.tipo_documento_tp == 'NENC' || form.tipo_documento_tp == 'VFT' || form.tipo_documento_tp == 'VFR' || form.tipo_documento_tp == 'VFS' || form.tipo_documento_tp == 'VGR' || form.tipo_documento_tp == 'VGT' || form.tipo_documento_tp == 'VNENC' || form.tipo_documento_tp == 'VORC' || form.tipo_documento_tp == 'VFPF' || form.tipo_documento_tp == 'PEDG'" :value="l('botao_rascunho')" id="rascunho" />
                  <input autocomplete="off" type="button" class="btn btn-primary pull-right foco btn-block-mobile" @click="submit(1)" :value="l('botao')" id="finalizar" :disabled="isButtonDisabled" />
                </div>
                <div class="clearfix"></div>
              </div>

              <div class="celarfix"></div>
              <!--DISCUSSAO && form.terminado-->
              <div class="margin-top-15" v-if="(NOTAS || PROJETOS && form.terminado) && form.serie_id>0 && data1 && form.contato_id && ((form.tipo_documento_tp == 'NC' || form.tipo_documento_tp == 'ND' || form.tipo_documento_tp == 'VNC' || form.tipo_documento_tp == 'VNC') && form.motivo.length > 2 || form.tipo_documento_tp != 'NC' && form.tipo_documento_tp != 'ND' && form.tipo_documento_tp != 'VNC' && form.tipo_documento_tp != 'VNC') && !form.importado">
                <ul class="nav nav-tabs">
                  <li v-if="NOTAS" :class="{'active': tab === 'notas', 'margin-right-10': 1}"><a @click="tab='notas'" class="text-uppercase mao">{{l('notas_internas')}}</a></li>
                  <!--<li v-if="DISCUSSAO" :class="{'active': !tab, 'margin-right-10': 1}"><a class="text-uppercase mao" @click="tab=''">{{l('discussao')}}</a></li>-->
                  <li v-if="PROJETOS" :class="{'active': tab === 'projetos'}"><a class="text-uppercase mao" @click="tab='projetos'">{{l('projetos')}}</a></li>
                </ul>

                <div class="tab-content" v-show="tab === 'projetos'">
                  <div class="panel margin-0 padding-bottom-15">
                    <div class="panel-body container-fluid padding-0">
                      <div class="col-md-12 col-sm-12 form-group padding-top-15" v-if="form.terminado">
                        <div v-if="projetos[0] || pesqProjeto">
                          <div v-if="SelProjetos[0]">
                            <button class="listaDelete btn btn-primary btn-xs" v-for="(dt, index) in SelProjetos" :key="index" @click="delProjetos(dt, index)"><i class="glyphicon glyphicon-trash margin-right-10"></i> <b>{{dt.codigo}}</b> {{dt.titulo}}</button>
                            <hr />
                          </div>
                          <input type="text" class="form-control" v-texto :placeholder="l('pesquisar_projeto')" v-model="pesqProjeto" @keyup="pesqProjetoFN()" @blur="pesqProjetoFN()" />
                          <div v-if="projetos[0]">
                            <div v-for="(dt, index) in projetos" :key="index">
                              <input @change="upProjetos(dt)" class="foco" type="checkbox" v-model="dt.sel" :id="'proj' + index" @keydown.enter.prevent="FOCO">
                              <label :for="'proj' + index"><b>{{dt.codigo}}</b> {{dt.titulo}}</label>
                              <div class="clearfix"></div>
                            </div>
                            <div class="clearfix"></div>
                            <paginacao1-paginacao1 :pag="pag" :lastpage="lastpage" @formUrl="projetosFN"></paginacao1-paginacao1>
                          </div>
                          <div v-else class="alert alert-danger alert-dismissible text-justify margin-vertical-50" >
                            {{l('sem_projetos_pesquisa')}}
                          </div>
                        </div>
                        <div v-else class="alert alert-danger alert-dismissible text-justify margin-vertical-50" >
                          {{l('sem_projetos_associados_ao_contato')}}
                        </div>
                      </div>
                      <div v-else class="margin-horizontal-15 alert alert-danger alert-dismissible text-justify margin-vertical-50" >
                        {{l('add_projetos_sem_finalizar')}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="tab-content" v-show="tab === 'notas'">
                  <div class="panel margin-0 padding-bottom-15">
                    <div class="panel-body container-fluid padding-0">
                      <div class="col-md-12 col-sm-12 form-group padding-top-15">
                        <div class="input-group">
                          <span class="input-group-addon">{{l('notas_documento')}}</span>
                          <textarea style="height:100px" autocomplete="off" id="nota_documento" class="form-control foco" :placeholder="l('ajuda_notas')" v-model="form.nota_documento" maxlength="200" row="5" v-texto @keydown.enter.prevent="FOCO"></textarea>
                        </div>
                      </div>
                      <div class="clearfix"></div>
                      <div class="form-group form-material text-right" v-if="form.terminado && (!tab && form.discussao || tab === 'notas')">
                        <input autocomplete="off" type="button" class="btn btn-primary margin-top-5 margin-right-15 foco" :value="$t('atualizar')" id="atualizar" @click="upNota()" />
                      </div>
                    </div>
                  </div>
                </div>

                <div v-show="!tab" @click="discussoDropDown = false">
                  <label v-show="!form.discussao || form.terminado < 1" class="switch pull-right margin-15 margin-bottom-0" v-tooltip.top-start="form.discussao ? $t('ativo') : $t('desativo')"><input type="checkbox" name="discussaoo" v-model="form.discussao" @change="upEstadoDiscussao()"><i></i></label>

                  <div v-if="form.discussao && form.terminado" :class="{'dropdown pull-right': 1, 'open': discussoDropDown}">
                    <button @click.prevent.stop="discussoDropDown = !discussoDropDown" type="button" class="btn btn-warning btn-pure glyphicon glyphicon-cog"></button>
                    <ul class="dropdown-menu">
                      <li><a class="mao" @click="form.discussao = 0; upEstadoDiscussao()">{{$t('desativar')}}</a></li>
                      <li><a class="mao" @click="discussaoSenhaFN()">{{l('password_discussao')}}</a></li>
                      <li><a class="mao" @click="gerarNovoCodigoFN()">{{$t('discussao.codigo_discussao')}}</a></li>
                      <li><a class="mao" @click="copiar()" >{{$t('discussao.copiar_codigo_discussao')}}</a></li>
                    </ul>
                  </div>
                  <div class="clearfix"></div>
                  <div v-if="DISCUSSAO && form.terminado && form.contato">
                    <div v-if="PLANO120">
                      <comentarios :documento="form" @upNota="upNota"></comentarios>
                      <div @click="$router.push('/contactos/editar/' + form.contato.contato_id, () => {})" class="col-lg-12 alert alert-danger mao" v-if="!form.contato.email && form.discussao" v-html="$t('discussao.nao_tem_email').replace('@name', form.contato.nome)"></div>
                    </div>
                    <div v-else class="col-lg-12 alert alert-warning text-justify">
                      <svg width="24" aria-hidden="true" data-prefix="far" data-icon="sad-tear" class="margin-right-10 svg-inline--fa fa-sad-tear fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm8-152c-13.2 0-24 10.8-24 24s10.8 24 24 24c23.8 0 46.3 10.5 61.6 28.8 8.1 9.8 23.2 11.9 33.8 3.1 10.2-8.5 11.6-23.6 3.1-33.8C330 320.8 294.1 304 256 304zm-88-64c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160-64c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm-165.6 98.8C151 290.1 126 325.4 126 342.9c0 22.7 18.8 41.1 42 41.1s42-18.4 42-41.1c0-17.5-25-52.8-36.4-68.1-2.8-3.7-8.4-3.7-11.2 0z"></path></svg>{{$t('sem_suporte_discussao')}}
                      <br /><router-link class="pull-right btn btn-primary" to="/planos">{{l('planos')}}</router-link>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
          <router-view></router-view>
        </div>
        <msg-permissoes :ok="!(PERMISSOES.itens_ver_preco_custo && PERMISSOES.compras && page2 === 'compras' && (PERMISSOES.pagamentos_gerir && form.tipo_documento_tp == 'RFC' || form.tipo_documento_tp != 'RFC') || PERMISSOES.vendas && page2 !== 'compras' && (PERMISSOES.recibos_gerir && (form.tipo_documento_tp == 'RC' || form.tipo_documento_tp == 'NL') || (form.tipo_documento_tp != 'RC'  && form.tipo_documento_tp != 'NL')))"></msg-permissoes>
      </div>
    </template>

    <script>
    import { PAISES } from '../../../filters/paises'
    import paisMoeda from '../pais-moedas.vue'
    import pesqContactos from '../pesq-contactos.vue'
    import pesqArtigos from '..//pesq-artigos.vue'
    import comentarios from '../../discussao/comentarios.vue'
// getData
    import { Principal, somarDias, gethora, urlhashDocs, round10, v } from '../../../filters/index'
    import { agendamentoCriar, documentosCriar, documentosUp, documentosId, contatos, pagamentosAberto, pagamentosCriar, movimentosPendentesMultiplos, ATAutomatic, saldoinicial, documentosNota, enviarmail, pagamentoDevolucao, cambios, discussaoUp, contatosId, metaAdMultiplos, projetos, projetosAddDoc, projetosDelDoc, propriaEmpresa, servePagamento, itensId, ignoraPagamento, estatisticasContato } from '../../../resources/fn'
    import { mapState, mapMutations } from 'vuex'

    export default {
      name: 'tplDocumentosCriar',
      components: { paisMoeda, pesqContactos, pesqArtigos, comentarios },
      data () {
        return {
          tab: '',
          discussoDropDown: false,
          form: {
            produtos: [],
            documentos: [],
            cae: '',
            observacoes: '',
            meta: {},
            divida: {}
          },
          ao: {
            prazo_vencimento: 0,
            prazo_vencimento1: '',
            arredondamento: 0,
            total_recibo: '',
            data_carga: '',
            hora_carga: '',
            carga_morada: '',
            carga_codigo_postal: '',
            carga_pais: '',
            data_descarga: '',
            hora_descarga: '',
            descarga_morada: '',
            descarga_cidade: '',
            descarga_pais: '',
            viatura: '',
            viaturaoutra: '',
            desconto_total: '',
            desconto_total_iva: '',
            desconto_percentual: '',
            dados_transporte: false,
            frequencia: 'meses',
            a_cada: 1,
            data_inicio: new Date(),
            data_fim: new Date(),
            rascunho: '',
            enviar_email: '',
            email_destino: '',
            template_id: '',
            mensagem_email: '',
            total_desconto: '',
            docsrecibo: [],
            lojaGuia: 'contacto',
            desconto_financeiro: 0
          },
          data1: '',
          time1: '',
          showTime1: false,
          razoesIsencaoIva: '',
          fimIvas: [],
          paises: PAISES(),
          sinal: false,
          total_liquido: 0,
          total_iva: 0,
          retencao_total: 0,
          criarFormExterno: false,
          totalQP: 0,
          series: {},
          impostos: {},
          viaturas: {},
          pagamento: {},
          expedicao: {},
          documentos: {},
          unidades: {},
          lojas: {},
          clientefornecedor: '',
          credito: 0,
          blocktransporte: false,
          artigos: [],
          artigo: '',
          montou: false,
          documents: {},
          clonar: false,
          clonar1: false,
          resultado: false,
          ESTATISTICAS: {},
          contato: '',
          contacto: '',
          moradas: [],
          caes: [],
          tooltipPrazoVencimento: '',
          indexPesq: 0,
          item: '',
      // ano: new Date().getFullYear() + 1,
      // ativar modal dos preços nas guias globais, e compras so depois de ter o cliente seleccionado
          AtivarModalCompras: false,
          AtivarModalComprasData: [],
      selectClient: false, // ao converte rum documento de uma guia global ja fez a pergunta que cliente pretende
      descontTotalValor: 0, // para verificar se o desconto total foi alterado com sucesso
      descontTotalValor_: 0, // para verificar se o desconto total foi alterado com sucesso c iva
      descontTotalPercentual: 0,
      widthBody: false,
      descontoTotal: '',
      descontoPercentual: '',
      agendamento: [],
      PrecoIVA: 0,
      agendar: false, // se ta no agendar documentos
      metas: [],
      projetos: [],
      pesqProjeto: '',
      SelProjetos: [],
      buscouProjectos: false,
      lastpage: 1,
      pag: 1,
      composto: false,
      produtosLinha: [],
      lojaGuia1: '',
      existeMeta: false, // tem meta dados preenchicos se o documento for o final
      modalUpdatePrecosCompra: false, // apresenta o modal de atualziar os preços de compra
      modalUpdatePrecosCompraAr: [], // array com os preços que vai atualziar
      modalUpdatePrecosCompraIva: false, // se tem o iva incluido ou nao o preço
      modalUpdatePrecosCompraConfirm: 0, // ja abriu o modal e o gajo confirmou
      modalUpdatePrecosCompraCheck: true, // checkbox os produtos que vai atualizar
      openTime: false, // quando escolher os minutos dechar as horas
      openTimeCarga: false, // quando escolher os minutos dechar as horas carga
      openTimeDescarga: false, // quando escolher os minutos dechar as horas descarga
      // data1Time1: '', // data e hora toda junta com o data1 + time1
      euroPerc: 1, // se 1 % se 0 euros
      modalProdutoVariado: false, // modal do produto variado
      modalProdutoVariadoIva: false, // se aparece os preços com iva ou sem iva do produto variado
      modalProdutoVariadoNome: '', // nome do produto varaivel
      modalProdutoVariadoKeys: [], // colspan da composição do prod variavel
      modalProdutoVariadoAr: [], // ar da composição do prod variavel
      modalProdutoVariadoDT: [], // dt do produto com a composicao
      modalProdutoVariadoNames: [], // nome das varaicoes da composiçao
      idCheque: false, // se for cheque no recibo aparece o campo do chque
      allRecibos: false, // selecciona os recibos todos

      isButtonDisabled: false, // impedir duplo click no botao


      barcodeMessage: '',
      barcodeBuffer: '',
      lastKeyPressTime: 0,
      barcodeTimeout: null,
      processBarcode: ''
    }
  },
  props: {
    form1: { type: Object }
  },
  computed: {
    ...mapState({
      page: 'page',
      page1: 'page1',
      page2: 'page2',
      page3: 'page3',
      page4: 'page4'
    }),
    PERMISSOES: function () {
      return this.$store.state.utilizador.permissoes
    },
    MOEDA: function () {
      return this.$store.getters.MOEDA
    },
    ISENTO: function () {
      return this.$store.getters.ISENTO
    },
    casasDecimais: function () {
      return this.$store.getters.CASASDECIMAIS * -1
    },
    RAZOESISENCAO: function () {
      return this.$store.getters.RAZOESISENCAO
    },
    RETENCAO: function () {
      return this.page2 === 'compras' ? true : this.$store.getters.RETENCAO
    },
    ARREDONDAMENTOS: function () {
      return this.$store.getters.ARREDONDAMENTOS
    },
    REFERENCIAMANUAL: function () {
      return this.$store.getters.REFERENCIAMANUAL
    },
    NOTAS: function () {
      return this.$store.getters.NOTAS
    },
    NUMEROSERIE: function () {
      return this.$store.state.utilizador.empresa.numeros_de_serie
    },
    DISCUSSAO: function () {
      return this.$store.state.utilizador.empresa.discussao_em_documentos && (this.form.tipo_documento_tp === 'FT' || this.form.tipo_documento_tp === 'FS' || this.form.tipo_documento_tp === 'FR' || this.form.tipo_documento_tp === 'GT' || this.form.tipo_documento_tp === 'GR' || this.form.tipo_documento_tp === 'NENC' || this.form.tipo_documento_tp === 'ORC' || this.form.tipo_documento_tp === 'FPF' || this.form.tipo_documento_tp === 'VFT' || this.form.tipo_documento_tp === 'VFR' || this.form.tipo_documento_tp === 'VFS' || this.form.tipo_documento_tp === 'VGR' || this.form.tipo_documento_tp === 'VGT' || this.form.tipo_documento_tp === 'VNENC' || this.form.tipo_documento_tp === 'VORC' || this.form.tipo_documento_tp === 'VFPF')
    },
    PLANO120: function () {
      return this.$store.state.utilizador.plano.plano.valor_mensal > 10 || this.$store.state.utilizador.plano.plano.valor_anual > 100
    },
    PROJETOS: function () {
      return this.$store.state.utilizador.empresa ? this.$store.state.utilizador.empresa.modulo_projetos : false
    }
    /*,
    produtosLinha: function () {
      console.log('-..a.--.sa.-as-.aquii')
      let u = this.form.produtos.length
      let i = 0
      let ar = []
      while (i < u) {
        if (this.form.produtos[i].item_pai_id) {
          ar[ar.length - 1].push(this.form.produtos[i])
        } else {
          ar.push([this.form.produtos[i]])
        }
        ++i
      }
      return ar
    }
    */
  },
  watch: {
    form: {
      handler: function () {
        this.$emit('update:form1', this.form)
        let u = this.form.produtos.length
        let i = 0
        let ar = []
        while (i < u) {
          if (this.form.produtos[i].item_pai_id) {
            ar[ar.length - 1].push(this.form.produtos[i])
          } else {
            ar.push([this.form.produtos[i]])
          }
          ++i
        }
        this.produtosLinha = ar
        if (this.form.terminado < 1 && !this.page3) {
          window.localStorage.setItem('documento', JSON.stringify({ form: this.form, ao: this.ao, contacto: this.contacto, contato: this.contato, data1: this.data1, moradas: this.moradas, agendar: this.agendar, user: this.PERMISSOES.user_id, metas: this.metas }))
        }
      },
      deep: true
    },
    ao: {
      handler: function () {
        if (this.form.terminado < 1 && !this.page3) {
          window.localStorage.setItem('documento', JSON.stringify({ form: this.form, ao: this.ao, contacto: this.contacto, contato: this.contato, data1: this.data1, moradas: this.moradas, agendar: this.agendar, user: this.PERMISSOES.user_id, metas: this.metas }))
        }
      },
      deep: true
    },
    '$route' (b, c) {
      if (this.$route.query.a) {
        this.agendar = true
      } else {
        this.agendar = false
      }
      this.tab = ''
      if (this.criarFormExterno && this.$store.state.help.tipo === 'artigo') {
        return
      }
      let a = window.location.pathname.split('/')
      if (a[1] === 'quebrastock') {
        this.route(b, c)
        return
      }
      // limpar localstorage
      if (b.query.l > 0 || (b.query.i === c.query.i && b.query.x)) {
        window.localStorage.removeItem('documento')
        this.information()
      }
      if (b.query.i) {
        this.form.tipo_documento_tp = b.query.i
        // this.form.tipo_documento_id = this.$store.state.idDocumento[this.form.tipo_documento_tp]
      }
      if (!this.montou && this.$store.state.documents_bts.tipo !== 'clonarListagem') {
        if (!this.clonar || b.fullPath !== c.fullPath) {
          this.clonar = false
          if (a[1] === 'documentos' && (a[2] === 'editar' || a[2] === 'criar') && this.$store.state.help.valor !== 'final') {
            this.route(b, c)
          } else {
            this.information()
          }
        }
      } else {
        this.SET_LOAD()
        this.montou = false
      }
    },
    '$store.state.converter_result1' (val) {
      if (val.length) {
        this.documents = val
        let i = this.documents.length
        let docs = []
        if (i > 0) {
          let ar = []
          while (--i >= 0) {
            if (this.documents[i].sel) {
              ar.push(this.documents[i].origem)
              if (this.form.tipo_documento_tp === 'NC' || this.form.tipo_documento_tp === 'VNC') {
                if (parseFloat(this.documents[i].origem_documento.divida.total_divida) < parseFloat(this.documents[i].origem_documento.gross_total)) {
                  docs.push(this.documents[i].origem_documento.invoice_number)
                }
              }
            }
          }
          this.euroPerc = false
          if (this.form.tipo_documento_tp === 'NC' || this.form.tipo_documento_tp === 'VNC') {
            let self = this
            this.SET_LOAD()
            this.$swal({
              title: self.l('documentos.NC'),
              text: self.l('confirmar_converter_desc_varios').replace('#DOCUMENTOS#', docs.join(', ')),
              showCancelButton: true,
              confirmButtonText: self.$t('sim'),
              cancelButtonText: self.$t('nao'),
              allowOutsideClick: false,
              type: 'question'
            }).then(function (result) {
              if (result.value) {
                self.form.divida.serve_pagamento = 1
              } else {
                self.form.divida.serve_pagamento = 0
              }
              self.converterDocsInteiros(ar)
            })
          } else {
            this.converterDocsInteiros(ar)
          }
        }
        this.SET_CONVERTER_RESULT1({})
      }
    },
    '$store.state.documents_bts' (val) {
      switch (val.tipo) {
      case 'finalizar':
      case 'rascunho':
        document.getElementById(val.tipo).click()
        break
      case 'clonar':
        this.euroPerc = 1
        this.clonar = true
        this.clonar1 = true
        val.terminado = 0
        val.data.codigo_discussao = ''
        val.data.discussao = this.$store.state.utilizador.empresa.discussao_em_documentos && (val.data.tipo_documento_tp === 'FT' || val.data.tipo_documento_tp === 'FS' || val.data.tipo_documento_tp === 'FR' || val.data.tipo_documento_tp === 'GT' || val.data.tipo_documento_tp === 'GR' || val.data.tipo_documento_tp === 'NENC' || val.data.tipo_documento_tp === 'ORC' || val.data.tipo_documento_tp === 'FPF' || val.data.tipo_documento_tp === 'VFT' || val.data.tipo_documento_tp === 'VFR' || val.data.tipo_documento_tp === 'VFS' || val.data.tipo_documento_tp === 'VGR' || val.data.tipo_documento_tp === 'VGT' || val.data.tipo_documento_tp === 'VNENC' || val.data.tipo_documento_tp === 'VORC' || val.data.tipo_documento_tp === 'VFPF')
        val.data.password_discussao = ''
        this.formFN(val, 'clonar')
        this.SET_DOCUMENTO_BTS({})
        break
        // converter documento
      case 'doc':
        this.clonar = true
        this.euroPerc = 1
        this.formFNInit()
        if (!val.data.contato.lingua_padrao_documentos) {
          val.data.contato.lingua_padrao_documentos = val.data.lingua
        }
        this.selContato(val.data.contato.origem)
        if (this.form.tipo_documento_tp === 'NC' || this.form.tipo_documento_tp === 'VNC') {
          let self = this
          this.$swal({
            title: self.l('documentos.NC'),
            text: self.l('confirmar_converter_desc').replace('#DOCUMENTO#', val.data.invoice_number),
            showCancelButton: true,
            confirmButtonText: self.$t('sim'),
            cancelButtonText: self.$t('nao'),
            allowOutsideClick: false,
            type: 'question'
          }).then(function (result) {
            if (result.value) {
              self.form.divida.serve_pagamento = 1
            } else {
              self.form.divida.serve_pagamento = 0
            }
            self.converterDocsInteiros([val.data.id], val.data.invoice_number)
          })
        } else {
          this.converterDocsInteiros([val.data.id], val.data.invoice_number)
        }
        this.form.serie_id = val.data.serie_id
        this.SET_DOCUMENTO_BTS({})
        this.form.meta = val.data.meta
        this.meta()
        break
      case 'recibo':
        this.euroPerc = 1
        this.clonar = true
        this.formFNInit()
        if (!val.data.contato.lingua_padrao_documentos) {
          val.data.contato.lingua_padrao_documentos = val.data.lingua
        }
        val.data.contato.id = val.data.contato.contato_id
        this.selContato(val.data.contato, val.data)
        this.SET_DOCUMENTO_BTS({})
        break
      }
    },
    '$store.state.converterCompras' (val) {
      try {
        // converter das compras para vendas o update dos preços
        if (Array.isArray(val)) {
          let i = val.length
          if (i) {
            let u = this.form.produtos.length
            let x
            while (--u >= 0) {
              x = i
              while (--x >= 0) {
                if (parseInt(this.form.produtos[u].id) === parseInt(val[x].id) && !this.form.produtos[u].updatePreco) {
                  this.form.produtos[u].preco_unitario = val[x].preco_unitario
                  this.form.produtos[u].preco_unitario_iva = val[x].preco_unitario_iva
                  if (this.ISENTO) {
                    this.form.produtos[u].imposto_id = 0
                    this.form.produtos[u].imposto = 0
                    this.form.produtos[u].motivo_isencao_id = this.RAZOESISENCAO
                  } else {
                    /*
                      if (val[x].imposto_id > 0) {
                        this.form.produtos[u].imposto_id = val[x].imposto_id
                        this.form.produtos[u].imposto = Principal(this.impostos, this.form.produtos[u].imposto_id).valor
                        this.form.produtos[u].motivo_isencao_id = this.form.produtos[u].imposto > 0 ? '' : this.RAZOESISENCAO
                      } else {
                        this.form.produtos[u].imposto_id = Principal(this.impostos).id
                        this.form.produtos[u].imposto = Principal(this.impostos, this.form.produtos[u].imposto_id).valor
                        this.form.produtos[u].motivo_isencao_id = this.form.produtos[x].imposto > 0 ? '' : this.RAZOESISENCAO
                      }
                    */
                  }
                  this.form.produtos[x].updatePreco = true
                  break
                }
              }
            }
            /*
              if (i !== this.form.produtos.length && i) {
                this.$swal('', this.l('nem_todos_os_precos_foram_atualizados'), 'info')
              } else {
                if (i === this.form.produtos.length && i) {
                  this.$swal('', this.l('nao_existe_precos_definidos_para_venda'), 'info')
                }
              }
            */
            this.SET_CONVERTER_COMPRAS([])
            this.contas()
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    // quando mudo de loja e movimenta stock e tenho artigos
    '$store.state.loja' (lj) {
      try {
        let a = this.$store.state.lojas.find(x => x.id === lj)
        this.ao.carga_cidade = a.cidade
        this.ao.carga_codigo_postal = a.codigo_postal
        this.ao.carga_morada = a.morada
        // this.ao.carga_pais = this.empresa.pais
        if (!this.$store.state.stockNegativo && this.form.produtos.length && this.form.terminado < 1) {
          this.$swal(this.l('mudou_loja_tem_produtos'), this.l('mudou_loja_tem_produtos_desc'), 'info')
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleWindowResize)
    window.removeEventListener('keypress', this.handleKeyPress)
  },
  mounted () {
    window.addEventListener('keypress', this.handleKeyPress)
    this.pesqProjetoFN = this._.debounce(function () {
      this.projetosFN(1)
    }, 550)
    this.descontoTotal = this._.debounce(function () {
      if (this.descontTotalValor !== this.ao.desconto_total) {
        let desconto = ''
        if (this.ao.desconto_total > 0) {
          if (this.ao.desconto_total >= this.totalQP) {
            this.ao.desconto_total = this.totalQP
            desconto = 100
          } else {
            desconto = parseFloat((this.ao.desconto_total * 100 / this.totalQP).toFixed(3))
          }
        }
        this.descontTotalValor = this.ao.desconto_total
        this.ao.desconto_percentual = desconto
        let a = this.form.produtos.length
        let i = 0
        let total = 0
        while (i < a) {
          if (!this.form.produtos[i].produto_composto) {
            this.form.produtos[i].desconto_1 = desconto
            this.form.produtos[i].desconto_2 = ''
            this.form.produtos[i].desconto_3 = ''
            this.produto(this.form.produtos[i], i, null, 1)
            total += this.form.produtos[i].desconto_1Civa
          }
          ++i
        }
        this.ao.desconto_total_iva = total
        this.contas()
      }
    }, 550)
    this.descontoTotal1 = this._.debounce(function () {
      if (this.descontTotalValor_ !== this.ao.desconto_total_iva) {
        let desconto = ''
        let total = 0
        this.form.produtos.map(dt => {
          total += dt.preco_unitario_iva * dt.quantidade
        })
        if (this.ao.desconto_total_iva > 0) {
          if (this.ao.desconto_total_iva >= total) {
            this.ao.desconto_total_iva = total
            desconto = 100
          } else {
            desconto = parseFloat((this.ao.desconto_total_iva * 100 / total).toFixed(3))
          }
        }
        this.descontTotalValor = ''
        this.ao.desconto_percentual = desconto
        let a = this.form.produtos.length
        let i = 0
        while (i < a) {
          if (!this.form.produtos[i].produto_composto) {
            if (!(desconto > 0)) {
              this.form.produtos[i].desconto_1Siva = ''
              this.form.produtos[i].desconto_1Civa = ''
            }
            this.form.produtos[i].desconto_1 = desconto
            this.form.produtos[i].desconto_2 = ''
            this.form.produtos[i].desconto_3 = ''
            this.produto(this.form.produtos[i], i, null, 1)
          }
          ++i
        }
        this.contas()
      }
    }, 550)
    this.descontoPercentual = this._.debounce(function () {
      if (this.descontTotalPercentual !== this.ao.desconto_percentual) {
        if (this.ao.desconto_percentual > 100) {
          this.ao.desconto_percentual = 100
        }
        this.descontTotalPercentual = this.ao.desconto_percentual
        this.ao.desconto_total = ''
        let a = this.form.produtos.length
        let i = 0
        let total = 0
        while (i < a) {
          if (!this.form.produtos[i].produto_composto) {
            this.form.produtos[i].desconto_1 = this.ao.desconto_percentual
            this.form.produtos[i].desconto_2 = ''
            this.form.produtos[i].desconto_3 = ''
            this.produto(this.form.produtos[i], i, null, 1)
            total += this.form.produtos[i].desconto_1Civa
          }
          ++i
        }
        this.ao.desconto_total_iva = total
        this.contas()
      }
    }, 550)
    /*
    let a = window.location.pathname.split('/')
    if (a[1] === 'quebrastock' && a[2] === 'criar' && this.$route.query.i !== 'SSTK') {
      this.$router.replace('/quebrastock/criar?i=SSTK', () => {})
    }
    */
    this.montou = true
    this.information()
    this.defineForm()
    if (this.$route.query.a) {
      this.agendar = true
    } else {
      this.agendar = false
    }
    this.recibo()
    window.scrollTo(0, 0)
    window.addEventListener('resize', this.handleWindowResize)
    this.handleWindowResize()
    /*
    this.$refs.windowResize.$on('resize', () => {
      this.widthBody = document.body.clientWidth >= 850
    })
    */
  },
  methods: {
    ...mapMutations([
      'SET_TOAST',
      'SET_TITULO',
      'SET_LOAD',
      'SET_ERROR',
      'SET_HELP',
      'SET_AVISO',
      'SET_CONVERTER',
      'SET_CONVERTER_RESULT',
      'SET_CONVERTER_RESULT1',
      'SET_CONVERTER_COMPRAS1',
      'SET_CONVERTER_COMPRAS',
      'SET_MENU',
      'SET_DOCUMENTO_BTS',
      'FOCO',
      'SET_ANULADO',
      'SET_SCROLLBOTTOM',
      'SET_SCROLL'
    ]),
    disableDates(date) {
      if (this.page2 === 'compras') {
        return false
      }
      const today = new Date();
      const sevenDaysBefore = new Date(today);
      const thirtyDaysAfter = new Date(today);

      // Ajustar as datas de início e fim
      sevenDaysBefore.setDate(today.getDate() - 7); // 7 dias antes
      thirtyDaysAfter.setDate(today.getDate() + 30); // 30 dias depois

      // Desabilitar datas fora do intervalo
      return date < sevenDaysBefore || date > thirtyDaysAfter;
    },
    handleChangeTime (value, type) {
      if (type === 'second') {
        this.openTime = false
        this.openTimeCarga = false
        this.openTimeDescarga = false
      }
    },
    handleKeyPress(event) {
      if (this.form.contato_id) {
        const currentTime = new Date().getTime()

        if (currentTime - this.lastKeyPressTime < 50) {
          this.barcodeBuffer += event.key
        } else {
          this.barcodeBuffer = event.key
        }

        // Update the last key press time
        this.lastKeyPressTime = currentTime

        // Check if the buffer contains a valid barcode
        clearTimeout(this.barcodeTimeout)
        this.barcodeTimeout = setTimeout(() => {
          let item = this.barcodeBuffer.toString().split('Enter')[0]
          if (item.length > 5) {
            this.processBarcode = item
            setTimeout(() => {
              this.processBarcode = ''
            }, 10)
          }
        }, 100)
      }
    },
    handleWindowResize () {
      // console.log(e.currentTarget.innerWidth, '.,,.', document.body.clientWidth)
      this.widthBody = document.body.clientWidth > 850
    },
    l (a) {
      return this.$t('documentos.' + a)
    },
    Principal (ar, id, a) {
      return Principal(ar, id, a)
    },
    agendamentoFN () {
      if (this.agendar) {
        if (!(this.ao.data_inicio && this.ao.data_fim)) {
          this.agendamento = []
          return
        }
        if (!new Date(this.ao.data_inicio).valueOf()) {
          this.$swal(this.$t('data_inicio'), this.$t('1'), 'error')
          return
        }
        if (!new Date(this.ao.data_fim).valueOf()) {
          this.$swal(this.$t('data_fim'), this.$t('1'), 'error')
          return
        }
        if (new Date(this.ao.data_fim) < new Date(this.ao.data_inicio)) {
          this.$swal(this.$t('datas'), this.$t('data_inicio_data_fim'), 'error')
          return
        }
        let inicio = new Date(this.ao.data_inicio)

        let agendamento = [inicio.getFullYear() + '-' + ('0' + (inicio.getMonth() + 1)).slice(-2) + '-' + ('0' + inicio.getDate()).slice(-2)]

        let fim = new Date(this.ao.data_fim)
        switch (this.ao.frequencia) {
        case 'dias':
          while (inicio) {
            inicio.setDate(inicio.getDate() + parseInt(this.ao.a_cada))
            if (inicio <= fim) {
              agendamento.push(inicio.getFullYear() + '-' + ('0' + (inicio.getMonth() + 1)).slice(-2) + '-' + ('0' + inicio.getDate()).slice(-2))
            } else {
              break
            }
          }
          break
        case 'meses':
          while (inicio) {
            inicio.setMonth(inicio.getMonth() + parseInt(this.ao.a_cada))
            if (inicio <= fim) {
              agendamento.push(inicio.getFullYear() + '-' + ('0' + (inicio.getMonth() + 1)).slice(-2) + '-' + ('0' + inicio.getDate()).slice(-2))
            } else {
              break
            }
          }
          break
        case 'anos':
          while (inicio) {
            inicio.setYear(inicio.getFullYear() + parseInt(this.ao.a_cada))
            if (inicio <= fim) {
              agendamento.push(inicio.getFullYear() + '-' + ('0' + (inicio.getMonth() + 1)).slice(-2) + '-' + ('0' + inicio.getDate()).slice(-2))
            } else {
              break
            }
          }
          break
        }
        this.agendamento = agendamento
      } else {
        this.agendamento = []
      }
    },
    openConverter () {
      if (this.form.motivo && this.form.contato_id > 0 && (this.form.tipo_documento_tp === 'ND' || this.form.tipo_documento_tp === 'NC' || this.form.tipo_documento_tp === 'VND' || this.form.tipo_documento_tp === 'VNC') && this.form.produtos.length === 0) {
        this.SET_CONVERTER(this.form)
      }
    },
    upData () {
      let data1 = new Date(this.data1)
      let data2 = new Date()
      if (data1.getDate() !== data2.getDate() || data1.getMonth() !== data2.getMonth() || data1.getFullYear() !== data2.getFullYear()) {
        let self = this
        this.$swal({
          title: self.l('avancar_data').replace('@data', data1.getFullYear() + '-' + ('0' + (data1.getMonth() + 1)).slice(-2) + '-' + ('0' + data1.getDate()).slice(-2)),
          text: data1 > new Date() ? self.l('avancar_data_descricao') : '',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: self.$t('confirmar'),
          cancelButtonText: self.$t('cancelar'),
          allowOutsideClick: false,
          focusCancel: true
        }).then((result) => {
          if (result.dismiss === 'cancel') {
            self.data1 = new Date()
            self.delyFoco('data')
          } else {
            this.showTime1 = true
          }
        })
      } else {
        this.showTime1 = false
      }
      this.ao.data_carga = this.data1
      this.ao.data_descarga = this.data1
    },
    route (b, c) {
      if (this.form.contato_id > 0 && this.$store.state.help.tipo !== 'contacto') {
        this.delyFoco('itens')
      } else {
        this.delyFoco('contactos')
      }
      if (this.$store.state['tipos-documento1']) {
        try {
          let e = b.query.i
          let f = c.query.i
          this.defineForm()
          if ((e !== f && this.$store.state.help.tipo !== 'contacto' && this.$store.state.help.tipo !== 'artigo' && this.$store.state.help.tipo !== 'contacto1' && this.$store.state.help.tipo !== 'artigo1') || (this.form.id > 0 && this.$store.state.help.tipo !== 'contacto' && this.$store.state.help.tipo !== 'artigo' && this.$store.state.help.tipo !== 'contacto1' && this.$store.state.help.tipo !== 'artigo1') || this.form.terminado === 1) {
            this.information()
            this.recibo()
          }
          if (e === f && this.$store.state.help.tipo !== 'contacto1' && this.$store.state.help.tipo !== 'artigo1') {
            this.form.tipo_documento_tp = this.$route.query.i
          }
          if ((this.$store.state.help.tipo === 'contacto1' || this.$store.state.help.tipo === 'artigo1') && this.form.terminado !== 1) {
            if (this.$store.state.help.tipo === 'artigo1') {
              this.form = this.$store.state.help.valor.form
              this.PrecoIVA = this.$store.state.help.valor.PrecoIVA
              this.ao.viaturaoutra = this.$store.state.help.valor.viaturaoutra
              this.ao.arredondamento = this.$store.state.help.valor.arredondamento
              this.sinal = this.$store.state.help.valor.sinal
              this.ao.dados_transporte = this.$store.state.help.valor.dados_transporte
              this.ao.prazo_vencimento = this.$store.state.help.valor.prazo_vencimento
              this.ao.prazo_vencimento1 = somarDias(this.ao.prazo_vencimento, this.data1)
              this.ao.data_carga = new Date(this.$store.state.help.valor.data_carga)
              this.ao.hora_carga = new Date('1900/01/01 ' + this.$store.state.help.valor.hora_carga)
              this.ao.data_descarga = new Date(this.$store.state.help.valor.data_descarga)
              this.ao.hora_descarga = new Date('1900/01/01 ' + this.$store.state.help.valor.hora_descarga)
              if (this.$store.state.help.valor.res.codigo) {
                this.selArtigo(this.$store.state.help.valor.res)
              } else {
                this.SET_LOAD()
                this.delyFoco('itens')
              }
              // window.scrollTo(0, window.document.getElementById('itens').offsetTop - 200)
            } else {
              this.formFNInit()
              this.selContato(this.$store.state.help.valor.res)
              window.scrollTo(0, window.document.getElementById('titulo1').offsetTop + 60)
            }
            this.SET_HELP({})
          } else {
            this.SET_LOAD()
          }
          if (this.form.tipo_documento_tp === 'SINIC' || this.form.tipo_documento_tp === 'SINIV' || this.form.tipo_documento_tp === 'RC' || this.form.tipo_documento_tp === 'RFC' || this.form.tipo_documento_tp === 'ORC' || this.form.tipo_documento_tp === 'VORC' || this.form.tipo_documento_tp === 'DP' || this.form.tipo_documento_tp === 'PEDG') {
            this.SET_TITULO(this.$t('novo') + ' ' + this.l('documentos.' + this.form.tipo_documento_tp))
          } else {
            this.SET_TITULO(this.$t('nova') + ' ' + this.l('documentos.' + this.form.tipo_documento_tp))
          }
        } catch (e) {
          console.log(e)
        }
      } else {
        this._.delay(this.route, 150, b, c)
      }
    },
    idChequeFN () {
      this.idCheque = Principal(this.$store.state['metodos-pagamento'], this.form.metodo_pagamento_id).simbolo === 'CH'
    },
    information () {
      this.defineForm()
      let id = window.location.pathname.split('/')
      if ((id[2] === 'criar' || id[2] === 'editar')) {
        if (this.$store.state.impostos1 && this.$store.state.lojas1 && this.$store.state['unidades-medida1'] && this.$store.state.utilizador1 && this.$store.state.isencao1 && this.$store.state.viaturas1 && this.$store.state['metodos-expedicao1'] && this.$store.state['metodos-pagamento1'] && this.$store.state['tipos-documento1'] && this.$store.state.series1 && this.$store.state.documentosMeta1) {
          if (this.$store.state.seriesAll.length === 0) {
            this.$swal('', this.l('deve_ter_uma_serie_ativa'), 'error')
            this.$router.replace('/configuracoes/series', () => {})
            return
          }
          if (!Principal(this.$store.state.seriesAll).id) {
            this.$swal('', this.l('deve_ter_uma_serie_ativa'), 'error')
            this.$router.replace('/configuracoes/series', () => {})
            return
          }
          this.SET_CONVERTER_COMPRAS([])
          this.SET_CONVERTER_COMPRAS1([])
          if (this.$store.getters.EMPRESA) {
            if (this.$store.getters.EMPRESA.cae) {
              this.caes = this.$store.getters.EMPRESA.cae.toString().split(' ')
            }
          }
          this.form = this.init()
          this.lojas = this.$store.state.lojas
          this.pagamento = this.$store.state['metodos-pagamento']
          this.expedicao = this.$store.state['metodos-expedicao']
          this.impostos = this.$store.state.impostos
          this.razoesIsencaoIva = this.$store.state.isencao
          this.documentos = this.$store.state['tipos-documento']
          this.unidades = this.$store.state['unidades-medida']
          this.viaturas = this.$store.state.viaturas
          this.series = this.$store.state.seriesAll
          this.empresa = this.$store.getters.EMPRESA
          this.form.metodo_expedicao_id = Principal(this.expedicao).id
          this.form.metodo_pagamento_id = Principal(this.pagamento).id
          this.metas = this.$store.state.documentosMeta
          this.idCheque = Principal(this.$store.state['metodos-pagamento'], this.form.metodo_pagamento_id).simbolo === 'CH'
          this.allRecibos = false

          this.form.moeda = this.empresa.moeda
          let x = Principal(this.lojas, this.$store.state.loja)
          if (x.morada && x.cidade && x.codigo_postal) {
            this.ao.carga_morada = x.morada
            this.ao.carga_codigo_postal = x.codigo_postal
            this.ao.carga_cidade = x.cidade
          } else {
            this.ao.carga_morada = this.empresa.morada
            this.ao.carga_codigo_postal = this.empresa.codigo_postal
            this.ao.carga_cidade = this.empresa.cidade
          }
          this.ao.carga_pais = this.empresa.pais

          if (this.$store.state.documents_bts.tipo === 'clonarListagem' && this.$store.state.documents_bts.data > 0) {
            id[4] = this.$store.state.documents_bts.data
            this.clonar = true
            this.clonar1 = true
          }

          if ((id[4] > 0 && id[1] !== 'quebrastock') || (id[1] === 'quebrastock' && id[3] > 0)) {
            this.SET_LOAD(true)
            this.pesqProjeto = ''
            documentosId(this.axios, id[1] === 'quebrastock' ? id[3] : id[4], this.$store.state.modulo).then((res) => {
              if (this.$store.state.documents_bts.tipo === 'clonarListagem' && this.$store.state.documents_bts.data > 0) {
                if (this.$store.state.documents_bts.limpar) {
                  this.contacto = ''
                  this.contato = ''
                  res.data.contato_id = ''
                  res.data.contato = {}
                  res.data.morada1 = ''
                  this.moradas = []
                  this.delyFoco('contactos')
                }
                this.SET_DOCUMENTO_BTS({})
                res.data.terminado = 0
                res.data.id = ''
                res.data.metodo_expedicao_id = Principal(this.$store.state['metodos-expedicao']).id
                res.data.metodo_pagamento_id = Principal(this.$store.state['metodos-pagamento']).id
                // let serie = Principal(this.$store.state.lojas, this.$store.state.loja).serie_id
                // res.data.serie_id = serie > 0 ? serie : Principal(this.$store.state.series).id
                res.data.importado = 0
              }

              if (this.clonar || this.clonar1) {
                res.data.codigo_discussao = ''
              }
              let tp = this.$store.state['tipos-documento'].find(a => a.id === res.data.tipo_documento_id).tipificacao
              res.data.discussao = this.$store.state.utilizador.empresa.discussao_em_documentos && (tp === 'FT' || tp === 'FR' || tp === 'FS' || tp === 'GT' || tp === 'GR' || tp === 'NENC' || tp === 'ORC' || tp === 'FPF' || tp === 'VFT' || tp === 'VFR' || tp === 'VFS' || tp === 'VGR' || tp === 'VGT' || tp === 'VNENC' || tp === 'VORC' || tp === 'VFPF')
              res.data.password_discussao = ''

              if (res.data.terminado && this.PROJETOS) {
                this.projetosFN(1, res.data.contato_id, res.data.projectos, 1)
              }

              this.formFN(res)
              this.historicDocument()
              this.resultado = true
            }, () => {
              this.resultado = true
              this.$swal('', this.l('documento_nao_existe'), 'error')
              if (id[1] === 'quebrastock') {
                this.$router.replace('/quebrastock/1', () => {})
              } else {
                this.$router.replace('/' + this.page + '/' + this.page2 + '/1', () => {})
              }
            })
          } else {
            this.formFNInit()
            this.historicDocument()
            this.resultado = true
          }
          if (this.form.tipo_documento_tp === 'GA') {
            propriaEmpresa(this.axios, this.$store.state.modulo).then((res) => {
              this.lojaGuia1 = res.data
            }, () => {})
          }
        } else {
          this._.delay(this.information, 150)
        }
      }
    },
    projetosFN (pag, contato, projectos, ativo) {
      if (this.form.terminado || ativo) {
        projectos = projectos || this.form.projectos || []
        let search = { pesquisa: { titulo: this.pesqProjeto, codigo: this.pesqProjeto } }
        if (this.page2 !== 'compras') {
          search.pesquisa.contato_id = contato || this.form.contato_id
        }
        projetos(this.axios, search, pag, this.$store.state.modulo).then((res) => {
          this.pag = res.data.current_page
          this.lastpage = res.data.last_page
          this.projetos = res.data.data.map(a => {
            if (projectos.find(b => a.id === b.projecto_id)) {
              a.sel = true
              return a
            }
            a.sel = false
            return a
          })
        }, () => {})
        if (!this.buscouProjectos) {
          this.buscouProjectos = true
          projetos(this.axios, { pesquisa: { documentos: [this.page3] }, limite: 200 }, 1, this.$store.state.modulo).then((res) => {
            this.SelProjetos = res.data.data
          }, () => {})
        }
      }
    },
    historicDocument () {
      if (window.localStorage.getItem('documento') && !this.page3) {
        let a = JSON.parse(window.localStorage.getItem('documento'))
        if (a.form.tipo_documento_tp === this.$route.query.i && this.$route.query.i && a.form.terminado < 1 && a.user === this.PERMISSOES.user_id) {
          this.time1 = new Date('1900/01/01 ' + a.time1)
          this.form = a.form
          this.data1 = new Date(a.data1)
          this.moradas = a.moradas
          this.ao = a.ao
          this.fdatas()
          this.contacto = a.contacto
          this.contato = a.contato
          this.form.cae = Principal(this.series, this.form.serie_id, 1).cae
          this.PrecoIVA = Principal(this.series, this.form.serie_id).iva_incluido
          this.agendar = a.agendar
          this.metas = a.metas
          if (this.agendar) {
            this.agendamentoFN()
          }
          this.contas()
        }
      } else {
        window.localStorage.removeItem('documento')
      }
      this.SET_LOAD()
    },
    formFNInit () {
      this.isButtonDisabled = false
      let idDoc = this.$store.state['tipos-documento'].find(a => a.tipificacao === this.$route.query.i || (a.tipificacao === 'GT' && this.$route.query.i === 'guiaglobal'))
      if (idDoc) {
        this.form.tipo_documento_tp = this.$route.query.i
        this.form.tipo_documento_id = idDoc.id
      } else {
        if (this.page1 === 'criar') {
          if (this.page === 'documentos') {
            this.$router.replace('/documentos/' + this.page2 + '/1', () => {})
          } else {
            this.$router.replace('/' + this.page + '/1', () => {})
          }
        }
      }
      this.selectClient = false
      this.moradas = []
      this.artigo = []
      this.fdatas()
      this.form.terminado = 0
      this.form.loja_id = this.$store.state.loja
      let serie = Principal(this.lojas, this.$store.state.loja).serie_id
      this.form.serie_id = serie > 0 ? serie : Principal(this.series).id
      this.form.cae = Principal(this.series, this.form.serie_id, 1).cae
      this.PrecoIVA = Principal(this.series, this.form.serie_id).iva_incluido

      this.SET_LOAD(true)
      if (this.form.tipo_documento_tp === 'GMD') {
        contatos(this.axios, { pesquisa: { texto: { codigo: 'guiaglobal' } } }, 1, this.$store.state.modulo).then((res) => {
          if (res.data.data.length) {
            this.selContato(res.data.data[0])
          } else {
            this.$swal(this.l('documentos.GMD'), this.l('nao_encontrou_o_contacto'), 'error')
            this.$router.replace('/documentos/' + this.page1 + '/1', () => {})
          }
          this.SET_LOAD()
        }, () => {
          this.$swal(this.l('documentos.GMD'), this.l('nao_encontrou_o_contacto'), 'error')
          this.$router.replace('/documentos/' + this.page1 + '/1', () => {})
          this.SET_LOAD()
        })
      } else {
        if (this.$route.query.c > 0) {
          contatosId(this.axios, {}, this.$route.query.c, this.$store.state.modulo).then((res) => {
            this.selContato(res.data)
            // this.SET_LOAD()
          }, () => {
            this.$swal(this.l('documentos.GMD'), this.l('nao_encontrou_o_contacto'), 'error')
            this.$router.replace('/documentos/' + this.page1 + '/1', () => {})
            this.SET_LOAD()
          })
        } else {
          this.SET_LOAD()
        }
      }

      this.metas = this.$store.state.documentosMeta
      for (let i in this.metas) {
        this.metas[i].option.valor = ''
      }
      this.ao.dados_transporte = false
      this.ao.arredondamento = ''
      if (this.form.tipo_documento_tp === 'SINIC' || this.form.tipo_documento_tp === 'SINIV' || this.form.tipo_documento_tp === 'RC' || this.form.tipo_documento_tp === 'RFC' || this.form.tipo_documento_tp === 'ORC' || this.form.tipo_documento_tp === 'VORC' || this.form.tipo_documento_tp === 'DP' || this.form.tipo_documento_tp === 'PEDG') {
        this.SET_TITULO(this.$t('novo') + ' ' + this.l('documentos.' + this.form.tipo_documento_tp))
      } else {
        this.SET_TITULO(this.$t('nova') + ' ' + this.l('documentos.' + this.form.tipo_documento_tp))
      }
      this.SET_MENU({})
      this.transporte()
      if (this.agendar) {
        this.agendamentoFN()
      }
      this.delyFoco('contactos')
    },
    formFN (res) {
      try {
        if (!res.data) {
          return
        }
        this.selectClient = false
        this.moradas = []
        this.artigo = []
        this.form = res.data
        this.time1 = new Date(res.data.system_entry_date)
        if (this.$route.query.i && this.$route.query.i !== 'null') {
          this.form.tipo_documento_tp = this.$route.query.i
          this.form.tipo_documento_id = this.$store.state.idDocumento[this.form.tipo_documento_tp]
        } else {
          this.form.tipo_documento_tp = this.$store.state['tipos-documento'].find(a => a.id === this.form.tipo_documento_id).tipificacao
        }
        // this.form.codigo_discussao = 1 aqui devia de limpar o codigo do documento antigo

        this.PrecoIVA = Principal(this.series, this.form.serie_id).iva_incluido
        if (this.page !== 'quebrastock') {
          this.form.cae = Principal(this.series, this.form.serie_id, 1).cae
          if (res.data.contato.codigo === 'guiaglobal' && res.data.tipo_documento_tp === 'GT') {
            this.form.tipo_documento_tp = 'guiaglobal'
          }
          if (res.data.contato_id && (this.form.tipo_documento_tp !== 'GA' || this.form.id)) {
            this.rescontatos_ = res.data.contato
            this.contato = res.data.contato
            this.contacto = res.data.contato.nome
            this.fnmoradas(this.contato, res.data)
            this.form.cliente_retencao = this.page2 === 'compras' ? true : res.data.contato.retencao
          } else {
            if (this.form.tipo_documento_tp === 'GA') {
              this.FNLojaGuia()
            }
            this.rescontatos_ = {}
            this.contato = {}
            this.contacto = ''
          }

          this.ao.viaturaoutra = this.form.viatura
          if (res.data.arredondamento > 0) {
            this.ao.arredondamento = res.data.arredondamento * 100 || ''
            this.sinal = true
          } else {
            this.ao.arredondamento = res.data.arredondamento * 100 * -1 || ''
            this.sinal = false
          }
        }
        this.meta()
        this.form.produtos = []
        let u = res.data.lancamentos.length
        let i = 0
        let xx
        let kk
        let quantidadeDef
        while (i < u) {
          if (res.data.importado) {
            res.data.lancamentos[i].referencia_manual = res.data.invoice_number
          }
          if (res.data.lancamentos[i].descricao_extra) {
            res.data.lancamentos[i].descricao_extra1 = true
          }
          if (res.data.lancamentos[i].serials) {
            xx = res.data.lancamentos[i].serials.length
            kk = 0
            if (xx) {
              while (--xx >= 0) {
                res.data.lancamentos[i].serials[xx] = { serial: res.data.lancamentos[i].serials[xx].serial, serialFocus: false }
                if (res.data.lancamentos[i].serials[xx].serial) {
                  kk = 1
                }
              }
              if (kk) {
                res.data.lancamentos[i].serials1 = true
                if (res.data.lancamentos[i].serials.length < res.data.lancamentos[i].item.quantidade) {
                  while (res.data.lancamentos[i].serials.length < res.data.lancamentos[i].item.quantidade) {
                    res.data.lancamentos[i].serials.push({ serial: '', serialFocus: false })
                  }
                }
              }
            }
          }

          res.data.lancamentos[i].servico = res.data.lancamentos[i].item.servico
          res.data.lancamentos[i].codigo = res.data.lancamentos[i].item.codigo
          if (res.data.lancamentos[i].item.unidade_medida) {
            res.data.lancamentos[i].unidade = res.data.lancamentos[i].item.unidade_medida.simbolo
          }
          if (this.clonar1) {
            res.data.lancamentos[i].lancamento_pai_id = ''
            res.data.lancamentos[i].convertido = ''
          } else {
            if (res.data.lancamentos[i].lancamento_pai_id > 0) {
              res.data.lancamentos[i].convertido = res.data.lancamentos[i].origem.documento.invoice_number
            }
          }
          res.data.lancamentos[i].quantidade = parseFloat(res.data.lancamentos[i].quantidade)

          if (res.data.lancamentos[i].produto_composto) {
            quantidadeDef = res.data.lancamentos[i].quantidade
          }
          if (res.data.lancamentos[i].item_pai_id) {
            res.data.lancamentos[i].quantidade_def = res.data.lancamentos[i].quantidade / quantidadeDef
          }
          // res.data.lancamentos[i].preco_unitario = round10(res.data.lancamentos[i].preco_unitario, this.casasDecimais)
          // if (this.PrecoIVA) {
          if (res.data.lancamentos[i].imposto_id > 0) {
            res.data.lancamentos[i].imposto = Principal(this.impostos, res.data.lancamentos[i].imposto_id).valor
          } else {
            res.data.lancamentos[i].imposto = 0
            res.data.lancamentos[i].imposto_id = 0
          }
          // res.data.lancamentos[i].preco_unitario_iva = round10(res.data.lancamentos[i].preco_unitario * (res.data.lancamentos[i].imposto / 100 + 1), this.casasDecimais)

          res.data.lancamentos[i].preco_unitario = parseFloat(res.data.lancamentos[i].preco_unitario) || 0
          res.data.lancamentos[i].preco_unitario_iva = res.data.lancamentos[i].preco_unitario * (res.data.lancamentos[i].imposto / 100 + 1)
          // }
          res.data.lancamentos[i].desconto_1 = parseFloat(res.data.lancamentos[i].desconto_1) || ''
          res.data.lancamentos[i].desconto_2 = parseFloat(res.data.lancamentos[i].desconto_1) > 0 ? parseFloat(res.data.lancamentos[i].desconto_2) : ''
          res.data.lancamentos[i].desconto_3 = parseFloat(res.data.lancamentos[i].desconto_1) > 0 && parseFloat(res.data.lancamentos[i].desconto_2) > 0 ? parseFloat(res.data.lancamentos[i].desconto_3) : ''
          res.data.lancamentos[i].id1 = res.data.lancamentos[i].item_id + '-' + Math.random()

          if (res.data.lancamentos[i].data_variacao) {
            res.data.lancamentos[i].variavel = 1
            res.data.lancamentos[i].variavelAr = JSON.parse(res.data.lancamentos[i].data_variacao)
          }
          // this.form.produtos.push(res.data.lancamentos[i])
          // this.produto(res.data.lancamentos[i], i)
          ++i
        }
        this.$set(this.form, 'produtos', res.data.lancamentos)
        i = this.form.produtos.length
        let xk = 0
        while (xk < i) {
          this.produto(this.form.produtos[xk], xk++)
        }

        if (res.data.terminado > 0) {
          this.data1 = new Date(res.data.invoice_date)
          // this.data1Time1 = this.data1.getFullYear() + '-' + ('0' + (this.data1.getMonth() + 1)).slice(-2) + '-' + ('0' + this.data1.getDate()).slice(-2)
        }
        if (this.page !== 'quebrastock') {
          if (res.data.terminado > 0) {
            this.ao.prazo_vencimento1 = res.data.prazo_vencimento
            if (res.data.data_carga) {
              this.ao.data_carga = new Date(res.data.data_carga)
              this.ao.hora_carga = new Date(res.data.data_carga)
            }
            if (res.data.data_descarga) {
              this.ao.data_descarga = new Date(res.data.data_descarga)
              this.ao.hora_descarga = new Date(res.data.data_descarga)
            }

            this.form.email = res.data.contato.origem.email
          } else {
            this.fdatas()
            let kxk = Math.ceil(Math.abs(new Date(this.form.updated_at).getTime() - new Date(this.form.prazo_vencimento).getTime()) / (1000 * 3600 * 24))
            this.ao.prazo_vencimento = kxk > 0 ? kxk : 0
          }
        }
        if (this.form.terminado) {
          this.SET_TITULO(this.l('documentos.' + this.form.tipo_documento_tp) + ' - ' + res.data.invoice_number + (res.data.importado ? '<sup class="text-info">' + this.l('importado') + '</sup>' : ''))
        } else {
          this.SET_TITULO(this.l('documentos.' + this.form.tipo_documento_tp) + '<sup class="text-success">' + this.l('rascunho') + '</sup>')
        }

        this.SET_MENU(this.form)
        if (this.page !== 'quebrastock') {
          this.transporte()
          if (res.data.carga_cidade || res.data.carga_codigo_postal || res.data.carga_morada || res.data.descarga_cidade || res.data.descarga_codigo_postal || res.data.descarga_morada) {
            this.ao.dados_transporte = true

            this.ao.carga_cidade = res.data.carga_cidade
            this.ao.carga_pais = res.data.carga_pais
            this.ao.carga_codigo_postal = res.data.carga_codigo_postal

            this.ao.carga_morada = res.data.carga_morada

            if (this.form.terminado) {
              this.ao.descarga_cidade = res.data.descarga_cidade
              this.ao.descarga_pais = res.data.descarga_pais
              this.ao.descarga_codigo_postal = res.data.descarga_codigo_postal

              this.ao.descarga_morada = res.data.descarga_morada
            }
          }
        }
        this.defineForm()
        if (this.moradas.length) {
          this.delyFoco('morada1')
        } else {
          this.delyFoco('data')
        }
        this.SET_LOAD()
      } catch (e) {
        console.log(e)
        this.SET_ERROR(true)
      }
    },

    meta () {
      this.existeMeta = false
      let u = this.metas.length
      while (--u >= 0) {
        this.$set(this.metas[u].option, 'valor', '')
      }
      if (Array.isArray(this.form.meta)) {
        let i = this.form.meta.length
        if (i) {
          let x = this.metas.length
          while (--i >= 0) {
            u = x
            while (--u >= 0) {
              if (this.form.meta[i].meta_key === this.metas[u].meta_key) {
                this.$set(this.metas[u].option, 'valor', this.form.meta[i].meta_value)
                if (this.form.meta[i].meta_value) {
                  this.existeMeta = true
                }
                // this.metas[u].option.valor = this.form.meta[i].meta_value
                break
              }
            }
          }
        }
      }
    },
    recibo (dt1) {
      this.ao.total_recibo = ''
      if (this.form.terminado !== 1 && (this.form.tipo_documento_tp === 'RC' || this.form.tipo_documento_tp === 'NL' || this.form.tipo_documento_tp === 'RFC') && this.form.contato_id > 0) {
        this.SET_LOAD(true)
        pagamentosAberto(this.axios, { contatos: [this.form.contato_id], importado: (this.form.tipo_documento_tp === 'NL' ? 1 : 0), estado: ['N', 'F'], categoria: (this.page2 === 'compras' ? 'compras' : 'faturas') }, this.$store.state.modulo).then((res) => {
          let i = res.data.length
          this.credito = 0
          while (--i >= 0) {
            res.data[i].total_desconto = ''
            res.data[i].valor = ''
            if (this.clonar) {
              if (parseInt(dt1.id) === parseInt(res.data[i].documento_id) && dt1.id > 0) {
                res.data[i].valor = parseFloat(res.data[i].total_divida)
                this.ao.total_recibo = parseFloat(res.data[i].total_divida)
              }
            }
            this.clonar = false
            res.data[i].pagar = ''
            this.credito += parseFloat(res.data[i].total_divida)
            this.delyFoco('A0')
          }
          this.ao.docsrecibo = res.data
          this.SET_LOAD()
        }, () => {
          this.SET_ERROR(true)
        })
      }
    },
    transporte () {
      switch (this.form.tipo_documento_tp) {
      case 'GT':
      case 'GR':
      case 'GC':
      case 'GA':
      case 'GD':
      case 'VGT':
      case 'VGR':
      case 'VGC':
      case 'VGA':
      case 'VGD':
        this.ao.viatura = Principal(this.viaturas).matricula
        this.blocktransporte = true
        this.ao.dados_transporte = true
        break
      case 'FT':
      case 'FR':
      case 'VFT':
      case 'VFR':
        this.ao.dados_transporte = false
        this.blocktransporte = false
        break
      default:
        this.ao.dados_transporte = false
        this.blocktransporte = true
      }
    },

    fdatas () {
      this.data1 = !this.form.terminado && this.page2 === 'compras' && this.form.id ? new Date(this.form.invoice_date) : new Date()
      this.ao.data_carga = !this.form.terminado && this.page2 === 'compras' && this.form.id ? new Date(this.form.data_carga) : new Date()
      this.ao.data_descarga = !this.form.terminado && this.page2 === 'compras' && this.form.id ? new Date(this.form.data_descarga) : new Date()
      this.ao.hora_carga = !this.form.terminado && this.page2 === 'compras' && this.form.id ? new Date(this.form.data_carga) : new Date('1900/01/01 ' + gethora(10, false))
      this.ao.hora_descarga = !this.form.terminado && this.page2 === 'compras' && this.form.id ? new Date(this.form.data_descarga) : new Date('1900/01/01 23:59:59')
      this.time1 = new Date('1900/01/01 ' + gethora(10, false))
    },
    tipoDocumento () {
      if (!(window.location.pathname.split('/')[4] > 0)) {
        this.$router.replace(window.location.pathname + '?i=' + this.form.tipo_documento_tp || '', () => {})
      }
    },
    fnmoradas (dt, edit) {
      if (dt.origem) {
        this.contato = dt.origem
        dt = this.contato
      }
      this.form.morada1 = 'sede'
      if (edit) {
        this.ao.descarga_morada = edit.descarga_morada
        this.ao.descarga_codigo_postal = edit.descarga_codigo_postal
        this.ao.descarga_cidade = edit.descarga_cidade
        this.ao.descarga_pais = edit.descarga_pais
      } else {
        this.ao.descarga_morada = this.contato.morada
        this.ao.descarga_codigo_postal = this.contato.codigo_postal
        this.ao.descarga_cidade = this.contato.cidade
        this.ao.descarga_pais = this.contato.pais
      }
      let m = dt.moradas.length
      let i = 0
      let u = 0
      let paises = '!'
      let pais = ''
      let moradas = []
      this.moradas = []
      while (i < m) {
        if (pais === '' || paises.indexOf('!' + dt.moradas[i].pais + '!') === -1) {
          paises += '!' + dt.moradas[i].pais + '!'
          pais = dt.moradas[i].pais
          moradas = []
          u = 0
          while (u < m) {
            if (pais === dt.moradas[u].pais) {
              moradas.push(dt.moradas[u])
            }
            ++u
          }
          this.moradas.push({ pais: pais, moradas: moradas })
        }
        ++i
      }
    },
    delContato () {
      this.contacto = ''
      this.contato = ''
      this.form.contato_id = ''
      this.moradas = []
      this.delyFoco('contactos')
    },
    codigoPostalFN (campo, valor, pais) {
      switch (campo) {
      case 'descarga':
        if (pais === 'PT') {
          if (/^[0-9]{4}-[0-9]{3}$/.test(valor) || /^[0-9]{4}-[0-9]{3}.{0,}$/.test(valor)) {
            this.ao.descarga_codigo_postal = valor.toString().substring(0, 8)
          } else {
            if (/^[0-9]{4}$/.test(valor) || /^[0-9]{4}.{0,}?$/.test(valor)) {
              this.ao.descarga_codigo_postal = valor.toString().substring(0, 4) + '-000'
            } else {
              this.ao.descarga_codigo_postal = ''
            }
          }
        } else {
          this.ao.descarga_codigo_postal = valor
        }
        break
      }
    },
    selContato (dt, dt1) {
      if (!dt) {
        return
      }
      this.allRecibos = false
      let xi = this.metas.length
      if (!dt.meta && xi) {
        contatosId(this.axios, {}, dt.id, this.$store.state.modulo).then((res) => {
          if (Array.isArray(res.data.meta)) {
            this.contato = res.data
            let xu = 0
            res.data.meta.map(a => {
              xu = xi
              while (--xu >= 0) {
                if (this.metas[xu].meta_key === a.meta_key && a.meta_value !== 'image') {
                  this.metas[xu].option.valor = a.meta_value
                  break
                }
              }
              return a
            })
          }
        }, () => {})
      }
      this.form.contato_divida_vencida = 0
      this.form.contato_divida = 0
      if (dt.credito_maximo > 0 && (this.page2 === 'faturas' || this.page2 === 'guias' || this.page2 === 'orcamentos') && this.PERMISSOES.relatorio_saldo_por_cliente && this.PERMISSOES.relatorio_facturacao_por_cliente) {
        estatisticasContato(this.axios, { contato_id: dt.id }).then((res) => {
          this.form.contato_divida_vencida = this.casasDec(res.data.vendas_vencido[0].total_divida)
          this.form.contato_divida = this.casasDec(res.data.vendas[0].total_divida)
          this.$forceUpdate()
        }, () => {})
      }

      this.ao.email_destino = this.contato.email
      this.contacto = dt.nome
      this.fnmoradas(dt)
      this.form.lingua = dt.lingua_padrao_documentos
      this.form.contato_id = dt.contato_id || dt.id
      this.ao.prazo_vencimento = dt.prazo_vencimento || this.$store.getters.VENCIMENTO
      this.up_prazo_vencimento()
      this.form.metodo_pagamento_id = dt.metodo_pagamento_id || Principal(this.pagamento).id
      this.form.metodo_expedicao_id = dt.metodo_expedicao_id || Principal(this.expedicao).id
      this.form.cliente_retencao = this.page2 === 'compras' ? true : dt.retencao

      this.ao.descarga_morada = dt.morada

      this.codigoPostalFN('descarga', dt.codigo_postal, dt.pais)

      this.ao.descarga_cidade = dt.cidade
      this.ao.descarga_pais = dt.pais

      this.ao.desconto_padrao = dt.desconto_padrao

      this.contato = dt

      this.form.moeda = this.empresa.moeda
      let x = Principal(this.lojas, this.$store.state.loja)
      if (x.morada && x.cidade && x.codigo_postal) {
        this.ao.carga_morada = x.morada
        this.ao.carga_codigo_postal = x.codigo_postal
        this.ao.carga_cidade = x.cidade
      } else {
        this.ao.carga_morada = this.empresa.morada
        this.ao.carga_codigo_postal = this.empresa.codigo_postal
        this.ao.carga_cidade = this.empresa.cidade
      }
      this.ao.carga_pais = this.empresa.pais

      if (this.moradas.length) {
        this.delyFoco('morada1')
      } else {
        this.delyFoco('data')
      }
      this.recibo(dt1)

      switch (this.form.tipo_documento_tp) {
      case 'DP':
        this.delyFoco('data')
        break
      default:
        this.delyFoco(this.moradas.length ? 'morada1' : 'data')
      }
      if (this.AtivarModalCompras) {
        window.scrollTo(0, 0)
        this.SET_CONVERTER_COMPRAS1(this.AtivarModalComprasData)
        this.AtivarModalCompras = false
        this.AtivarModalComprasData = []
      }
      this.openConverter()
    },
    upMorada () {
      if (this.form.morada1 === 'sede') {
        this.ao.descarga_morada = this.contato.morada
        // this.ao.descarga_codigo_postal = this.contato.codigo_postal

        this.codigoPostalFN('descarga', this.contato.codigo_postal, this.contato.pais)

        this.ao.descarga_cidade = this.contato.cidade
        this.ao.descarga_pais = this.contato.pais
      } else {
        let i = this.contato.moradas.length
        while (--i >= 0) {
          if (this.form.morada1 === this.contato.moradas[i].id) {
            this.ao.descarga_morada = this.contato.moradas[i].morada
            // this.ao.descarga_codigo_postal = this.contato.moradas[i].codigo_postal

            this.codigoPostalFN('descarga', this.contato.moradas[i].codigo_postal, this.contato.moradas[i].pais)

            this.ao.descarga_cidade = this.contato.moradas[i].cidade
            this.ao.descarga_pais = this.contato.moradas[i].pais
            break
          }
        }
      }
    },
    // remover linha adicionada ao documento do artigo
    removeItem (dt) {
      // this.$delete(this.form.produtos, index)
      let i = this.form.produtos.length
      let x = 1
      while (--i >= 0) {
        if (this.form.produtos[i].id1 === dt.id1) {
          if (dt.produto_composto) {
            let y = i
            while (this.form.produtos[++y]) {
              if (!this.form.produtos[y].item_pai_id) {
                break
              }
              ++x
            }
          }
          if (x === this.form.produtos.length) {
            // this.$set(this.form, 'produtos', [])
            this.form = Object.assign({}, this.form, { produtos: [] })
          } else {
            this.form.produtos.splice(i, x)
          }
          break
        }
      }
      // this.produtosLinha.splice(index, 1)
      this.contas()
      this.delyFoco('itens')
      /*
      if (dt.produto_composto) {
        let i = index + 1
        while (this.form.produtos[i]) {
          if (this.form.produtos[i].item_pai_id === dt.item_id) {
            this.form.produtos.splice(i, 1)
            continue
          }
          break
        }
      }
      this.form.produtos.splice(index, 1)
      this.contas()
      // this.delyFoco('itens')
      // this.SET_SCROLL()
      */
    },
    filtrarVariacoes () {
      this.modalProdutoVariadoAr = this.modalProdutoVariadoAr.map(a => {
        a.show = 1
        return a
      })
      this.modalProdutoVariadoKeys.forEach((b, ind) => {
        let search = b.toString().trim()
        if (search.length) {
          this.modalProdutoVariadoAr.forEach((a, i) => {
            if (a.value[ind].toUpperCase().indexOf(search.toUpperCase()) === -1) {
              this.modalProdutoVariadoAr[i].show = 0
            }
          })
        }
      })
    },
    selArtigo (dt, codBarras) {
      this.item = ''
      if (!dt) {
        return
      }
      // quando adiciono uma nova linha de um produto que existe no documento pergunta o que fazer
      /*
          let self = this
          let u = -1
          let i = self.form.produtos.length
          while (--i >= 0) {
            if (self.form.produtos[i].item_id === dt.id) {
              u = i
              break
            }
          }
          if (u >= 0) {
            this.$swal({
              title: dt.descricao,
              text: self.l('artigo_adicionado'),
              showCancelButton: true,
              confirmButtonText: self.l('juntar'),
              cancelButtonText: self.l('cancelar_juntar'),
              type: 'question'
            }).then(function (result) {
              if (result.value) {
                FOCO ['D' + u]
              } else {
                self.selArtigo1(dt)
              }
            })
          } else {
            self.selArtigo1(dt)
          }
      */
      if (dt.variavel) {
        this.SET_LOAD(true)
        itensId(this.axios, dt.id, this.$store.state.modulo).then((res) => {
          if (res.data.variacoes.length) {
            console.log('variacoes')
            this.modalProdutoVariadoNome = dt.descricao
            this.modalProdutoVariadoAr = []
            this.modalProdutoVariadoDT = dt
            this.modalProdutoVariadoKeys = []
            res.data.variacoes.forEach((a) => {
              a.data = JSON.parse(a.data)
              if (a.data.value.length > this.modalProdutoVariadoKeys.length) {
                this.modalProdutoVariadoKeys = a.data.value.map(() => '')
                this.modalProdutoVariadoNames = a.data.name
              }
              let stock = a.stock.find(b => b.loja_id === this.$store.state.loja)
              this.modalProdutoVariadoAr.push({
                value: a.data.value,
                name: a.data.name,
                preco: a.preco_sem_iva > 0 ? a.preco_sem_iva : dt.preco_sem_iva,
                imposto: res.data.imposto.valor,
                show: 1,
                hash: a.hash,
                stock: stock ? parseFloat(stock.stock) : '',
                codigo_barras: a.codigo_barras
              })
            })
            this.modalProdutoVariadoAr.forEach(a => {
              while (a.value.length < this.modalProdutoVariadoKeys.length) {
                a.value.push('')
              }
            })
            if (this.PrecoIVA) {
              this.modalProdutoVariadoIva = true
            } else {
              this.modalProdutoVariadoIva = false
            }
            this.modalProdutoVariado = true
            if (codBarras && res.data.variacoes.find(a => a.codigo_barras.toString() === dt.search.toString())) {
              this.selArtigo1(dt, 1, 1, res.data.variacoes.findIndex(a => a.codigo_barras.toString() === dt.search.toString()))
            }
          } else {
            this.selArtigo1(dt, codBarras)
          }
          this.SET_LOAD()
        }, (er) => {
          this.SET_AVISO(er.response)
        })
      } else {
        dt.variacao = 0
        if (dt.produto_composto) {
          console.log('prod composto')
          let self = this
          let i = self.form.tipo_documento_tp
          if (self.page2 !== 'compras' && self.page2 !== 'orcamentos' && self.page2 !== 'encomendas' && self.page !== 'quebrastock' && !(self.page2 === 'faturas' && (i === 'SINIV' || i === 'ND' || i === 'NC')) && self.$store.state.stockNegativo && dt.movimenta_stock && !dt.servico) {
            if (parseFloat(0 + dt.stock) < 1) {
              console.log('nao tem stock 0')
              this.$swal(dt.descricao, self.l('nao_tem_stock'), 'error').then(function () {
                self.delyFoco('itens')
              })
              return
            }
          }
          let u = this.form.produtos.length
          i = 0
          let repetidos = []
          while (i < u) {
            if (this.form.produtos[i].id === dt.id) {
              repetidos.push(i)
            }
            ++i
          }
          let existe = 0
          let xx = repetidos.length
          if (xx) {
            let kk
            let xk = 0
            let uu = 0
            let totalComposicao = dt.composicao.length
            let contTotalComposicao = 0
            while (uu < xx) {
              kk = repetidos[uu]
              i = repetidos[uu]
              xk = 0
              contTotalComposicao = 0
              while (++kk < u && ++contTotalComposicao <= totalComposicao) {
                // tou no filho do produto composto
                if (this.form.produtos[kk].item_pai_id) {
                  // console.log(this.form.produtos[kk].item_id === dt.composicao[xk].item_id, parseFloat(this.form.produtos[kk].preco_unitario).toFixed(2) === parseFloat(dt.composicao[xk].preco_unitario).toFixed(2), parseFloat(this.form.produtos[kk].desconto_1) === parseFloat(dt.composicao[xk].desconto_1), parseFloat(this.form.produtos[kk].desconto_2) === parseFloat(dt.composicao[xk].desconto_2), parseFloat(this.form.produtos[kk].desconto_3) === parseFloat(dt.composicao[xk].desconto_3), parseFloat(this.form.produtos[kk].desconto_3) === parseFloat(dt.composicao[xk].desconto_3))
                  if (this.form.produtos[kk].item_id === dt.composicao[xk].item_id && parseFloat(this.form.produtos[kk].preco_unitario).toFixed(2) === parseFloat(dt.composicao[xk].preco_unitario).toFixed(2) && parseFloat(this.form.produtos[kk].desconto_1) === parseFloat(dt.composicao[xk].desconto_1) && parseFloat(this.form.produtos[kk].desconto_2) === parseFloat(dt.composicao[xk].desconto_2) && parseFloat(this.form.produtos[kk].desconto_3) === parseFloat(dt.composicao[xk].desconto_3) && parseFloat(this.form.produtos[kk].desconto_3) === parseFloat(dt.composicao[xk].desconto_3)) {
                    ++xk
                    existe = 1
                  } else {
                    existe = 0
                    break
                  }
                }
              }
              if (existe) {
                break
              }
              ++uu
            }
          }
          if (existe) {
            ++this.form.produtos[i].quantidade
            this.produto(this.form.produtos[i], i)
          } else {
            this.selArtigo1(dt, 0)
            i = dt.composicao.length - 1
            u = 0
            while (u <= i) {
              console.log(dt.composicao[u].imposto_id, dt.composicao[u].item.descricao)
              this.selArtigo1({ nome: dt.composicao[u].item.descricao, descricao: dt.composicao[u].item.descricao, codigo: dt.composicao[u].item.codigo, preco_sem_iva: dt.composicao[u].preco_unitario, preco_com_iva: 0, unidade_medida_id: 0, imposto_id: dt.composicao[u].imposto_id, id: dt.composicao[u].item_id, servico: dt.composicao[u].servico, principal: 1, movimenta_stock: dt.composicao[u].movimenta_stock, preco_unitario: '', item_pai_id: dt.item_id, quantidade: dt.composicao[u].quantidade, quantidade_def: dt.composicao[u].quantidade, desconto_1: parseFloat(dt.composicao[u].desconto_1), desconto_2: parseFloat(dt.composicao[u].desconto_2), desconto_3: parseFloat(dt.composicao[u].desconto_3), desconto_1Siva: '', desconto_1Civa: '' }, u === i ? codBarras : 0)
              ++u
            }
          }
        } else {
          this.selArtigo1(dt, codBarras)
        }
      }
    },
    selArtigo1 (dt, codBarras, variavel, indexVariavel) {
      this.modalProdutoVariado = false
      this._.delay(this.SET_SCROLL, 50)
      let self = this
      let i = self.form.tipo_documento_tp
      try {
        if (dt) {
          if (variavel) {
            if ((this.$store.state.utilizador.plano.plano.valor_mensal > 10 || this.$store.state.utilizador.plano.plano.valor_anual > 100) && dt.movimenta_stock && !dt.servico) {
              if (self.page2 !== 'compras' && self.page2 !== 'orcamentos' && self.page2 !== 'encomendas' && self.page !== 'quebrastock' && !(self.page2 === 'faturas' && (i === 'SINIV' || i === 'ND' || i === 'NC')) && !self.$store.state.stockNegativo) {
                console.log(dt.stock)
                if (parseFloat(0 + dt.stock) < 1) {
                  this.$swal(dt.descricao, self.l('nao_tem_stock'), 'error').then(function () {
                    self.delyFoco('itens')
                  })
                  return
                }
              }
            }
            dt.variacao = 1
            dt.variavel = 1
            dt.variavelAr = this.modalProdutoVariadoAr[indexVariavel]
            dt.hash = this.modalProdutoVariadoAr[indexVariavel].hash
            if (this.modalProdutoVariadoAr[indexVariavel].preco > 0 && this.page2 !== 'compras') {
              dt.preco_sem_iva = this.modalProdutoVariadoAr[indexVariavel].preco
            }
          }
          dt.item_id = dt.id
          dt.id1 = dt.id + '-' + Math.random()
          dt.preco_unitario = ''
          if (dt.preco_sem_iva > 0) {
            // dt.preco_unitario = round10(dt.preco_sem_iva, self.casasDecimais)
            dt.preco_unitario = parseFloat(dt.preco_sem_iva)
          }
          dt.unidade = Principal(self.unidades, dt.unidade_medida_id).simbolo
          if (self.ISENTO || ((self.contato.pais !== 'PT' && self.form.tipo_documento_tp !== 'guiaglobal') || self.form.tipo_documento_tp === 'FC' || self.form.tipo_documento_tp === 'GC')) {
            dt.imposto_id = 0
            dt.imposto = 0
            dt.motivo_isencao_id = dt.motivo_isencao_id || self.RAZOESISENCAO
          } else {
            if (dt.imposto_id) {
              dt.imposto = Principal(self.impostos, dt.imposto_id).valor
            } else {
              dt.imposto = 0
              dt.imposto_id = 0
              dt.motivo_isencao_id = dt.motivo_isencao_id || self.RAZOESISENCAO
            }
          }
          // faturas simplificadas nao podem ser isentas de iva
          if (self.form.tipo_documento_tp === 'FS' && (dt.imposto_id === 0 || !dt.imposto_id)) {
            dt.imposto_id = self.impostos[0].id
            dt.imposto = Principal(self.impostos, dt.imposto_id).valor
          }
          if (dt.servico) {
            if (self.page2 === 'compras') {
              dt.retencao = ''
            } else {
              if (self.form.cliente_retencao && self.RETENCAO) {
                dt.retencao = self.RETENCAO
              } else {
                dt.retencao = ''
              }
            }
          }
          
          if (self.PrecoIVA && dt.imposto_id) {
            // dt.preco_unitario_iva = round10((dt.preco_unitario || 0) * (dt.imposto / 100 + 1), self.casasDecimais)
            // dt.preco_unitario_iva = (dt.preco_unitario || 0) * (dt.imposto / 100 + 1)
            dt.preco_unitario_iva = parseFloat(dt.preco_com_iva) || parseFloat(dt.preco_sem_iva * (dt.imposto / 100 + 1)) || ''
          } else {
            dt.preco_unitario_iva = dt.preco_sem_iva
          }
          // começa com a quantidade a 1 o artigo
          if (!dt.quantidade) {
            dt.quantidade = 1
          }
          // dt.quantidade = ''
          dt.nome = dt.descricao
          if (self.ao.desconto_padrao > 0 && self.page2 !== 'guias' && self.page2 !== 'compras' && !dt.item_pai_id) {
            dt.desconto_1 = self.ao.desconto_padrao
          }
          if (self.page2 === 'compras' && !dt.item_pai_id && dt.desconto_compra > 0) {
            dt.desconto_1 = +dt.desconto_compra
          }

          /*
            dt.desconto_total = 0
            dt.total_iva = dt.preco_unitario * (dt.imposto / 100)
            if (dt.servico && self.RETENCAO > 0) {
              dt.retencao = self.RETENCAO
            } else {
              dt.retencao = 0
            }

            if (self.PrecoIVA === 1) {
              dt.total_iva = dt.preco_unitario * (dt.imposto / 100 + 1)
            } else {
              dt.total = dt.preco_unitario
            }
            let i = self.form.produtos.length

            self.form.produtos.push(dt)
          */
          if (self.page2 !== 'compras' && self.page2 !== 'orcamentos' && self.page !== 'quebrastock' && self.page2 !== 'encomendas' && !(self.page2 === 'faturas' && (i === 'SINIV' || i === 'ND' || i === 'NC')) && !self.$store.state.stockNegativo && dt.movimenta_stock && !dt.servico && !variavel) {
            console.log(dt.stock, dt)
            if (parseFloat(0 + dt.stock) < 1) {
              console.log('nao tem stock')
              this.$swal(dt.descricao, self.l('nao_tem_stock'), 'error').then(function () {
                self.delyFoco('itens')
              })
              return
            } else {
              // ja posso ter inserido uma unidade desse produto
              let xx = self.form.produtos.length
              let a = 0
              let aa
              let idExiste = ''
              while (--xx >= 0) {
                aa = self.form.produtos[xx].convertido || ''
                if (dt.item_id === self.form.produtos[xx].item_id && aa.indexOf('GT ') < 0 && aa.indexOf('GR ') < 0 && aa.indexOf('GC ') < 0 && aa.indexOf('GA ') < 0 && aa.indexOf('GD ') < 0 && aa.indexOf('GMD ') < 0) {
                  a += parseFloat(0 + self.form.produtos[xx].quantidade || 1)
                  if (self.form.produtos[xx].item_id === dt.item_id && parseFloat(self.form.produtos[xx].preco_unitario || 0) === parseFloat(dt.preco_sem_iva || 0) && codBarras && parseFloat(self.form.produtos[xx].desconto_1 || 0) === 0) {
                    idExiste = xx
                  }
                }
              }
              i = this.form.tipo_documento_tp
              if (i === 'ND' || i === 'NC' || i === 'VNC' || i === 'VND' || dt.variavel || dt.variacao) {
                idExiste = ''
              }
              idExiste = ''
              // isto para adicionar artigos repetidos na fatura
              if (a < parseFloat(0 + dt.stock) && !dt.variavel && !dt.variacao) {
                // ja adicionei esse produto somo 1 unidade
                if (idExiste !== '' && !dt.variavel) {
                  self.form.produtos[idExiste].quantidade = 1 + parseFloat(self.form.produtos[idExiste].quantidade || 0)
                  self.produto(dt)
                } else {
                  self.form.produtos.push(dt)
                  if (codBarras) {
                    self.produto(dt) // para focar nos itens
                  } else {
                    self.produto(dt, this.produtosLinha.findIndex(a => a[0].item_id === dt.item_id && parseFloat(a[0].preco_unitario) === parseFloat(dt.preco_sem_iva) && parseFloat(a[0].desconto_1 || 0) === 0), 'D')
                  }
                }
              } else {
                console.log('nao tem stock 32')
                self.$swal(dt.descricao, dt.stock > 0 ? self.l('quantidade_maior_que_em_stock').replace('#quant', dt.stock || 0).replace('#und', dt.unidade) : self.l('nao_tem_stock'), 'error').then(function () {
                  // _.delay(self.FOC, 200, ['itens'])
                })
                return
              }
            }
          } else {
            if (!dt.item_pai_id) {
              i = self.form.produtos.length
              if (codBarras) {
                while (--i >= 0) {
                  if (self.form.produtos[i].item_id === dt.item_id && parseFloat(self.form.produtos[i].preco_unitario) === parseFloat(dt.preco_sem_iva) && parseFloat(self.form.produtos[i].desconto_1 || 0) === 0 && !dt.variavel && !dt.variacao) {
                    self.form.produtos[i].quantidade = 1 + parseFloat(self.form.produtos[i].quantidade)
                    self.produto(dt)
                    return
                  }
                }
              }
              /*
              else {
                while (--i >= 0) {
                  if (self.form.produtos[i].item_id === dt.item_id && parseFloat(self.form.produtos[i].preco_unitario) === parseFloat(dt.preco_sem_iva) && parseFloat(self.form.produtos[i].desconto_1 || 0) === 0) {
                    self.form.produtos[i].quantidade = 1 + parseFloat(self.form.produtos[i].quantidade)
                    self.produto(dt, this.produtosLinha.findIndex(a => a[0].item_id === dt.item_id && parseFloat(a[0].preco_unitario) === parseFloat(dt.preco_sem_iva) && parseFloat(a[0].desconto_1 || 0) === 0), 'D')
                    return
                  }
                }
              }
              */
            }
            self.form.produtos.push(dt)
            if (codBarras) {
              self.produto(dt) // para focar nos itens
            } else {
              self.produto(dt, this.produtosLinha.length, 'D')
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    addArtigo (item) {
      this.criarFormExterno = true
      this.SET_HELP({
        tipo: 'artigo',
        valor: {
          artigo: item,
          contato: this.contato,
          PrecoIVA: this.PrecoIVA,
          viaturaoutra: this.ao.viaturaoutra,
          arredondamento: this.ao.arredondamento,
          sinal: this.sinal,
          dados_transporte: this.ao.dados_transporte,
          prazo_vencimento: this.ao.prazo_vencimento,
          data1: this.data1,
          data_carga: new Date(this.ao.data_carga),
          hora_carga: new Date('1900/01/01 ' + this.ao.hora_carga),
          data_descarga: new Date(this.ao.data_descarga),
          hora_descarga: new Date('1900/01/01 ' + this.ao.hora_descarga),
          form: this.form,
          time1: this.time1
        }
      })
      if (this.form.id > 0) {
        this.$router.push('/' + this.page + '/' + this.page1 + '/' + this.page2 + '/' + this.page3 + '/itens' + urlhashDocs(), () => {})
      } else {
        this.$router.push('/' + this.page + '/' + this.page1 + '/' + this.page2 + '/itens' + urlhashDocs(), () => {})
      }
    },
    erroArrendomantos () {
      if (this.PAIS === 'PT') {
        this.ao.arredondamento = (this.ao.arredondamento > 3) ? 3 : this.ao.arredondamento
      }
    },

    upSerie () {
      try {
        this.form.cae = Principal(this.series, this.form.serie_id, 1).cae
        this.PrecoIVA = Principal(this.series, this.form.serie_id).iva_incluido
        let i = this.form.produtos.length
        while (--i >= 0) {
          if (this.PrecoIVA) {
            // this.form.produtos[i].preco_unitario_iva = round10(this.form.produtos[i].preco_unitario * (this.form.produtos[i].imposto / 100 + 1), this.casasDecimais)
            this.form.produtos[i].preco_unitario_iva = this.form.produtos[i].preco_unitario * (this.form.produtos[i].imposto / 100 + 1)
          }
          this.produto(this.form.produtos[i], i)
        }
      } catch (e) {
        console.log(e)
      }
    },
    limparPrecosGuias () {
      let i = this.form.produtos.length
      while (--i >= 0) {
        this.form.produtos[i].preco_unitario_iva = 0
        this.form.produtos[i].preco_unitario = 0
        this.form.produtos[i].desconto_1 = 0
        this.form.produtos[i].desconto_2 = 0
        this.form.produtos[i].desconto_3 = 0
        this.produto(this.form.produtos[i], i)
      }
      this.contas()
    },
    euroPercFN (dt, index) {
      dt.desconto_2 = ''
      dt.desconto_3 = ''
      if (this.PrecoIVA) {
        if (dt.desconto_1Civa > dt.total_com_iva) {
          dt.desconto_1Civa = dt.total_com_iva
        }
        dt.desconto_1 = dt.desconto_1Civa * 100 / dt.total_com_iva
        dt.desconto_1Siva = dt.desconto_1Civa / (dt.imposto / 100 + 1)
      } else {
        if (dt.desconto_1Siva > dt.total_qp) {
          dt.desconto_1Civa = dt.total_qp
        }
        dt.desconto_1 = dt.desconto_1Siva * 100 / dt.total_qp
        dt.desconto_1Civa = dt.desconto_1Siva * (dt.imposto / 100 + 1)
      }
      this.ao.desconto_percentual = ''
      this.produto(dt, index)
    },
    calcularQuantidade (dt, index, id) {
      if (dt.quantidade.toString().indexOf('*')) {
        dt.quantidade = parseFloat(eval(v(id)))
        if (!dt.quantidade) {
          dt.quantidade = ''
        }
      }
      this.produto(dt, index)
    },
    produto (dt, index, foco, europerc) {
      try {
        if (dt.imposto_id > 0) {
          dt.imposto = Principal(this.impostos, dt.imposto_id).valor
          dt.motivo_isencao_id = ''
        } else {
          dt.imposto = 0
          if (!dt.motivo_isencao_id) {
            dt.motivo_isencao_id = this.RAZOESISENCAO
          }
        }

        if (this.PrecoIVA) {
          dt.preco_unitario = dt.preco_unitario_iva / (dt.imposto / 100 + 1)
        }

        let q = parseFloat(dt.quantidade) || 1
        if (dt.quantidade_max) {
          if (dt.quantidade_max < q) {
            dt.quantidade = dt.quantidade_max
            q = parseFloat(dt.quantidade)
          }
        }
        if (dt.produto_composto) {
          // isto é para meter as quantidads certas pela composicao
          let xt = this.form.produtos.length
          let xi = 0
          while (xi < xt) {
            if (dt.id1 === this.form.produtos[xi].id1) {
              break
            }
            ++xi
          }
          while (++xi < xt) {
            if (!this.form.produtos[xi].item_pai_id) {
              break
            }
            this.form.produtos[xi].quantidade = this.form.produtos[xi].quantidade_def * dt.quantidade
            this.produto(this.form.produtos[xi], xi)
          }
        }

        // verifica stock
        let i = this.form.tipo_documento_tp
        if (this.page2 !== 'compras' && this.page2 !== 'orcamentos' && this.page2 !== 'encomendas' && this.page !== 'quebrastock' && !(this.page2 === 'faturas' && (i === 'SINIV' || i === 'ND' || i === 'NC')) && !this.$store.state.stockNegativo && dt.movimenta_stock && !dt.servico) {
          // ja posso ter inserido uma unidade desse produto
          i = this.form.produtos.length
          let xx = i
          let a = 0
          let aa
          while (--xx >= 0) {
            aa = this.form.produtos[xx].convertido || ''
            if (dt.id === this.form.produtos[xx].id && aa.indexOf('GT ') < 0 && aa.indexOf('GR ') < 0 && aa.indexOf('GC ') < 0 && aa.indexOf('GA ') < 0 && aa.indexOf('GD ') < 0) {
              a += parseFloat(0 + this.form.produtos[xx].quantidade || 1)
            }
          }
          if (a > parseFloat(0 + dt.stock)) {
            let self = this
            this.$swal(dt.descricao, this.l('quantidade_maior_que_em_stock').replace('#quant', dt.stock || 0).replace('#und', dt.unidade), 'error').then(function () {
              self.delyFoco('D' + index)
            })
            a -= q
            dt.quantidade = dt.stock - a
            q = parseFloat(dt.quantidade)
          }
        }
        // adiciona os campos de numeros de serie
        if (dt.quantidade > 0 && this.empresa.numeros_de_serie) {
          if (!dt.serials) {
            dt.serials = []
          }
          if (dt.quantidade > dt.serials.length) {
            while (Math.ceil(dt.quantidade) > dt.serials.length) {
              dt.serials.push({ serial: '', serialFocus: false })
            }
          }
          if (dt.quantidade < dt.serials.length) {
            while (Math.ceil(dt.quantidade) !== dt.serials.length) {
              dt.serials.pop()
            }
          }
        }

        let v = parseFloat(dt.preco_unitario || 0)
        let d = parseFloat(dt.desconto_1) || 0
        if (!(d > 0)) {
          dt.desconto_2 = 0
        }
        let d2 = parseFloat(dt.desconto_2) || 0
        if (!(d > 0 && d2 > 0)) {
          dt.desconto_3 = 0
        }
        let d3 = parseFloat(dt.desconto_3) || 0
        let r = parseFloat(dt.retencao) || 0
        if (dt.preco_max && (this.form.tipo_documento_tp === 'NC' || this.form.tipo_documento_tp === 'VNC' || this.form.tipo_documento_tp === 'ND' || this.form.tipo_documento_tp === 'VND')) {
          if (dt.preco_max < v) {
            dt.preco_unitario = dt.preco_max
            if (this.PrecoIVA) {
              dt.preco_unitario_iva = dt.precoIva_max
            }
          }
        }

        v = parseFloat(dt.preco_unitario || 0)

        if (d > 100) {
          dt.desconto_1 = 100
          d = 100
        }

        if (d2 > 100) {
          dt.desconto_2 = 100
          d2 = 100
        }

        if (d3 > 100) {
          dt.desconto_3 = 100
          d3 = 100
        }

        let qp = q * v
        let d_ = qp * d / 100
        let d2_ = 0

        if (d > 0) {
          d2_ = (qp - d_) * d2 / 100
        }
        let d3_ = 0
        if (d2 > 0 && d > 0) {
          d3_ = (qp - d_ - d2_) * d3 / 100
        }

        let ddd = qp - d_ - d2_ - d3_
        dt.total_qp = qp
        dt.desconto_total = d_ + d2_ + d3_
        dt.total_iva = ddd * dt.imposto / 100
        dt.retencao1 = ddd * r / 100

        dt.total_com_iva = ddd * (dt.imposto / 100 + 1)
        dt.total = ddd

        if (europerc || this.euroPerc) {
          dt.desconto_1Siva = dt.desconto_total
          dt.desconto_1Civa = dt.desconto_total * (dt.imposto / 100 + 1)
        }

        // this.form.produtos.push(dt)
        this.contas(foco, index)
      } catch (e) {
        console.log(e)
      }
    },
    contas (foco, index) {
      try {
        let a = this.form.produtos
        let ii = this.form.produtos.length
        let i = 0
        let fimIvas = []
        let totalQP = 0
        let totalLiquido = 0
        let totalIva = 0
        let descontoTotal = 0
        let retencaoTotal = 0
        let totalDescIva = 0
        while (i < ii) {
          if (!a[i].produto_composto) {
            totalQP += parseFloat(a[i].total_qp > 0 ? a[i].total_qp : 0)
            totalLiquido += parseFloat(a[i].total > 0 ? a[i].total : 0)
            totalIva += parseFloat(a[i].total_iva > 0 ? a[i].total_iva : 0)
            retencaoTotal += parseFloat(a[i].retencao1 > 0 ? a[i].retencao1 : 0)
            descontoTotal += parseFloat(a[i].desconto_total > 0 ? a[i].desconto_total : 0)

            totalDescIva += parseFloat(a[i].desconto_1Civa > 0 ? a[i].desconto_1Civa : 0)

            if ((!totalQP || !totalLiquido) && a[i].preco_unitario > 0 && a[i].quantidade > 0 && a[i].desconto_1 < 100 && a[i].desconto_2 < 100 && a[i].desconto_3 < 100) {
              this.produto(a[i], index, foco)
              return
            }

            if (fimIvas[a[i].imposto]) {
              fimIvas[a[i].imposto] = { imposto: a[i].imposto, incidencia: fimIvas[a[i].imposto].incidencia + a[i].total, valor: fimIvas[a[i].imposto].valor + a[i].total_iva }
            } else {
              fimIvas[a[i].imposto] = { imposto: a[i].imposto, incidencia: a[i].total, valor: a[i].total_iva }
            }
          } else {
            // sou um produto composto
            let k = i
            let preco = 0
            let precoIva = 0
            let desc = 0
            while (a[++k]) {
              if (!a[k].item_pai_id) {
                break
              }
              preco += a[k].preco_unitario * a[k].quantidade
              precoIva += a[k].preco_unitario_iva * a[k].quantidade
              desc += a[k].desconto_total
            }
            a[i].preco_unitario = preco
            a[i].preco_unitario_iva = precoIva
            a[i].desconto_total = desc
          }
          ++i
        }
        i = fimIvas.length
        let x = []
        while (--i >= 0) {
          if (fimIvas[i]) {
            x.push(fimIvas[i])
          }
        }
        this.fimIvas = x
        this.totalQP = totalQP > 0 ? parseFloat(totalQP.toFixed(2)) : ''
        this.total_liquido = totalLiquido > 0 ? parseFloat(totalLiquido.toFixed(2)) : ''
        this.total_iva = totalIva > 0 ? totalIva : ''
        this.ao.desconto_total = descontoTotal > 0 ? parseFloat(descontoTotal.toFixed(2)) : ''
        this.retencao_total = retencaoTotal > 0 ? parseFloat(retencaoTotal.toFixed(2)) : ''

        this.ao.desconto_total_iva = totalDescIva > 0 ? parseFloat(totalDescIva.toFixed(2)) : ''

        if (foco) {
          this.delyFoco(foco + '-' + index + '-' + '0')
        }
        this.SET_LOAD()
      } catch (e) {
        console.log(e)
      }
    },
    selectViatura () {
      this.ao.viaturaoutra = ''
      this.ao.viatura = ''
      this.delyFoco('viatura')
    },
    discussaoSenhaFN () {
      let self = this
      this.$swal({
        title: this.l('password_discussao'),
        input: 'text',
        inputValue: self.form.password_discussao,
        showCancelButton: true,
        confirmButtonText: this.$t('confirmar'),
        cancelButtonText: this.$t('cancelar'),
        inputAttributes: {
          'maxlength': 10,
          'minlength': 2,
          'autocapitalize': 'off',
          'autocorrect': 'off'
        },
        allowOutsideClick: false
      }).then((pass) => {
        if (!pass.dismiss) {
          self.SET_LOAD(true)
          discussaoUp(self.axios, { documento_id: self.form.id, password_discussao: pass.value }, self.$store.state.modulo).then(() => {
            self.form.password_discussao = pass.value
            self.SET_TOAST({ msg: self.l('discussao_sucesso_SENHA') })
          }, (er) => {
            self.SET_AVISO(er.response)
          })
        }
      }).catch(self.$swal.noop)
    },
    gerarNovoCodigoFN () {
      // discussao
      if (this.DISCUSSAO && !this.tab && this.form.terminado) {
        let self = this
        this.$swal({
          title: self.$t('discussao.codigo_discussao'),
          text: self.$t('discussao.codigo_discussao_desc'),
          showCancelButton: true,
          confirmButtonText: self.$t('confirmar'),
          cancelButtonText: self.$t('cancelar'),
          allowOutsideClick: false
        }).then((value) => {
          if (value.value || value.value === '') {
            this.SET_LOAD(true)
            discussaoUp(this.axios, { documento_id: this.form.id, codigo_discussao: 1 }, this.$store.state.modulo).then(() => {
              this.SET_TOAST({ msg: this.l('discussao_sucesso_codigo') })
            }, (er) => {
              this.SET_AVISO(er.response)
            })
          }
        })
      }
    },
    upEstadoDiscussao () {
      if (this.form.terminado) {
        this.SET_LOAD(true)
        discussaoUp(this.axios, { documento_id: this.form.id, discussao: this.form.discussao }, this.$store.state.modulo).then(() => {
          this.SET_TOAST({ msg: this.l('discussao_sucesso_estado') })
        }, (er) => {
          this.SET_AVISO(er.response)
        })
      }
    },
    upNota () {
      // notas
      this.SET_LOAD(true)
      documentosNota(this.axios, { id: this.form.id, nota_documento: this.form.nota_documento }, this.$store.state.modulo).then(() => {
        this.SET_TOAST({ msg: this.l('nota_sucesso') })
      }, (er) => {
        this.SET_AVISO(er.response)
      })
    },
    delProjetos (dt, index) {
      projetosDelDoc(this.axios, { projecto_id: dt.id, documento_id: this.form.id }, this.$store.state.modulo).then(() => {
        let i = this.projetos.length
        while (--i >= 0) {
          if (this.projetos[i].id === dt.id) {
            this.projetos[i].sel = false
            break
          }
        }
        this.SelProjetos.splice(index, 1)
        this.SET_TOAST({ msg: this.l('projeto_sucesso_del') })
      }, (er) => {
        this.SET_AVISO(er.response)
      })
    },
    upProjetos (dt) {
      // projetos
      this.SET_LOAD(true)
      if (dt.sel) {
        projetosAddDoc(this.axios, { projecto_id: dt.id, documento_id: this.form.id }, this.$store.state.modulo).then(() => {
          this.SelProjetos.push(dt)
          this.SET_TOAST({ msg: this.l('projeto_sucesso_add') })
        }, (er) => {
          this.SET_AVISO(er.response)
        })
      } else {
        projetosDelDoc(this.axios, { projecto_id: dt.id, documento_id: this.form.id }, this.$store.state.modulo).then(() => {
          let i = this.SelProjetos.length
          while (--i >= 0) {
            if (this.SelProjetos[i].id === dt.id) {
              this.SelProjetos.splice(i, 1)
              break
            }
          }
          this.SET_TOAST({ msg: this.l('projeto_sucesso_del') })
        }, (er) => {
          this.SET_AVISO(er.response)
        })
      }
    },
    submit (estado) {
      this.isButtonDisabled = true
      if (estado === 0 || estado === 1) {
        this.form.terminado = estado
      }
      let list = document.querySelectorAll('tbody.linhaProduto > tr')
      let ar = []
      let dd
      for (let a of list) {
        ar.push(this.form.produtos.find(b => b.id1 === a.id))
      }
      this.form.produtos = ar
      try {
        if (!(this.form.id > 0)) {
          this.form.id = ''
        }
        this.form.loja_id = this.$store.state.loja
        if (this.form.tipo_documento_tp === 'DP') {
          if (this.form.gross_total > 0) {
            this.SET_LOAD(true)
            this.form.terminado = 1
            dd = new Date(this.data1)
            if (this.showTime1) {
              this.form.data = dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2) + ' '
              dd = new Date(this.time1)
              this.form.data += ('0' + dd.getHours()).slice(-2) + ':' + ('0' + dd.getMinutes()).slice(-2) + ':' + ('0' + dd.getSeconds()).slice(-2)
            } else {
              this.form.data = dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2) + ' ' + gethora(0, true)
            }
            pagamentoDevolucao(this.axios, this.form, this.$store.state.modulo).then((res) => {
              this.$router.push('/' + this.page + '/editar/' + this.page2 + '/' + res.data.id, () => {})
              this.SET_TOAST({ msg: this.l('sucesso') })
              this.isButtonDisabled = false
            }, (er) => {
              this.SET_AVISO(er.response)
              this.form.terminado = 0
              this.isButtonDisabled = false
            })
          } else {
            this.form.terminado = 0
            let sself = this
            this.$swal(sself.l('valor'), sself.l('tem_preencher_valor_devolver'), 'error').then(function () {
              sself.delyFoco('valor1')
            })
            this.isButtonDisabled = false
          }
        } else {
          if (this.form.tipo_documento_tp !== 'FS' && this.moradas.length) {
            if (this.form.morada1 === 'sede') {
              this.form.morada = this.contato.morada
              this.form.codigo_postal = this.contato.codigo_postal
              this.form.cidade = this.contato.cidade
              this.form.pais = this.contato.pais
            } else {
              let i = this.contato.moradas.length
              while (--i >= 0) {
                if (this.form.morada1 === this.contato.moradas[i].id) {
                  this.form.morada = this.contato.moradas[i].morada
                  this.form.codigo_postal = this.contato.moradas[i].codigo_postal
                  this.form.cidade = this.contato.moradas[i].cidade
                  this.form.pais = this.contato.moradas[i].pais
                  break
                }
              }
            }
          }
          let self = this
          if ((this.$store.state.utilizador.plano.plano.modulo_stock > 0 && this.page2 === 'compras') || this.page2 !== 'compras') {
            if ((this.PERMISSOES.compras > 0 && this.page2 === 'compras') || (this.PERMISSOES.vendas > 0 && this.page2 !== 'compras')) {
              if (this.$store.state.utilizador.plano.plano.limite_documentos > this.$store.state.utilizador.estatisticas.total_documentos) {
                try {
                  this.clonar = false
                  this.clonar1 = false
                  if (!this.clonar) {
                    this.SET_LOAD(true)
                    dd = new Date(this.data1)
                    if (this.showTime1) {
                      this.form.data = dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2) + ' '
                      dd = new Date(this.time1)
                      this.form.data += ('0' + dd.getHours()).slice(-2) + ':' + ('0' + dd.getMinutes()).slice(-2) + ':' + ('0' + dd.getSeconds()).slice(-2)
                    } else {
                      this.form.data = dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2) + ' ' + gethora(0, true)
                    }
                    // recibo
                    if (this.form.tipo_documento_tp === 'RC' || this.form.tipo_documento_tp === 'NL' || this.form.tipo_documento_tp === 'RFC') {
                      if ((this.PERMISSOES.pagamentos_gerir > 0 && this.page2 === 'compras') || (this.PERMISSOES.recibos_gerir > 0 && this.page2 !== 'compras')) {
                        let x = this.ao.docsrecibo.length
                        this.form.documentos = []
                        while (--x >= 0) {
                          if (this.ao.docsrecibo[x].valor > 0) {
                            this.ao.docsrecibo[x].total = parseFloat(this.ao.docsrecibo[x].valor) - parseFloat(this.ao.docsrecibo[x].desconto || 0)
                            this.ao.docsrecibo[x].total_desconto = this.ao.docsrecibo[x].desconto || 0
                            this.form.documentos.push(this.ao.docsrecibo[x])
                          }
                        }
                        if (this.form.documentos.length > 0) {
                          let data_cheque = new Date(this.form.data_cheque)
                          this.form.observacoes = (this.idCheque ? (this.form.n_cheque ? this.l('n_cheque') + ': ' + this.form.n_cheque + '\n' : '') + (this.form.data_cheque ? this.l('data_cheque') + ': ' + data_cheque.getFullYear() + '-' + ('0' + (data_cheque.getMonth() + 1)).slice(-2) + '-' + ('0' + data_cheque.getDate()).slice(-2) + '\n' : '') : '') + this.form.observacoes
                          pagamentosCriar(this.axios, this.form, this.$store.state.modulo).then((res) => {
                            if (this.contato.envio_automatico_documento && this.page2 !== 'compras' && this.page2 !== 'encomendas') {
                              if (/\S+@\S+\.\S+/.test(this.contato.email)) {
                                this.enviarMailFN(res.data)
                              }
                            }
                            this.isButtonDisabled = false
                            this.$router.push('/' + this.page + '/editar/' + this.page2 + '/' + res.data.id, () => {})
                            this.SET_TOAST({ msg: this.l('sucesso') })
                            window.scrollTo(0, 0)
                          }, (er) => {
                            this.SET_AVISO(er.response)
                            this.form.terminado = 0
                          })
                        } else {
                          this.isButtonDisabled = false
                          this.form.terminado = 0
                          this.SET_LOAD()
                          this.$swal('', this.l('nao_ta_a_pagar_nada'), 'info')
                        }
                      } else {
                        this.form.terminado = 0
                        this.isButtonDisabled = false
                        this.$swal('', this.$t('permissoes_acesso'), 'warning')
                      }
                    } else {
                      if ((this.PERMISSOES.compras_gerir > 0 && this.page2 === 'compras') || (this.page2 !== 'compras' && this.PERMISSOES.vendas_gerir > 0)) {
                        // saldo inicial
                        if ((this.form.tipo_documento_tp === 'SINIV' || this.form.tipo_documento_tp === 'SINIC') && this.form.gross_total > 0) {
                          this.form.net_total = this.form.gross_total
                          saldoinicial(this.axios, this.form, this.$store.state.modulo).then((res) => {
                            this.$router.push('/' + this.page + '/editar/' + this.page2 + '/' + res.data.id, () => {})
                            this.SET_TOAST({ msg: this.$t('contactos.saldo_sucesso') })
                            window.scrollTo(0, 0)
                            this.isButtonDisabled = false
                          }, (er) => {
                            this.form.terminado = 0
                            this.SET_AVISO(er.response)
                            this.isButtonDisabled = false
                          })
                          return
                        }

                        if (!this.form.produtos.length) {
                          this.$swal('', this.l('produtos_obrigatiorio'), 'error').then(function () {
                            self.delyFoco('itens')
                          })
                          this.SET_LOAD()
                          this.form.terminado = 0
                          this.isButtonDisabled = false
                          return
                        }
                        if (this.form.tipo_documento_tp === 'FR' || this.form.tipo_documento_tp === 'VFR') {
                          this.form.prazo_vencimento = this.form.data
                        } else {
                          this.form.prazo_vencimento = somarDias(this.ao.prazo_vencimento, this.data1) + ' 23:59:59'
                        }

                        if (this.ao.arredondamento > 0) {
                          if (!this.sinal) {
                            this.form.arredondamento = this.ao.arredondamento * -1 / 100
                          } else {
                            this.form.arredondamento = this.ao.arredondamento / 100
                          }
                        }
                        if (parseInt(this.ao.viatura) === -1) {
                          this.form.viatura = this.ao.viaturaoutra
                        } else {
                          this.form.viatura = this.ao.viatura
                        }
                        if ((this.form.tipo_documento_tp !== 'FS' && this.ao.dados_transporte) || this.form.tipo_documento_tp === 'guiaglobal') {
                          if (this.form.tipo_documento_tp !== 'guiaglobal') {
                            this.form.descarga_morada = this.ao.descarga_morada
                            this.form.descarga_codigo_postal = this.ao.descarga_codigo_postal
                            this.form.descarga_cidade = this.ao.descarga_cidade
                            this.form.descarga_pais = this.ao.descarga_pais

                            dd = new Date(this.ao.data_descarga)
                            this.form.data_descarga = dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2) + ' '
                            dd = new Date(this.ao.hora_descarga)
                            this.form.data_descarga += ('0' + dd.getHours()).slice(-2) + ':' + ('0' + dd.getMinutes()).slice(-2) + ':' + ('0' + dd.getSeconds()).slice(-2)
                          }
                          dd = new Date(this.ao.data_carga)
                          let dd1 = new Date(this.data1)
                          let dd2 = new Date(this.ao.hora_carga)
                          if (dd.getFullYear() === dd1.getFullYear() && dd.getMonth() === dd1.getMonth() && dd.getDate() === dd1.getDate() && dd2.getHours() + ':' + dd2.getMinutes() + ':' + dd2.getSeconds() < gethora(0, true)) {
                            dd1 = gethora(1, true)
                          } else {
                            dd1 = ('0' + dd2.getHours()).slice(-2) + ':' + ('0' + dd2.getMinutes()).slice(-2) + ':' + ('0' + dd2.getSeconds()).slice(-2)
                          }
                          this.form.data_carga = dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2) + ' ' + dd1
                          this.form.carga_morada = this.ao.carga_morada
                          this.form.carga_codigo_postal = this.ao.carga_codigo_postal
                          this.form.carga_cidade = this.ao.carga_cidade
                          this.form.carga_pais = this.ao.carga_pais

                          if (new Date(this.form.data_carga) >= new Date(this.form.data_descarga) && this.form.tipo_documento_tp !== 'guiaglobal') {
                            this.SET_LOAD()
                            this.$swal('', self.l('datas_des_carga'), 'info').then(() => {
                              self.delyFoco('datacarga')
                            }, () => {
                              self.delyFoco('datacarga')
                            })
                            this.form.terminado = 0
                            this.isButtonDisabled = false
                            return
                          }

                          if (((!this.ao.descarga_morada || !this.ao.descarga_cidade || !this.ao.descarga_codigo_postal) && this.form.tipo_documento_tp !== 'guiaglobal') || !this.ao.carga_morada || !this.ao.carga_cidade || !this.ao.carga_codigo_postal) {
                            this.SET_LOAD()
                            this.form.terminado = 0
                            this.isButtonDisabled = false
                            if (!self.ao.carga_morada) {
                              this.$swal('', self.l('erro_carga_morada'), 'info').then(() => {
                                self.delyFoco('carga_morada')
                              }, () => {
                                self.delyFoco('carga_morada')
                              })
                              return
                            }
                            if (!self.ao.carga_cidade) {
                              this.$swal('', self.l('erro_carga_cidade'), 'info').then(() => {
                                self.delyFoco('carga_cidade')
                              }, () => {
                                self.delyFoco('carga_cidade')
                              })
                              return
                            }
                            if (!self.ao.carga_codigo_postal) {
                              this.$swal('', self.l('erro_carga_codigo_postal'), 'info').then(() => {
                                self.delyFoco('carga_codigo_postal')
                              }, () => {
                                self.delyFoco('carga_codigo_postal')
                              })
                              return
                            }
                            if (this.form.tipo_documento_tp !== 'guiaglobal') {
                              if (!self.ao.descarga_morada) {
                                this.$swal('', self.l('erro_descarga_morada'), 'info').then(() => {
                                  self.delyFoco('descarga_morada')
                                }, () => {
                                  self.delyFoco('descarga_morada')
                                })
                                return
                              }
                              if (!self.ao.descarga_cidade) {
                                this.$swal('', self.l('erro_descarga_cidade'), 'info').then(() => {
                                  self.delyFoco('descarga_cidade')
                                }, () => {
                                  self.delyFoco('descarga_cidade')
                                })
                                return
                              }
                              if (!self.ao.descarga_codigo_postal) {
                                this.$swal('', self.l('erro_descarga_codigo_postal'), 'info').then(() => {
                                  self.delyFoco('descarga_codigo_postal')
                                }, () => {
                                  self.delyFoco('descarga_codigo_postal')
                                })
                                return
                              }
                            }
                            /*
                            this.$swal('', this.l('dados_descarga_obrigatorios'), 'info').then(() => {
                              if (this.form.tipo_documento_tp !== 'guiaglobal') {
                                if (!self.ao.descarga_cidade) {
                                  self.delyFoco('descarga_cidade')
                                }
                                if (!self.ao.descarga_codigo_postal) {
                                  self.delyFoco('descarga_codigo_postal')
                                }
                                if (!self.ao.descarga_morada) {
                                  self.delyFoco('descarga_morada')
                                }
                              }
                              if (!self.ao.carga_cidade) {
                                self.delyFoco('carga_cidade')
                              }
                              if (!self.ao.carga_codigo_postal) {
                                self.delyFoco('carga_codigo_postal')
                              }
                              if (!self.ao.carga_morada) {
                                self.delyFoco('carga_morada')
                              }
                            }, () => {
                              if (this.form.tipo_documento_tp !== 'guiaglobal') {
                                if (!self.ao.descarga_cidade) {
                                  self.delyFoco('descarga_cidade')
                                }
                                if (!self.ao.descarga_codigo_postal) {
                                  self.delyFoco('descarga_codigo_postal')
                                }
                                if (!self.ao.descarga_morada) {
                                  self.delyFoco('descarga_morada')
                                }
                              }
                              if (!self.ao.carga_cidade) {
                                self.delyFoco('carga_cidade')
                              }
                              if (!self.ao.carga_codigo_postal) {
                                self.delyFoco('carga_codigo_postal')
                              }
                              if (!self.ao.carga_morada) {
                                self.delyFoco('carga_morada')
                              }
                            })
                            */
                          }
                          if (this.ao.carga_codigo_postal.length !== 8 && this.ao.carga_pais === 'PT') {
                            this.SET_LOAD()
                            this.form.terminado = 0
                            this.isButtonDisabled = false
                            this.$swal('', self.l('erro_carga_codigo_postal_incompleto'), 'info').then(() => {
                              self.delyFoco('carga_codigo_postal')
                            }, () => {
                              self.delyFoco('carga_codigo_postal')
                            })
                            return
                          }
                          if (this.form.tipo_documento_tp !== 'guiaglobal') {
                            if (this.ao.descarga_codigo_postal.length !== 8 && this.ao.descarga_pais === 'PT') {
                              this.SET_LOAD()
                              this.form.terminado = 0
                              this.isButtonDisabled = false
                              this.$swal('', self.l('erro_descarga_codigo_postal_incompleto'), 'info').then(() => {
                                self.delyFoco('descarga_codigo_postal')
                              }, () => {
                                self.delyFoco('descarga_codigo_postal')
                              })
                              return
                            }
                          }
                        } else {
                          delete this.form.descarga_morada
                          delete this.form.descarga_codigo_postal
                          delete this.form.descarga_cidade
                          delete this.form.descarga_pais
                          delete this.form.data_descarga
                          delete this.form.data_carga
                          delete this.form.carga_morada
                          delete this.form.carga_codigo_postal
                          delete this.form.carga_cidade
                          delete this.form.carga_pais
                        }
                        if (this.form.data_carga === '0000-00-00 00:00:00' || this.form.data_descarga === '0000-00-00 00:00:00') {
                          this.form.data_carga = ''
                          this.form.data_descarga = ''
                        }
                        dd = this.form.produtos.length
                        while (--dd >= 0) {
                          this.form.produtos[dd].preco_unitario = this.form.produtos[dd].preco_unitario || 0
                          this.form.produtos[dd].quantidade = this.form.produtos[dd].quantidade || 0

                          if (this.form.produtos[dd].item_pai_id === 0) {
                            this.form.produtos[dd].item_pai_id = ''
                          }

                          if (this.form.tipo_documento_tp === 'GC' || this.form.tipo_documento_tp === 'FC') {
                            this.form.produtos[dd].imposto_id = 0
                            this.form.produtos[dd].motivo_isencao_id = this.razoesIsencaoIva.find(a => a.codigo === 'M99').id
                          }
                        }
                        let tpp = this.form.tipo_documento_tp

                        dd = this.form.produtos.length
                        let erro = 0
                        if (this.page2 === 'faturas' && this.form.tipo_documento_tp !== 'RC' && this.form.tipo_documento_tp !== 'NL') {
                          while (--dd >= 0) {
                            if (!(parseFloat(this.form.produtos[dd].preco_unitario) > 0 || (((parseFloat(this.form.produtos[dd].preco_unitario) > 0 && parseFloat(this.form.produtos[dd].desconto_1) === 100) || parseFloat(this.form.produtos[dd].desconto_2) === 100 || parseFloat(this.form.produtos[dd].desconto_3) === 100))) && !this.form.produtos[dd].produto_composto) {
                              erro = ++dd
                              break
                            }
                          }
                        }
                        if (this.form.tipo_documento_tp === 'guiaglobal') {
                          this.form.global = true
                          this.form.tipo_documento_id = this.$store.state.idDocumento['GT']
                          tpp = 'GT'
                        } else {
                          this.form.global = false
                        }
                        if (erro === 0) {
                          this.form.produtos.map(a => {
                            if (Array.isArray(a.serials)) {
                              let i = a.serials.length
                              while (--i >= 0) {
                                if (!a.serials[i].serial) {
                                  a.serials.splice(i, 1)
                                }
                              }
                            }
                          })
                          this.form.meta = {}

                          if (this.form.tipo_documento_tp === 'GMD') {
                            this.form.produtos = this.form.produtos.map((a) => {
                              a.imposto_id = a.item.iva_compra
                              a.preco_unitario = a.item.preco_custo
                              if (!(a.imposto_id > 0)) {
                                a.motivo_isencao_id = this.RAZOESISENCAO
                              }
                              return a
                            })
                          }

                          if (this.form.id) {
                            if (this.ao.desconto_financeiro > 0) {
                              this.form.desconto_financeiro = this.ao.desconto_financeiro
                            }
                            documentosUp(this.axios, this.form, this.$store.state.modulo).then((res) => {
                              this.isButtonDisabled = false
                              if (this.metas[0]) {
                                let images = new window.FormData()
                                let k = this.metas.length
                                let i = 0
                                while (i < k) {
                                  images.append('meta[' + i + '][meta_key]', this.metas[i].meta_key)
                                  images.append('meta[' + i + '][meta_value]', this.metas[i].option.valor || '')
                                  images.append('meta[' + i + '][documento_id]', res.data.id)
                                  ++i
                                }
                                metaAdMultiplos(this.axios, images, 'documentos', this.$store.state.modulo).then(() => {
                                  this.submitOk(res, tpp)
                                }, (er) => {
                                  this.SET_AVISO(er.response)
                                  this.submitEdit(res, tpp)
                                })
                              } else {
                                this.submitEdit(res, tpp)
                              }
                            }, (er) => {
                              this.form.terminado = 0
                              this.isButtonDisabled = false
                              if (this.form.global) {
                                this.form.tipo_documento_tp = 'guiaglobal'
                              }
                              this.SET_AVISO(er.response)
                            })
                          } else {
                            if (this.agendar) {
                              dd = new Date(this.ao.data_inicio)
                              this.form.data_inicio = dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2)
                              dd = new Date(this.ao.data_fim)
                              this.form.data_fim = dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2)
                              this.form.a_cada = this.ao.a_cada
                              this.form.frequencia = this.ao.frequencia
                              this.form.rascunho = this.ao.rascunho
                              this.form.enviar_email = this.ao.enviar_email
                              this.form.email_destino = this.ao.email_destino
                              this.form.template_id = this.ao.template_id
                              this.form.mensagem_email = this.ao.mensagem_email
                              agendamentoCriar(this.axios, this.form, this.$store.state.modulo).then((res) => {
                                window.localStorage.removeItem('documento')
                                this.$router.push('/documentos/agendamentos/ver/' + res.data.id, () => {})
                                this.SET_TOAST({ msg: this.l('sucessoGuardar') })
                                this.isButtonDisabled = false
                              }, (er) => {
                                this.form.terminado = 0
                                this.isButtonDisabled = false
                                if (this.form.global) {
                                  this.form.tipo_documento_tp = 'guiaglobal'
                                }
                                this.SET_AVISO(er.response)
                              })
                            } else {
                              // atualizar produtos de compra e descontos
                              if ((this.form.tipo_documento_tp === 'VFR' || this.form.tipo_documento_tp === 'VFS' || this.form.tipo_documento_tp === 'VFT') && !this.modalUpdatePrecosCompraConfirm && !this.modalUpdatePrecosCompra) {
                                let i = this.form.produtos.length
                                let ar = []
                                while (--i >= 0) {
                                  // tem que ser do tipo produto nao pode ser composto
                                  if (!this.form.produtos[i].produto_composto) {
                                    if (this.form.produtos[i].item) {
                                      this.form.produtos[i].preco_custo = this.form.produtos[i].item.preco_custo
                                    }
                                    if (parseFloat(this.form.produtos[i].preco_custo) !== parseFloat(this.form.produtos[i].preco_unitario)) {
                                      this.form.produtos[i].actualiza_preco = true
                                      ar.push(this.form.produtos[i])
                                    }
                                  }
                                }
                                if (ar.length) {
                                  this.modalUpdatePrecosCompraAr = ar
                                  this.modalUpdatePrecosCompra = true
                                  this.SET_LOAD()
                                  this.modalUpdatePrecosCompraCheck = true
                                  this.modalUpdatePrecosCompraIva = this.PrecoIVA
                                  window.scrollTo(0, 0)
                                  return
                                }
                              }
                              this.modalUpdatePrecosCompra = false
                              if (this.ao.desconto_financeiro > 0) {
                                this.form.desconto_financeiro = this.ao.desconto_financeiro
                              }
                              documentosCriar(this.axios, this.form, this.$store.state.modulo).then((res) => {
                                this.isButtonDisabled = false
                                if (this.form.tipo_documento_tp === 'NC' || this.form.tipo_documento_tp === 'VNC') {
                                  this.servePagamentoFN(res.data.id)
                                }
                                if (this.metas[0]) {
                                  let images = new window.FormData()
                                  let k = this.metas.length
                                  let i = 0
                                  while (i < k) {
                                    images.append('meta[' + i + '][meta_key]', this.metas[i].meta_key)
                                    images.append('meta[' + i + '][meta_value]', this.metas[i].option.valor || '')
                                    images.append('meta[' + i + '][documento_id]', res.data.id)
                                    ++i
                                  }
                                  metaAdMultiplos(this.axios, images, 'documentos', this.$store.state.modulo).then(() => {
                                    this.submitOk(res, tpp)
                                  }, (er) => {
                                    this.SET_AVISO(er.response)
                                    this.submitOk(res, tpp)
                                  })
                                } else {
                                  this.submitOk(res, tpp)
                                }
                              }, (er) => {
                                this.isButtonDisabled = false
                                this.form.terminado = 0
                                if (this.form.global) {
                                  this.form.tipo_documento_tp = 'guiaglobal'
                                }
                                this.SET_AVISO(er.response)
                              })
                            }
                          }
                        } else {
                          this.form.terminado = 0
                          this.isButtonDisabled = false
                          this.SET_LOAD()
                          this.$swal('', this.l('preco_sem_valor'), 'info').then(function () {
                            if (self.PrecoIVA) {
                              self.delyFoco('C' + (--erro))
                            } else {
                              self.delyFoco('B' + (--erro))
                            }
                          }, function () {
                            if (self.PrecoIVA) {
                              self.delyFoco('C' + (--erro))
                            } else {
                              self.delyFoco('B' + (--erro))
                            }
                          })
                        }
                      } else {
                        this.form.terminado = 0
                        this.isButtonDisabled = false
                        this.$swal('', this.$t('permissoes_acesso'), 'warning')
                      }
                    }
                  } else {
                    this.form.terminado = 0
                    this.isButtonDisabled = false
                    this.$swal('', this.$t('permissoes_acesso'), 'warning')
                  }
                } catch (e) {
                  this.form.terminado = 0
                  this.isButtonDisabled = false
                  console.log(e)
                  this.SET_LOAD()
                }
              } else {
                this.form.terminado = 0
                this.isButtonDisabled = false
                this.SET_LOAD()
                this.$swal('', this.$t('limite_plano'), 'warning')
                this.SET_LOAD()
              }
            } else {
              this.form.terminado = 0
              this.isButtonDisabled = false
              this.SET_LOAD()
              this.$swal('', this.$t('permissoes_acesso'), 'warning')
            }
          } else {
            this.form.terminado = 0
            this.isButtonDisabled = false
            this.SET_LOAD()
            this.$swal('', this.$t('nao_suporta_compras'), 'warning')
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    modalUpdatePrecosCompraCheckFN () {
      this.form.produtos = this.form.produtos.map(a => {
        a.actualiza_preco = this.modalUpdatePrecosCompraCheck
        return a
      })
    },
    submitEdit (res, tpp) {
      window.localStorage.removeItem('documento')
      if (this.form.terminado === 1 && this.empresa.username_at && (tpp === 'GT' || tpp === 'GR' || tpp === 'GC' || tpp === 'GA' || tpp === 'GD') && !this.$store.state.Demo && this.PERMISSOES.comunicar_documentos_at > 0) {
        if (this.PERMISSOES.comunicar_documentos_at > 0) {
          ATAutomatic(this.axios, 'guia', res.data.id, this.$store.state.modulo).then(() => {
            this.$router.replace('/' + this.page + '/editar/' + this.page2 + '/' + res.data.id, () => {})
            this.SET_TOAST({ msg: this.l('sucessoguia') })
          }, () => {
            this.$swal('', this.l('erroguia'), 'error')
            this.$router.replace('/' + this.page + '/editar/' + this.page2 + '/' + res.data.id, () => {})
          })
        } else {
          this.$router.replace('/' + this.page + '/editar/' + this.page2 + '/' + res.data.id, () => {})
          this.$swal('', this.l('erroguia_sem_permissoes'), 'error')
        }
      } else {
        this.$router.replace('/' + this.page + '/editar/' + this.page2 + '/' + res.data.id, () => {})
        if (this.form.terminado === 1) {
          if (tpp === 'GT' || tpp === 'GR' || tpp === 'GC' || tpp === 'GA' || tpp === 'GD') {
            this.$swal('', this.l('erro_sem_dados_at'), 'info')
          } else {
            this.SET_TOAST({ msg: this.l('sucesso') })
            // envia por mail o documento automaticamente apos finalizado
            if (this.contato.envio_automatico_documento && this.page2 !== 'compras' && this.page2 !== 'encomendas') {
              if (/\S+@\S+\.\S+/.test(this.contato.email)) {
                this.enviarMailFN(res.data)
              }
            }
          }
        } else {
          this.SET_TOAST({ msg: this.l('sucessoEditar') })
        }
      }
    },
    submitOk (res, tpp) {
      window.localStorage.removeItem('documento')
      this.SET_LOAD(true)
      if (this.form.terminado === 1 && this.empresa.username_at && (tpp === 'GT' || tpp === 'GR' || tpp === 'GC' || tpp === 'GA' || tpp === 'GD') && !this.$store.state.Demo && this.PERMISSOES.comunicar_documentos_at > 0) {
        if (this.PERMISSOES.comunicar_documentos_at > 0) {
          ATAutomatic(this.axios, 'guia', res.data.id, this.$store.state.modulo).then(() => {
            if (this.contato.envio_automatico_documento && this.page2 !== 'compras' && this.page2 !== 'encomendas') {
              if (/\S+@\S+\.\S+/.test(this.contato.email)) {
                this.enviarMailFN(res.data)
              }
            }
            this.$router.push('/' + this.page + '/editar/' + this.page2 + '/' + res.data.id, () => {})
            this.SET_TOAST({ msg: this.l('sucessoguia') })
          }, () => {
            this.$swal('', this.l('erroguia'), 'error')
            this.$router.push('/' + this.page + '/editar/' + this.page2 + '/' + res.data.id, () => {})
          })
        } else {
          this.$router.push('/' + this.page + '/editar/' + this.page2 + '/' + res.data.id, () => {})
          this.$swal('', this.l('erroguia_sem_permissoes'), 'error')
        }
      } else {
        if (this.page === 'quebrastock') {
          this.$router.push('/' + this.page + '/editar/' + res.data.id, () => {})
        } else {
          this.$router.push('/' + this.page + '/editar/' + this.page2 + '/' + res.data.id, () => {})
        }
        if (this.form.terminado === 1) {
          if (tpp === 'GT' || tpp === 'GR' || tpp === 'GC' || tpp === 'GA' || tpp === 'GD') {
            this.$swal('', this.l('erro_sem_dados_at'), 'info')
          } else {
            this.SET_TOAST({ msg: this.l('sucesso') })
            // envia por mail o documento automaticamente apos finalizado

            if (this.contato.envio_automatico_documento && this.page2 !== 'compras' && this.page2 !== 'encomendas' && this.page !== 'quebrastock') {
              if (/\S+@\S+\.\S+/.test(this.contato.email)) {
                this.enviarMailFN(res.data)
              }
            }
          }
        } else {
          this.SET_TOAST({ msg: this.l('sucessoGuardar') })
        }
      }
    },
    enviarMailFN (dt) {
      /*
      enviarmail(this.axios, {id: data.id, email: this.contato.email}).then((res) => {
        this.SET_TOAST({msg: this.l('envio_mail_sucesso')})
      }, (er) => {
        this.SET_TOAST({msg: this.l('envio_mail_erro'), theme: 'danger'})
      })
      */
      let self = this
      if (self.$store.state.Demo) {
        this.$swal('', self.$t('conta_demo'), 'info')
      } else {
        let i = this.$store.state.template.length
        if (this.PLANO120 && i && this.$store.state.utilizador.empresa.smtp_privado) {
          let templates = []
          while (--i >= 0) {
            templates.unshift('<option value="' + this.$store.state.template[i].id + '" ' + (i ? '' : 'selected="selected"') + '>' + this.$store.state.template[i].nome + '</option>')
          }
          templates = templates.join('')
          let form = '<div class="form-material"><div class="input-group"><span class="input-group-addon">' + self.$t('email') + '</span><input autocomplete="off" type="email" id="s_email" value="' + self.contato.email + '" class="form-control"></div></div>' +
          '<div class="form-material"><div class="input-group"><span class="input-group-addon">' + self.$t('documentos.nome') + '</span><select id="s_nome" class="form-control">' + templates + '</select></div></div>' +
          '<div class="form-material"><div class="input-group"><span class="input-group-addon">' + self.$t('documentos.mensagem') + '</span><textarea rows="5" id="s_mensagem" class="form-control"></textarea></div></div>'
          this.$swal({
            title: self.l('enviar_documento'),
            html: form,
            showCancelButton: true,
            confirmButtonText: self.$t('confirmar'),
            cancelButtonText: self.$t('cancelar'),
            allowOutsideClick: false
          }).then(function (value) {
            if (value.value) {
              if (v('s_nome') && v('s_email')) {
                let emails = v('s_email').replace(/,/g, ';').split(';')
                while (--i >= 0) {
                  if (/\S+@\S+\.\S+/.test(emails[i])) {
                    self.SET_LOAD(true)
                    enviarmail(self.axios, { id: dt.id, email: emails[i], mensagem: v('s_mensagem'), template_id: v('s_nome') }, self.$store.state.modulo).then(() => {
                      self.SET_TOAST({ msg: self.l('sucEmail') })
                    }, () => {
                      self.SET_TOAST({ msg: self.l('erroEmail'), type: 'error' })
                      self._.delay(self.enviarMailFN, 3000, dt)
                    })
                  }
                }
              } else {
                self.SET_TOAST({ msg: self.l('erroEmailRequired'), type: 'error' })
                self._.delay(self.enviarMailFN, 3000, dt)
              }
            }
          })
        } else {
          let emails = this.contato.email.replace(/,/g, ';').split(';')
          i = emails.length
          while (--i >= 0) {
            if (/\S+@\S+\.\S+/.test(emails[i])) {
              this.SET_LOAD(true)
              enviarmail(this.axios, { id: dt.id, email: emails[i] }, this.$store.state.modulo).then(() => {
                this.SET_TOAST({ msg: this.l('envio_mail_sucesso') })
              }, () => {
                this.SET_TOAST({ msg: this.l('envio_mail_erro'), theme: 'danger' })
              })
            }
          }
        }
      }
    },
    defineForm () {
      let a = window.location.pathname.split('/')
      if (a[4] === 'contactos' || a[5] === 'contactos' || a[4] === 'itens' || a[5] === 'itens') {
        this.criarFormExterno = true
      } else {
        this.criarFormExterno = false
      }
      this.tab = ''
      if (this.NOTAS && !this.DISCUSSAO) {
        this.tab = 'notas'
      } else {
        if (this.PROJETOS) {
          this.tab = 'projetos'
        }
      }
      /*
      if (this.form.terminado < 1) {
        this.form.comentarios = []
        this.form.discussao = this.DISCUSSAO
        this.form.codigo_discussao = ''
        this.form.password_discussao = ''
      }
      */
    },

    // recibo contas
    recboVal (dt) {
      if (dt.valor > 0) {
        dt.valor = ''
        dt.pagar = ''
      } else {
        dt.valor = dt.total_divida
        dt.pagar = dt.total_divida
      }
      dt.desconto = ''
      this.valormax(dt)
    },
    recboValAll () {
      for (let key in this.ao.docsrecibo) {
        if (!this.allRecibos) {
          this.ao.docsrecibo[key].valor = this.ao.docsrecibo[key].total_divida
          this.ao.docsrecibo[key].pagar = this.ao.docsrecibo[key].total_divida
        } else {
          this.ao.docsrecibo[key].valor = ''
          this.ao.docsrecibo[key].pagar = ''
        }
        this.ao.docsrecibo[key].desconto = ''
      }
      this.valormaxContas()
    },
    valormax (dt) {
      if (parseFloat(dt.total_divida) < parseFloat(dt.valor)) {
        dt.valor = parseFloat(dt.total_divida)
      }
      dt.pagar = dt.valor
      this.valormaxContas(dt)
    },
    valormaxDesc (dt) {
      if (parseFloat(dt.valor) < parseFloat(dt.desconto)) {
        dt.desconto = parseFloat(dt.valor)
      }
      this.valormaxContas()
    },
    valormaxContas () {
      this.ao.total_recibo = 0
      this.ao.total_desconto = 0
      let i = this.ao.docsrecibo.length
      while (--i >= 0) {
        if (parseFloat(this.ao.docsrecibo[i].valor) > 0) {
          this.ao.total_recibo += parseFloat(this.ao.docsrecibo[i].valor)
        }
        if (parseFloat(this.ao.docsrecibo[i].desconto) > 0) {
          this.ao.total_desconto += parseFloat(this.ao.docsrecibo[i].desconto)
        }
      }
      if (!(this.ao.total_recibo > 0)) {
        this.ao.total_recibo = ''
      }
      if (!(this.ao.total_desconto > 0)) {
        this.ao.total_desconto = ''
      }
    },
    valortotalpagar () {
      if (parseFloat(this.ao.total_recibo) > parseFloat(this.credito)) {
        this.ao.total_recibo = this.credito
      }
      let i = this.ao.docsrecibo.length
      if (this.ao.total_recibo > 0) {
        let u = 0
        let total = parseFloat(this.ao.total_recibo)
        let kk = 0
        while (u < i) {
          this.ao.docsrecibo[u].valor = ''
          this.ao.docsrecibo[u].desconto = ''
          if (total > 0 && !kk) {
            if (this.ao.docsrecibo[u].total_divida <= total) {
              this.ao.docsrecibo[u].valor = round10(this.ao.docsrecibo[u].total_divida, -2)
              total -= this.ao.docsrecibo[u].valor
            } else {
              this.ao.docsrecibo[u].valor = round10(total, -2)
              kk = 1
            }
          }
          ++u
        }
        if (parseFloat(this.ao.total_recibo.toString().split('.')[1]) > 99) {
          this.ao.total_recibo = round10(this.ao.total_recibo, -2)
        }
        if (!(this.ao.total_recibo > 0)) {
          this.ao.total_recibo = ''
          this.ao.total_desconto = ''
        }
      } else {
        while (--i >= 0) {
          this.ao.docsrecibo[i].valor = ''
          this.ao.docsrecibo[i].desconto = ''
        }
      }
    },
    /*
    valortotalpagarDesconto () {
      if (this.ao.total_desconto > this.ao.total_recibo) {
        this.ao.total_desconto = this.ao.total_recibo
      }
      if (!(this.ao.total_desconto > 0)) {
        this.ao.total_desconto = ''
      }
     this.ao.total_receber =  parseFloat(this.ao.total_recibo) - parseFloat(this.ao.total_desconto)
    },
    */
    converterDocsInteiros (ar, fat) {
      if (ar.length) {
        this.SET_LOAD(true)
        // this.form.serie_id = res.data[key][u].serie_id
        movimentosPendentesMultiplos(this.axios, { id: ar }, this.$store.state.modulo).then((res) => {
          try {
            let u
            let uu
            let x
            let serie
            let idsProdutos = []
            let self = this
            let temPreco = false
            if (res.data.length === 0) {
              this.$swal('', this.l('nao_tem_nada_a_converter'), 'info').then(function () {
                self.delyFoco('itens')
              })
            } else {
              for (let key in res.data) {
                uu = res.data[key].length
                u = 0
                // let k
                if (this.clonar && !this.documents.length) {
                  serie = fat
                } else {
                  x = this.documents.length
                  while (--x >= 0) {
                    if (parseInt(this.documents[x].origem) === parseInt(key)) {
                      serie = this.documents[x].origem_documento.invoice_number
                      break
                    }
                  }
                }
                let movimentaStock = false
                let quantidadeDef
                while (u < uu) {
                  x = serie.toString().substring(0, 3)

                  res.data[key][u].lancamento_original.quantidade = parseFloat(res.data[key][u].lancamento_original.quantidade - res.data[key][u].quantidade_movida)
                  res.data[key][u].lancamento_original.quantidade_max = res.data[key][u].lancamento_original.quantidade

                  if (res.data[key][u].lancamento_original.produto_composto) {
                    quantidadeDef = res.data[key][u].lancamento_original.quantidade
                  }
                  if (res.data[key][u].lancamento_original.item_pai_id) {
                    res.data[key][u].lancamento_original.quantidade_def = res.data[key][u].lancamento_original.quantidade / quantidadeDef
                  }
                  if (!(x === 'ORC' && this.$route.query.i === 'FPF')) {
                    res.data[key][u].lancamento_original.convertido = serie
                    res.data[key][u].lancamento_original.lancamento_pai_id = res.data[key][u].lancamento_original.id
                  }
                  res.data[key][u].lancamento_original.codigo = res.data[key][u].lancamento_original.item.codigo
                  res.data[key][u].lancamento_original.unidade = res.data[key][u].lancamento_original.item.unidade_medida ? res.data[key][u].lancamento_original.item.unidade_medida.simbolo : ''
                  res.data[key][u].lancamento_original.servico = res.data[key][u].lancamento_original.item.servico
                  res.data[key][u].lancamento_original.id = res.data[key][u].lancamento_original.item.id
                  if (!(res.data[key][u].lancamento_original.retencao > 0)) {
                    res.data[key][u].lancamento_original.retencao = 0
                  }
                  if (res.data[key][u].lancamento_original.preco_unitario > 0) {
                    res.data[key][u].lancamento_original.preco_unitario = parseFloat(res.data[key][u].lancamento_original.preco_unitario)
                  } else {
                    if (this.page2 === 'compras' && res.data[key][u].lancamento_original.item.produto_composto) {
                      res.data[key][u].lancamento_original.preco_unitario = res.data[key][u].lancamento_original.item.preco_custo
                    } else {
                      if (res.data[key][u].lancamento_original.item.precos.length === 1) {
                        res.data[key][u].lancamento_original.preco_unitario = res.data[key][u].lancamento_original.item.precos[0].preco_sem_iva
                      } else {
                        if ((res.data[key][u].lancamento_original.item.precos.length && !res.data[key][u].lancamento_original.item_pai_id)) {
                          temPreco = true
                        }
                      }
                    }
                  }
                  if (res.data[key][u].lancamento_original.imposto_id > 0) {
                    res.data[key][u].lancamento_original.imposto = Principal(this.impostos, res.data[key][u].lancamento_original.imposto_id).valor
                  } else {
                    res.data[key][u].lancamento_original.imposto = 0
                    if (res.data[key][u].lancamento_original.motivo_isencao_id === '') {
                      res.data[key][u].lancamento_original.motivo_isencao_id = this.RAZOESISENCAO
                    }
                  }

                  res.data[key][u].lancamento_original.desconto_1 = parseFloat(res.data[key][u].lancamento_original.desconto_1)
                  res.data[key][u].lancamento_original.desconto_2 = res.data[key][u].lancamento_original.desconto_1 > 0 ? parseFloat(res.data[key][u].lancamento_original.desconto_2) : 0
                  res.data[key][u].lancamento_original.desconto_3 = res.data[key][u].lancamento_original.desconto_1 > 0 && res.data[key][u].lancamento_original.desconto_2 > 0 ? parseFloat(res.data[key][u].lancamento_original.desconto_3) : 0

                  res.data[key][u].lancamento_original.id1 = res.data[key][u].lancamento_original.item_id + '-' + Math.random()

                  // res.data[key][u].lancamento_original.preco_unitario_iva = round10(res.data[key][u].lancamento_original.preco_unitario * (res.data[key][u].lancamento_original.imposto / 100 + 1), this.casasDecimais)
                  res.data[key][u].lancamento_original.preco_unitario_iva = res.data[key][u].lancamento_original.preco_unitario * (res.data[key][u].lancamento_original.imposto / 100 + 1)

                  if (res.data[key][u].lancamento_original.preco_unitario > 0) {
                    res.data[key][u].lancamento_original.preco_max = res.data[key][u].lancamento_original.preco_unitario
                    res.data[key][u].lancamento_original.precoIva_max = res.data[key][u].lancamento_original.preco_unitario_iva
                  }
                  // estou a converter das compras os outros menus
                  /*
                  if (this.page2 !== 'compras' && serie.toString().substring(0, 1) === 'V') {
                    res.data[key][u].lancamento_original.lancamento_pai_id = ''
                    res.data[key][u].lancamento_original.convertido = ''
                    res.data[key][u].lancamento_original.quantidade_max = ''
                    res.data[key][u].lancamento_original.quantidade_manual = ''
                    res.data[key][u].lancamento_original.preco_max = ''
                    res.data[key][u].lancamento_original.precoIva_max = ''
                    res.data[key][u].lancamento_original.desconto_1 = ''
                    res.data[key][u].lancamento_original.desconto_2 = ''
                    res.data[key][u].lancamento_original.desconto_3 = ''
                    res.data[key][u].lancamento_original.preco_unitario = 0
                    res.data[key][u].lancamento_original.preco_unitario_iva = 0
                    if (res.data[key][u].lancamento_original.item.precos.length > 1 && this.page2 !== 'guias') {
                      temPreco = true
                    }
                    if (res.data[key][u].lancamento_original.item.imposto_id > 0) {
                      res.data[key][u].lancamento_original.imposto = Principal(this.impostos, res.data[key][u].lancamento_original.item.imposto_id).valor
                      res.data[key][u].lancamento_original.imposto_id = res.data[key][u].lancamento_original.item.imposto_id
                    } else {
                      res.data[key][u].lancamento_original.imposto = 0
                      res.data[key][u].lancamento_original.imposto_id = 0
                      if (res.data[key][u].lancamento_original.motivo_isencao_id === '') {
                        res.data[key][u].lancamento_original.motivo_isencao_id = this.RAZOESISENCAO
                      }
                    }
                    if (res.data[key][u].lancamento_original.descricao_extra) {
                      res.data[key][u].lancamento_original.descricao_extra1 = true
                    }
                    if (res.data[key][u].lancamento_original.serials) {
                      if (res.data[key][u].lancamento_original.serials.length) {
                        res.data[key][u].lancamento_original.serials1 = true
                      }
                    }
                  }
                  */

                  /*
                  // para converter notas de encomendas, orcamentos ou pro formas em compras
                  if (this.page2 === 'compras' && (x === 'NEN' || x === 'ORC' || x === 'FPF')) {
                    res.data[key][u].lancamento_original.imposto_id = res.data[key][u].lancamento_original.item.iva_compra
                    res.data[key][u].lancamento_original.imposto = Principal(this.impostos, res.data[key][u].lancamento_original.imposto_id).valor
                    res.data[key][u].lancamento_original.lancamento_pai_id = ''
                    res.data[key][u].lancamento_original.convertido = ''
                    res.data[key][u].lancamento_original.desconto_1 = ''
                    res.data[key][u].lancamento_original.desconto_2 = ''
                    res.data[key][u].lancamento_original.desconto_3 = ''
                    res.data[key][u].lancamento_original.quantidade_max = ''
                    res.data[key][u].lancamento_original.quantidade_manual = ''
                    res.data[key][u].lancamento_original.preco_max = ''
                    res.data[key][u].lancamento_original.precoIva_max = ''
                    res.data[key][u].lancamento_original.preco_unitario = res.data[key][u].lancamento_original.item.preco_custo || 0
                    res.data[key][u].lancamento_original.preco_unitario_iva = res.data[key][u].lancamento_original.item.preco_custo * (res.data[key][u].lancamento_original.imposto / 100 + 1) || 0
                  } else {
                  */
                  // converter documentos simplificados, nao podem ter exceçao de iva
                  if (this.form.tipo_documento_tp === 'FS' && !res.data[key][u].lancamento_original.imposto) {
                    res.data[key][u].lancamento_original.imposto_id = Principal(this.impostos).id
                    res.data[key][u].lancamento_original.imposto = Principal(this.impostos, res.data[key][u].lancamento_original.imposto_id).valor
                  }

                  if ((serie || '').indexOf('FC ') === 0) {
                    res.data[key][u].lancamento_original.imposto_id = res.data[key][u].lancamento_original.item.imposto_id
                    res.data[key][u].lancamento_original.imposto = Principal(this.impostos, res.data[key][u].lancamento_original.imposto_id).valor
                  }

                  if (res.data[key][u].lancamento_original.item.movimenta_stock) {
                    movimentaStock = true
                  }

                  // this.form1.produtos.push(res.data[key][u].lancamento_original)
                  if (temPreco) {
                    idsProdutos.push(res.data[key][u].lancamento_original.item)
                  }
                  // }

                  /*
                  if (res.data[key][u].lancamento_original.item.produto_composto) {
                    let xxk = res.data[key][u].lancamento_original.item.composicao.length
                    let xk = u
                    while (++xk < (xxk + u) && res.data[key][xk]) {
                      res.data[key][xk].lancamento_original.preco_unitario = res.data[key][u].lancamento_original.item.composicao[xxk].preco_unitario
                      res.data[key][xk].lancamento_original.preco_unitario_iva = 0
                      res.data[key][xk].lancamento_original.desconto_1 = res.data[key][u].lancamento_original.item.composicao[xxk].desconto_1
                      res.data[key][xk].lancamento_original.desconto_2 = res.data[key][u].lancamento_original.item.composicao[xxk].desconto_2
                      res.data[key][xk].lancamento_original.desconto_3 = res.data[key][u].lancamento_original.item.composicao[xxk].desconto_3
                    }
                  }
                  */

                  let qp = res.data[key][u].lancamento_original.quantidade * res.data[key][u].lancamento_original.preco_unitario_iva
                  let d_ = qp * res.data[key][u].lancamento_original.desconto_1 / 100
                  let d2_ = 0

                  if (res.data[key][u].lancamento_original.desconto_1 > 0) {
                    d2_ = (qp - d_) * res.data[key][u].lancamento_original.desconto_2 / 100
                  }
                  let d3_ = 0
                  if (res.data[key][u].lancamento_original.desconto_2 > 0 && res.data[key][u].lancamento_original.desconto_1 > 0) {
                    d3_ = (qp - d_ - d2_) * res.data[key][u].lancamento_original.desconto_3 / 100
                  }

                  let ddd = qp - d_ - d2_ - d3_
                  res.data[key][u].lancamento_original.total_qp = qp
                  res.data[key][u].lancamento_original.desconto_total = d_ + d2_ + d3_
                  res.data[key][u].lancamento_original.total_iva = ddd * res.data[key][u].lancamento_original.imposto / 100
                  res.data[key][u].lancamento_original.retencao1 = ddd * res.data[key][u].lancamento_original.retencao / 100

                  res.data[key][u].lancamento_original.total_com_iva = ddd * (res.data[key][u].lancamento_original.imposto / 100 + 1)
                  res.data[key][u].lancamento_original.total = ddd

                  if (res.data[key][u].lancamento_original.data_variacao) {
                    res.data[key][u].lancamento_original.variavel = 1
                    res.data[key][u].lancamento_original.variavelAr = JSON.parse(res.data[key][u].lancamento_original.data_variacao)
                  }

                  res.data[key][u].lancamento_original.converter = 1

                  console.log(res.data[key][u].lancamento_original)

                  this.form.produtos.push(res.data[key][u].lancamento_original)

                  temPreco = false
                  this.produto(this.form.produtos[u], u)
                  ++u
                }
                console.log(this.form.produtos)

                this.AtivarModalCompras = false
                this.AtivarModalComprasData = []
                u = serie.toString().substring(0, 3)
                if ((this.page2 !== 'compras' && (u === 'VFT' || u === 'VFR' || u === 'VFS' || u === 'VGR' || u === 'VGT')) || (this.page2 === 'compras' && (u === 'NEN' || u === 'ORC' || u === 'FPF'))) {
                  this.AtivarModalCompras = true
                  this.contacto = ''
                }

                // converter uma guia global para uma guia de descarga perguntar qual o cliente que pretende
                if (this.contato.codigo === 'guiaglobal' && !this.selectClient && this.form.tipo_documento_tp !== 'GMD') {
                  this.selectClient = true
                  this.$swal({
                    title: self.l('confirmar_cliente'),
                    showCancelButton: true,
                    confirmButtonText: self.l('outro'),
                    cancelButtonText: self.l('consumidor_final'),
                    allowOutsideClick: false,
                    type: 'question'
                  }).then(function (result) {
                    if (result.value) {
                      self.contato = ''
                      // self.rescontatos = []
                      // self.contacto = ''
                      self.form.contato_id = ''
                      self.item = ''
                      self.artigos = []
                      self.moradas = []
                      self.delyFoco('contactos')
                      if (idsProdutos.length) {
                        self.contacto = ''
                        self.AtivarModalComprasData = idsProdutos
                        self.AtivarModalCompras = true
                      }
                    } else {
                      if (idsProdutos.length) {
                        self.SET_CONVERTER_COMPRAS1(idsProdutos)
                      }
                      self.AtivarModalCompras = false
                    }
                  })
                } else {
                  // tou a fazer nota de credito ou vcredito e os produtos movimentam stock e preciso de saber o que fazer com eles
                  if (!this.form.retorna_stock && movimentaStock && (this.form.tipo_documento_tp === 'NC' || this.form.tipo_documento_tp === 'VNC') && fat.indexOf('ND ') !==0 && fat.indexOf('VND ') !==0) {
                    this.$swal({
                      title: self.l('titulo_do_que_faz_do_stock'),
                      text: self.l(self.form.tipo_documento_tp === 'VNC' ? 'movimenta_stock_vnota_credito' : 'movimenta_stock_nota_credito'),
                      showCancelButton: true,
                      confirmButtonText: self.$t('sim'),
                      cancelButtonText: self.$t('nao'),
                      allowOutsideClick: false,
                      type: 'question'
                    }).then(function (result) {
                      if (result.value) {
                        self.form.retorna_stock = true
                      } else {
                        self.form.retorna_stock = false
                      }
                      if (self.moradas.length) {
                        self.delyFoco('morada1')
                      } else {
                        self.delyFoco('motivo')
                      }
                    })
                  } else {
                    if (this.AtivarModalCompras) {
                      this.contato = ''
                      // this.rescontatos = []
                      // this.contacto = ''
                      this.form.contato_id = ''
                      this.item = ''
                      this.artigos = []
                      this.moradas = []
                      this.delyFoco('contactos')
                      if (idsProdutos.length) {
                        this.AtivarModalComprasData = idsProdutos
                      }
                      /*
                      this.$swal({
                        title: self.l('confirmar_cliente'),
                        showCancelButton: true,
                        confirmButtonText: self.l('outro'),
                        cancelButtonText: self.l('manter_mesmo_cliente'),
                        type: 'question'
                      }).then(function (result) {
                        if (result.value) {
                          self.contato = ''
                          self.rescontatos = []
                          self.contacto = ''
                          self.form.contato_id = ''
                          self.rescontatos = []
                          self.item = ''
                          self.artigos = []
                          self.moradas = []
                          this._.delay(self.FOC, 200, ['contactos'])
                            if (idsProdutos.length) {
                              self.AtivarModalComprasData = idsProdutos
                          }
                        } else {
                          if (idsProdutos.length) {
                            self.SET_CONVERTER_COMPRAS1(idsProdutos)
                          }
                          self.AtivarModalCompras = false
                        }
                      })
                      */
                    } else {
                      u = serie.toString().substring(0, 2)
                      if (u === 'GC' || u === 'GD' || u === 'GA' || u === 'GR' || u === 'GT') {
                        if (idsProdutos.length && this.form.tipo_documento_tp !== 'GMD') {
                          self.SET_CONVERTER_COMPRAS1(idsProdutos)
                        }
                      }
                    }
                  }
                }
              }
            }
          } catch (e) {
            console.log(e)
          }
          this.SET_LOAD()
          this.delyFoco('itens')
        }, (er) => {
          this.SET_AVISO(er.response)
        })
        this.meta()
      }
    },

    init () {
      this.ao.prazo_vencimento = 0
      this.ao.prazo_vencimento1 = ''
      this.sinal = false
      this.ao.viaturaoutra = ''
      this.ao.viatura = ''
      this.ao.arredondamento = 0
      this.montou = false
      this.ref = false
      this.contato = ''
      this.contacto = ''
      this.item = ''
      this.artigos = []
      this.ao.total_recibo = ''
      this.ao.total_desconto = ''
      this.ao.desconto_total = ''
      this.ao.desconto_percentual = ''
      this.AtivarModalCompras = false
      this.AtivarModalComprasData = []
      this.ao.frequencia = 'meses'
      this.ao.a_cada = 1
      this.ao.data_inicio = new Date()
      this.ao.data_fim = new Date(new Date().getFullYear() + '-12-31')
      this.ao.rascunho = false
      this.ao.enviar_email = ''
      this.ao.email_destino = ''
      this.ao.template_id = ''
      this.ao.mensagem_email = ''
      this.ao.docsrecibo = []
      this.ao.lojaGuia = 'contacto'
      this.ao.desconto_financeiro = 0

      return {
        discussao: this.$store.state.utilizador.empresa.discussao_em_documentos && (this.form.tipo_documento_tp === 'FT' || this.form.tipo_documento_tp === 'FS' || this.form.tipo_documento_tp === 'FR' || this.form.tipo_documento_tp === 'GT' || this.form.tipo_documento_tp === 'GR' || this.form.tipo_documento_tp === 'NENC' || this.form.tipo_documento_tp === 'ORC' || this.form.tipo_documento_tp === 'FPF' || this.form.tipo_documento_tp === 'VFT' || this.form.tipo_documento_tp === 'VFR' || this.form.tipo_documento_tp === 'VFS' || this.form.tipo_documento_tp === 'VGR' || this.form.tipo_documento_tp === 'VGT' || this.form.tipo_documento_tp === 'VNENC' || this.form.tipo_documento_tp === 'VORC' || this.form.tipo_documento_tp === 'VFPF'),
        codigo_discussao: '',
        password_discussao: '',
        tipo_documento_id: '',
        tipo_documento_tp: '',
        motivo: '',
        loja_id: '',
        produtos: [],
        viatura: '',
        contato_id: '',
        serie_id: '',
        moeda: 'EUR_€',
        cambio: '',
        arredondamento: '',
        prazo_vencimento: '',
        metodo_pagamento_id: '',
        metodo_expedicao_id: '',
        descarga_morada: '',
        descarga_codigo_postal: '',
        descarga_cidade: '',
        descarga_pais: '',
        carga_morada: '',
        carga_codigo_postal: '',
        carga_cidade: '',
        carga_pais: '',
        observacoes: '',
        id: 0,
        terminado: 0,
        documentos: [],
        lingua: 'pt',
        cae: '',
        documento_original: '',
        porto: '',
        retorna_stock: false,
        serials: [],
        n_cheque: '',
        data_cheque: '',
        divida: {},
        contato_divida: 0,
        contato_divida_vencida: 0
      }
    },
    /*
    valPostal (x) {
      try {
        let pais = !x ? this.ao.carga_pais : this.ao.descarga_pais
        if (pais === 'PT') {
          let pattern = /[0-9]{4}-[0-9]{3}/
          let codigoPostal = !x ? this.ao.carga_codigo_postal : this.ao.descarga_codigo_postal
          if (!pattern.test(codigoPostal)) {
            let self = this
            this.$swal('', this.$t('erro_codigoPostal'), 'error').then(function () {
              self.delyFoco(!x ? 'carga_codigo_postal' : 'descarga_codigo_postal')
            }, function () {
              self.delyFoco(!x ? 'carga_codigo_postal' : 'descarga_codigo_postal')
            })
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    */
    up_prazo_vencimento () {
      this.ao.prazo_vencimento1 = somarDias(this.ao.prazo_vencimento, this.data1)
    },
    referenciaManualUp (dt, index) {
      dt.referencia_manual = ''
      dt.referencia_manual1 = !dt.referencia_manual1
      this.produto(dt, index)
    },
    descricaoExtraUp (dt, index) {
      dt.descricao_extra = ''
      dt.descricao_extra1 = !dt.descricao_extra1
      if (dt.descricao_extra1) {
        this.delyFoco('A' + index)
      }
      this.produto(dt, index)
    },
    serialsUp (dt, index) {
      try {
        if (!dt.quantidade) {
          dt.quantidade = 1
        }
        dt.serials1 = !dt.serials1
        if (!dt.serials1) {
          if (!dt.serials) {
            dt.serials = []
          }
          let i = dt.serials.length
          while (--i >= 0) {
            dt.serials.push({ serial: '', serialFocus: false })
          }
        } else {
          this.delyFoco('Z' + index + '-0')
        }
        this.produto(dt, index)
      } catch (e) {
        console.log(e)
      }
    },
    LimparSerial (serie, dt, index) {
      serie.serial = ''
      this.produto(dt, index)
    },
    searchNumSerie () {
      try {
        if (this.form.terminado < 1 && this.page2 !== 'compras' && this.page2 !== 'encomendas') {
          this.form.produtos.map(a => {
            if (a.serials1) {
              a.serials.map(b => {
                b.serialFocus = false
              })
            }
          })
        }
        if (this.form.produtos.length) {
          this.produto(this.form.produtos[0], 0)
        }
      } catch (e) {
        console.log(e)
      }
    },
    serialFocou (serie, dt, index) {
      this.searchNumSerie()
      serie.serialFocus = true
      this.produto(dt, index)
    },
    cambio (moeda) {
      this.form.moeda = moeda
      if (this.form.moeda !== 'EUR_€') {
        this.SET_LOAD(true)
        cambios(this.axios, this.$store.state.modulo).then((res) => {
          this.form.cambio = res.data.rates[this.form.moeda.split('_')[0]] || ''
          this.SET_LOAD()
          this.delyFoco('cambio')
        }, (er) => {
          this.SET_AVISO(er.response)
        })
      }
    },
    /*
    ordena (dt, index, position) {
      // let temp = Object.assign(this.form.produtos[index])
      let total = this.form.produtos.length - 1
      let totalcomposicao = 1
      let i = index
      let totalComposicaoSeguinte = 0
      while (++i <= total) {
        if (this.form.produtos[i].item_pai_id) {
          ++totalcomposicao
          continue
        }
        break
      }
      // avança para a frente
      if (position === 1) {
        while (++i <= total) {
          if (this.form.produtos[i].item_pai_id) {
            ++totalComposicaoSeguinte
            continue
          }
          break
        }
        if ((index + totalcomposicao - 1) === total || index === total) {
          this.form.produtos.splice(index, totalcomposicao).reverse().map(a => {
            this.form.produtos.unshift(a)
          })
        } else {
          this.form.produtos.splice(index, totalcomposicao).map(a => {
            this.form.produtos.splice(index + ++totalComposicaoSeguinte, 0, a)
          })
        }
      } else {
        i = index
        // passa para tras uma posição
        while (--i > 0) {
          if (this.form.produtos[i].item_pai_id) {
            ++totalComposicaoSeguinte
            continue
          }
          break
        }
        if (index === 0) {
          this.form.produtos.splice(index, totalcomposicao).map(a => {
            this.form.produtos.push(a)
          })
        } else {
          totalComposicaoSeguinte = index - ++totalComposicaoSeguinte
          this.form.produtos.splice(index, totalcomposicao).map(a => {
            this.form.produtos.splice(totalComposicaoSeguinte++, 0, a)
          })
        }
      }
      this.contas()
    },
    */
    delyFoco (campo) {
      this._.delay(function (campo) {
        if (document.getElementById(campo)) {
          document.getElementById(campo).focus()
        }
      }, 100, campo)
    },
    copiar () {
      let texto = document.createElement('input')
      document.body.appendChild(texto)
      texto.setAttribute('value', window.location.origin + '/discussao/email/' + this.form.id + '/' + this.form.codigo_discussao)
      texto.select()
      document.execCommand('copy')
      document.body.removeChild(texto)
    },
    FNLojaGuia () {
      if (this.ao.lojaGuia !== 'contacto') {
        this.form.contato_id = this.lojaGuia1.id
        if (this.ao.lojaGuia === 'sede') {
          this.ao.descarga_morada = this.lojaGuia1.morada
          this.ao.descarga_codigo_postal = this.lojaGuia1.codigo_postal
          this.ao.descarga_cidade = this.lojaGuia1.cidade
          this.ao.descarga_pais = this.lojaGuia1.pais
        } else {
          let a = this.$store.state.lojas.find(a => a.id === this.ao.lojaGuia)
          this.ao.descarga_morada = a.morada
          this.ao.descarga_codigo_postal = a.codigo_postal
          this.ao.descarga_cidade = a.cidade
          this.ao.descarga_pais = this.lojaGuia1.pais
        }
      } else {
        this.moradas = []
        this.contato = ''
        this.contacto = ''
        this.form.contato_id = ''
      }
    },
    onFileChange1 (e, index) {
      e.preventDefault()
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.$set(this.metas[index], 'option', { valor: files[0] })
      e.target.value = ''
      // dt.option.valor = files[0]
    },
    apagarFoto (index) {
      this.$set(this.metas[index], 'option', { valor: '' })
    },

    servePagamentoFN (id) {
      this.SET_LOAD(true)
      servePagamento(this.axios, { serve_pagamento: this.form.divida.serve_pagamento || false, documento_id: id || this.form.id }).then(() => {
        this.SET_TOAST({ msg: this.l('sucesso_serve_pagamento') })
      }, (er) => {
        this.SET_AVISO(er.response)
      })
    },
    ignoraPagamentoFN (id) {
      this.SET_LOAD(true)
      ignoraPagamento(this.axios, { ignorado: this.form.divida.ignorado, documento_id: id || this.form.id }).then(() => {
        this.SET_TOAST({ msg: this.l('sucesso_serve_pagamento') })
      }, (er) => {
        this.SET_AVISO(er.response)
      })
    },
    casasDec (val) {
      return new Intl.NumberFormat(this.$i18n.locale() + '-' + this.$i18n.locale(), { minimumFractionDigits: this.$store.getters.CASASDECIMAIS }).format(Math.round(val * 100) / 100)
    },
    pagoPorFN (data) {
      if (!Array.isArray(data) || !data.length) {
        return []
      }
      const x =  data.reduce((acc, item) => {
        const existingItem = acc.find(
          i => i.documento.invoice_number === item.documento.invoice_number
          )

        if (existingItem) {
          existingItem.total = (parseFloat(existingItem.total) + parseFloat(item.total)).toFixed(2)
          existingItem.total_desconto = (parseFloat(existingItem.total_desconto) + parseFloat(item.total_desconto)).toFixed(2)
        } else {
          acc.push({ ...item })
        }
        return acc
      }, [])
      console.log(x)
      return x
    }

  }
}
</script>
